import React, {useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card, 
  CardBody,
  Form,
  Table,
  Input,
  Button,
  InputGroup,
  Modal,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { IoEllipsisVertical } from 'react-icons/io5';
import styles from "./Contacts.module.scss"
import empresa from "../../assets/images/empresa.jpg"
import contacto from "../../assets/images/contacto.jpg"
import avatarHombre from "../../assets/images/avatarHombre.jpg"
import avatarMujer from "../../assets/images/avatarMujer.jpg"
import companyAvatar from "../../assets/images/companyAvatar.jpg"
import imgDefault from "../../assets/images/avatardefault.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faWhatsapp, faTelegram } from '@fortawesome/free-brands-svg-icons';
// Redux
import { connect, useDispatch} from "react-redux";
import { withRouter, Link, useHistory} from "react-router-dom";
import Select from 'react-select';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Pagination from "../../components/Common/Pagination";
import Loader from "../../components/Common/Loader";

//Action
import { contactsList } from "../../store/contacts/actions";
import { contactsFilter } from "../../store/contacts/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { getCampaignFullList, getContactsOfCampaign, postContactsOfCampaign } from "../../helpers/backend";



const ContactsList = (props) => {
const dispatch= useDispatch();
const history= useHistory();
const user = JSON.parse(localStorage.getItem("user"))
const groups =String(user.groups);

const [search, setSearch] = useState("");
const [active, setActive] = useState(false)
const [contactOpen, setContactOpen] = useState(false)
const [campaignAssign, setCampaignAssign] = useState(false)
const [alert, setAlert]= useState(false)
const [error, setError]= useState(false)
const [dropdownOpen, setDropdownOpen] = useState(false);
const [activeIndex, setActiveIndex] = useState(null);
const [dropdownOpenChat, setDropdownOpenChat] = useState(false);
const [activeIndexChat, setActiveIndexChat] = useState(null);
const [dropdownOpenTel, setDropdownOpenTel] = useState(false);
const [activeIndexTel, setActiveIndexTel] = useState(null);
const [campaignId, setCampaignId] = useState(null)
const [campaign, setCampaign] = useState('')
const [contactId, setContactId] = useState(null)

const [campaignData, setCampaignData] = useState({});
const getCampaignData = async () => {
  const response = await getCampaignFullList();
  const data = response.data;
  setCampaignData(data);
};
const [contactsOfCampaign, setContactsOfCampaign] = useState([]);
const getContactsData = async () => {
  const response = await getContactsOfCampaign(campaign);
  const data = response.data;
  setContactsOfCampaign(data);
};

const toggleDropdown = (idx) => {
  setDropdownOpen(!dropdownOpen);
  setActiveIndex(idx);
}
const toggleDropdownChat = (idx) => {
  setDropdownOpenChat(!dropdownOpenChat);
  setActiveIndexChat(idx);
}
const toggleDropdownTel = (idx) => {
  setDropdownOpenTel(!dropdownOpenTel);
  setActiveIndexTel(idx);
}
const onChange = (e) => {
  setSearch(e.target.value);
};
const onSubmit = (e) => {
  e.preventDefault();
  dispatch(contactsFilter(`?search=${search}`));
};

const resetSearch = () => {
  dispatch(contactsFilter(`?search=`));
  setSearch("");
};

useEffect(() => {
  if(campaign){
    getContactsData()
    console.log(contactsOfCampaign);
  }
  else{
    dispatch(contactsList());
  }
    // eslint-disable-next-line
},[campaign, dispatch]);

useEffect(() => {
    getCampaignData()
  // eslint-disable-next-line
},[]);

const handleShow = (id) =>{ 
  history.push(`/contact/${id}/contact_profile`);
  }

const handleItemClick = (selectedChatId, contactId, type, template) => {
  history.push({
    pathname: '/chat',
    state: { selectedChatId, contactId, type, template }
 });
};
  const assignCampaign = async () => {
    const response = await postContactsOfCampaign(campaignId , {
      contact: [contactId]
    })
    console.log(response);
    if (response.status === 200){
      setAlert(true)
      setCampaignAssign(false)
    }
    else {
      setError(true)
      setCampaignAssign(false)
    }
  }
  const params = !campaign
  ? { 
    count: props.contacts.count,
    next: props.contacts.next,
    previous: props.contacts.previous,
    results: props.contacts.results, }
  : {
    results: contactsOfCampaign
    };
    console.log(params);
return (
<React.Fragment>
  <div className="page-content">
    <MetaTags>
      <title>TrueContact | Contactos</title>
    </MetaTags>
    <Container fluid>
      {/* Render Breadcrumb */}
      <Breadcrumb title={"TrueContact"} breadcrumbItem={"Contactos"} />
      {props.loading ? (
        <Loader />
      ) : (
      <div>                   
  <Modal
    isOpen={contactOpen}
    centered={true}
    toggle={() => setContactOpen(false)}
    size="lg"
  >
    <h2 className="mt-4 ms-4 text-dark">
      <b>Agregar Contacto</b>{" "}
    </h2>
    <Row className="justify-content-center mt-2 mx-5 mb-5">
      <Col>
        <Button onClick={()=> history.push('/contact/add_company')}>
          <img style={{ height: "300px" }} src={empresa} alt="" />
          <h5 className="text-dark mt-2">
            <b>Empresas</b>{" "}
          </h5>
        </Button>
      </Col>
      <Col>
        <Button onClick={()=> history.push('/contact/add_contact')}>
          <img style={{ height: "300px" }} src={contacto} alt="" />
          <h5 className="text-dark mt-2">
            <b>Personas</b>{" "}
          </h5>
        </Button>
      </Col>
    </Row>
    <Row className="mb-3 mx-3">
      <Col className="text-end">
        <Button className="text-dark" onClick={() => setContactOpen(false)}>
          Cerrar
        </Button>
      </Col>
    </Row>
  </Modal>
  <Modal
    isOpen={campaignAssign}
    toggle={()=> setCampaignAssign(false)}
    centered={true}
  >
      <div className="modal-header">
        <h3 className="mt-2">Asignar a Campaña</h3>
        <button
          type="button"
          onClick={()=> setCampaignAssign(false)}
          className="btn-close btn-sm"
          data-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div className='p-3 mt-2'>
      <Select
        id="agent"
        name="agent"
        options={Array.isArray(campaignData)
          ? campaignData.map((item) => ({
              label: item.name,
              value: item.id,
            }))
          : []}
        onChange={(e)=> setCampaignId(e.value)}
        placeholder={"Seleccione"}
        isMulti={false}
        menuPosition="fixed"
      />
      </div>

      <Row className="mt-3 p-2">
          <Col className="d-flex justify-content-end">
            <Button className="text-dark btn btn-secondary" 
                onClick={()=> setCampaignAssign(false)}
            >
              Cerrar
          </Button>
          <Button color = 'primary' className="btn  ms-1" 
                onClick={()=> assignCampaign() }
            >Guardar </Button>
          </Col>
        </Row>

  </Modal>
      <Card >
        <CardBody>
          <Row>
            <Col md={7}>
            {groups.includes("SuperAdmin") ||  groups.includes("Supervisor") || groups.includes("Operador") || groups.includes("Crear y modificar Cliente") ?(
              <Link
                className="btn btn-primary waves-effect waves-light"
                to="#"
                onClick={()=> setContactOpen(true)}
              >
                Agregar Contacto{" "}
                <span className="bx bx-user-plus fa-lg align-middle"></span>
              </Link>
            ):null}
            </Col>
            <Col md={5}>
              <Form
                className="search-box"
                onSubmit={onSubmit}
              >

                <InputGroup>
                  <Input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Buscar"
                    value={search}
                    onChange={onChange}
                    
                  />
                  <i className="bx bx-search-alt search-icon" />
                  <Button color="primary" onClick={resetSearch}>
                    <span className=" bx bx-x fa-lg align-middle"></span>
                  </Button>
                </InputGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {params.count !== 0 ? (
        <CardBody className="pb-0">
            <Row>
              <Col>
              <h2 className="mb-3 font-size-20">Lista de Contactos</h2>
              </Col>
              <Col className="d-flex justify-content-end mb-1">
                <Button onClick={()=> setActive(true)}>
                  <span
                    className={`fas fa-align-justify fa-md ${active ? 'text-primary' : 'text-dark'}`}
                  />
                </Button>{" "}
                <Button onClick={()=> setActive(false)}>
                  <span
                    className={`fas fa-grip-vertical fa-lg ${active ? 'text-dark' : 'text-primary'} ms-1`}
                  />
                </Button>
              </Col>
            </Row>
          {groups.includes("SuperAdmin") ||  groups.includes("Supervisor") ?
            <Row>
              <Col style={{maxWidth:'150px'}} className="font-size-13 mb-2"  >                    
                <Select
                  id="campaign"
                  name="campaign"
                  options={[
                    { label: "Todos", value: null }, 
                    ...(Array.isArray(campaignData)
                      ? campaignData.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))
                      : [])
                  ]}
                  onChange={(e)=> setCampaign(e.value)}
                  placeholder={"Campañas"}
                  isMulti={false}
                  menuPosition="fixed"
                />
              </Col>
            </Row>
          :null}
          {active ? ( 
            <div className="table-responsive">
              <Table
                className="table  mb-3 mt-3 text-center"
                hover
                responsive
              >
                <thead className="table-dark align-middle">
                  <tr >
                    <th>#</th>
                    <th>Documento</th>
                    <th>Nombre del Contacto</th>
                    <th>Tipo de Contacto</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                {params.results &&
                  params.results.map((contacts, idx) => (
                    <tr key={idx}>
                      <th scope="row">{idx +1}</th>
                      <td>{contacts.documentNumber}</td>
                      <td>{contacts.get_full_name}</td>                                
                      <td>{contacts.contactType}</td>
                      <td>
                        <Link to ="#"  onClick={() => handleShow(contacts.id)}                           
                        >
                          <span className=" fas fa-eye fa-lg "></span>
                        </Link>
                      </td>
                    </tr>
                  ))} 
                </tbody>                
              </Table>
            </div>
            ):(
            <div className={`text-center ${styles['card-container']}`}>
            {params.results &&
              params.results.map((contacts, idx) => (
              <Card item lg={4} key={idx} className={styles.card} 
              style={{ borderTop : contacts.status ==="DESCONECTADO" ?  '7px solid #f35454' : '7px solid #46f092' }}>
                <div className="position-absolute top-0 end-0">
                  <Dropdown isOpen={dropdownOpen && activeIndex === idx} toggle={() => toggleDropdown(idx)}>
                    <DropdownToggle
                      tag="span"
                      onClick={() => toggleDropdown(idx)}
                      className={`${styles.menu}`}
                    >
                      <IoEllipsisVertical size={15} />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem>Asignar Encuesta</DropdownItem>  
                      <DropdownItem onClick={()=> { setCampaignAssign(true); setContactId(contacts.id) } }>
                        Asignar a Campaña</DropdownItem>                      
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div onClick={() => handleShow(contacts.id)}>
                <div className="text-center">
                {contacts.image ? (
                <img src={contacts.image} alt="" className={styles['card-avatar']} />
                ) : contacts.contactType && contacts.gender === "Masculino" ? (
                <img src={avatarHombre} alt="" className={styles['card-avatar']} />
                ) : contacts.contactType && contacts.gender === "Femenino" ? (
                <img src={avatarMujer} alt="" className={styles['card-avatar']} />
                ) : contacts.contactType === "EMPRESA" ? (
                <img src={companyAvatar} alt="" className={styles['card-avatar']} />
                ) :
                <img src={imgDefault} alt="" className={styles['card-avatar']} /> }
                </div>
                <div className={styles['card-title']}>
                  <b>{contacts.get_full_name}</b>
                </div>
                <br />
                </div>
                <div className={styles['fixed-button']}>
                  <Dropdown
                    isOpen={dropdownOpenChat && activeIndexChat === idx} toggle={() => toggleDropdownChat(idx)}>
                      <DropdownToggle
                        tag="span"
                        onClick={() => {toggleDropdownChat(); setContactId(contacts.id)}}
                      >                         
                        <FontAwesomeIcon icon={faWhatsapp} size="2x" style={{color: '#25d366'}} className={styles['social-icon']}/>
                      </DropdownToggle>
                      <DropdownMenu right>
                      {Array.isArray(contacts.phone) && contacts.phone.length > 0 ? (
                        contacts.phone.map((item, idx) => (
                          <DropdownItem key={idx} onClick={() =>
                            handleItemClick(item.telefono, contactId, 'WhatsApp', true)
                          }>{item.telefono}</DropdownItem>
                        ))
                      ) : (
                        <DropdownItem>No tiene número registrado</DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                    <FontAwesomeIcon icon={faFacebook} size="2x" style={{color: '#1877f2'}} className={styles['social-icon']} />
                    <FontAwesomeIcon icon={faInstagram} size="2x" style={{ color: '#e4405f '}} className={styles['social-icon']} />
                  <Dropdown
                  isOpen={dropdownOpenTel && activeIndexTel === idx} toggle={() => toggleDropdownTel(idx)}>
                    <DropdownToggle
                      tag="span"
                      onClick={() => {toggleDropdownTel(); setContactId(contacts.id)}}
                    >                         
                      <FontAwesomeIcon icon={faTelegram} size="2x" style={{color: '#0088cc' }} className={styles['social-icon']} />
                    </DropdownToggle>
                    <DropdownMenu right>
                    {Array.isArray(contacts.telegram) && contacts.telegram.length > 0 ? (
                      contacts.telegram.map((item, idx) => (
                        <DropdownItem key={idx} onClick={() =>
                          handleItemClick(item.telegram, contactId, 'Telegram')
                        } >{item.telegram}</DropdownItem>
                      ))
                    ) : (
                      <DropdownItem>No tiene número registrado</DropdownItem>
                    )}
                  </DropdownMenu>
                  </Dropdown>
                    

                </div>
                <div className={styles['fixed-button-left']}>
                  <Col lg={3}>
                  <Link className="btn btn-warning btn-sm" to= {`contact/calendar/${contacts.id}`}>
                    <i className="fas fa-calendar-alt"></i>
                  </Link>
                  <Link className="btn btn-info btn-sm mt-2" to= {`/gestionContacto/${contacts.id}`}>
                    <i className="fas fa-tasks "></i>
                  </Link>
                  </Col>
              </div>

              </Card>
            ))}
            </div>
          )}
          
        </CardBody>
        ) : ( 
        <CardBody>
          <h4 className="card-title">No se encontraron resultados</h4>
        </CardBody>
        )}

        <Pagination
          count={params.count}
          next={params.next}
          previous={params.previous}
          limit={12}
          baseUrl="contact/contacto/"
          filterFunction={contactsFilter}
        />
      </div>

      )}
    </Container>
    {alert ? (
      <SweetAlert
          title="Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
              setAlert(false); 

          }}
      >
          Se ha asignado al contacto en la Campaña
      </SweetAlert>
    ) : null}
      {error ? (
      <SweetAlert
          title="Lo sentimos!"
          danger
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
              setError(false); 
          }}
      >
          No se ha podido asignar la Campaña
      </SweetAlert>
    ) : null}
  </div>
</React.Fragment>
);
};
const mapStateToProps = (state) => {
  const { error, loading, contacts } = state.Contacts;
  return { error, loading, contacts };
};

export default withRouter(connect(mapStateToProps)(ContactsList));