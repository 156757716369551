import {
  CONTACTSHC_LIST,
  CONTACTSHC_FILTER,
  CONTACTSHC_CREATE,
  CONTACTSHC_UPDATE,
  CONTACTSHC_UPDATE_SUCCESS,
  CONTACTSHC_CREATE_SUCCESS,
  CONTACTSHC_LIST_SUCCESS,
  CONTACTSHC_REQUEST_FAILED,
  CONTACTSHC_FULLLIST,
  CONTACTSHC_FULLLIST_SUCCESS,

} from "./actionTypes";

export const contactshcList = () => {
  return {
    type: CONTACTSHC_LIST,
    payload: {},
  };
};

export const contactshcFulllist = () => {
  return {
    type: CONTACTSHC_FULLLIST,
    payload: {},
  };
};


export const contactshcFuLLListSuccess = (contacts) => {
  return {
    type: CONTACTSHC_FULLLIST_SUCCESS,
    payload: contacts,
  };
};

export const contactshcCreate = (contactshc) => {
  return {
    type: CONTACTSHC_CREATE,
    payload: contactshc,
  };
};

export const contactshcFilter = (changeUrl) => {
  return {
    type: CONTACTSHC_FILTER,
    payload: { changeUrl },
  };
};

export const contactshcUpdate = (id, data) => {
  return {
    type: CONTACTSHC_UPDATE,
    payload: { id, data },
  };
};

export const contactshcListSuccess = (contactshc) => {
  return {
    type: CONTACTSHC_LIST_SUCCESS,
    payload: contactshc,
  };
};

export const contactshcCreateSuccess = (contactshcCreated) => {
  return {
    type: CONTACTSHC_CREATE_SUCCESS,
    payload: contactshcCreated,
  };
};

export const contactshcUpdateSuccess = (contactshc) => {
  return {
    type: CONTACTSHC_UPDATE_SUCCESS,
    payload: contactshc,
  };
};

export const contactshcRequestFailed = (error) => {
  return {
    type: CONTACTSHC_REQUEST_FAILED,
    payload: error,
  };
};
