import React, { useEffect, useState } from 'react';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane, Container, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import ContactsDetails from '../components/ContactsDetails';
import DatoTab from './DatoTab'
import DocumentoContacto from '../components/DocumentoContacto'
import { MetaTags } from 'react-meta-tags';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { getContactsRead } from '../../../helpers/backend';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CompanyDetails from './CompanyDetails';
import HojaContacto from '../../HojaDeVida/HojaContacto';

const ProfileTab = ({contactos=[]})=> {
    const [activeTab, setActiveTab] = useState('1');
    const id = useParams().id
    const [contact, setContact]= useState({})
    const getContactData = async () => {
      const response = await getContactsRead(id);
      const data = response.data;
      setContact(data);   
    };
    useEffect(() => {
        getContactData();  
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>TrueContact| Perfil del Contacto</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumb title="TrueContact" breadcrumbItem="Perfil del Contacto" route = {contact.get_full_name} />
            <Card>
              <Row>
                <Col>
                <Nav pills justified  role="tablist">
                <NavItem>
                        <NavLink
                            to="#"
                            className={`p-4 ${classnames({ active: activeTab === '1' })}`}
                            onClick={() => { toggle('1'); }}
                            style={{ cursor: 'pointer'}}>
                            <i className="fas fa-user fa-lg"></i>
                            <span className="d-none d-sm-block">Información General</span>
                        </NavLink>
                    </NavItem>                  
                    <NavItem className=''>
                        <NavLink
                            to="#"
                            className={`p-4 ${classnames({ active: activeTab === '2' })}`}
                            style={{ cursor: 'pointer'}}
                            onClick={() => { toggle('2'); }}>
                            <i className="fas fa-address-card fa-lg "></i>
                            <span className="d-none d-sm-block">Datos del Contacto</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            to="#"
                            className={`p-4 ${classnames({ active: activeTab === '3' })}`}
                            onClick={() => { toggle('3'); }}
                            style={{ cursor: 'pointer'}}
                        >
                            <i className="fas fa-clipboard-list fa-lg"></i>
                            <span className="d-none d-sm-block">Documentos del Contacto</span>
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            to="#"
                            className={`p-4 ${classnames({ active: activeTab === '4' })}`}
                            onClick={() => { toggle('4'); }}
                            style={{ cursor: 'pointer'}}>
                            <i className="fas fa-file-alt fa-lg"></i>
                            <span className="d-none d-sm-block">Hoja de Vida</span>
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent className="p-5" activeTab={activeTab}>  
                <TabPane id="1" role="tabpanel" tabId="1">
                    {contact.contactType === "EMPRESA" ?  
                        <CompanyDetails contactos = {contactos}/>
                    :
                       <ContactsDetails contactos = {contactos}/>
                    }
                    </TabPane>                  
                    <TabPane id="2" role="tabpanel" tabId="2">
                        <DatoTab/>
                    </TabPane>
                    
                    <TabPane id="3" role="tabpanel" tabId="3">
                       <DocumentoContacto/>
                    </TabPane>
                    

                    <TabPane id="4" role="tabpanel" tabId="4">
                        <HojaContacto />
                    </TabPane>
                </TabContent>
                </Col>
              </Row>


            </Card>
            </Container>
            </div>
        </React.Fragment>
    );
}

export default ProfileTab;