import { call, put, takeEvery } from "redux-saga/effects";

import {
  AREAS_LIST,
  AREAS_FILTER,
  AREAS_CREATE,
  AREAS_UPDATE,
  AREAS_FULLLIST,
} from "./actionTypes";

import {
 areasListSuccess,
 areasCreateSuccess,
 areasUpdateSuccess,
 areasRequestFailed,
 areasFullListSuccess,
} from "./actions";

import {
  getAreaList,
  getAreaFilter,
  postAreaCreate,
  patchAreaUpdate,
  getAreaFullList,
} from "../../helpers/backend";

function*areasListSaga() {
  console.log("Trayendo lista de areas...");
  try {
    const response = yield call(getAreaList);
    if (response.status === 200) {
      yield put(areasListSuccess(response.data));
    } else {
      yield put(areasRequestFailed(response.data));
    }
  } catch (error) {
    yield put(areasRequestFailed(error.response.data));
  }
}

function*areasFullListSaga() {
  console.log("Trayendo lista de areas de FullList...");
  try { 
    const response = yield call(getAreaFullList);
    if (response.status === 200) {
      yield put(areasFullListSuccess(response.data));
    } else {
      yield put(areasRequestFailed(response.data));
    } 
  } catch (error) {
    yield put(areasRequestFailed(error.response.data));
  }
} 

function*areasCreateSaga({ payload:areas }) {
  console.log("Creando nuevo areas...");
  try {
    const response = yield call(postAreaCreate,areas);
    if (response.status === 201) {
      yield put(areasCreateSuccess(response.data));
    } else {
      yield put(areasRequestFailed(response.data));
    }
  } catch (error) {
    yield put(areasRequestFailed(error.response.data));
  }
}

function*areasFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getAreaFilter, { changeUrl });
    if (response.status === 200) {
      yield put(areasListSuccess(response.data));
    } else {
      yield put(areasRequestFailed(response.data));
    }
  } catch (error) {
    yield put(areasRequestFailed(error.response.data));
  }
}

function*areasUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del areas...");
  try {
    const response = yield call(patchAreaUpdate, { id }, data);
    if (response.status === 200) {
      yield put(areasUpdateSuccess(response.data));
    } else {
      yield put(areasRequestFailed(response.data));
    }
  } catch (error) {
    yield put(areasRequestFailed(error.response.data));
  }
}

export default function*areasSaga() {
  yield takeEvery(AREAS_LIST,areasListSaga);
  yield takeEvery(AREAS_FILTER,areasFilterSaga);
  yield takeEvery(AREAS_CREATE,areasCreateSaga);
  yield takeEvery(AREAS_UPDATE,areasUpdateSaga);
  yield takeEvery(AREAS_FULLLIST,areasFullListSaga);
}
