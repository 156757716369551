// AREAS requests
export const AREAS_LIST = "AREAS_LIST";
export const AREAS_CREATE = "AREAS_CREATE";
export const AREAS_FILTER = "AREAS_FILTER";
export const AREAS_UPDATE = "AREAS_UPDATE";
export const AREAS_DELETE = "AREAS_DELETE";
export const AREAS_READ = "AREAS_READ";
export const AREAS_FULLLIST = "AREAS_FULLLIST";

// AREAS success
export const AREAS_LIST_SUCCESS = "AREAS_LIST_SUCCESS";
export const AREAS_FULLLIST_SUCCESS = "AREAS_FULLLIST_SUCCESS";
export const AREAS_CREATE_SUCCESS = "AREAS_CREATE_SUCCESS";
export const AREAS_UPDATE_SUCCESS = "AREAS_UPDATE_SUCCESS";
export const AREAS_DELETE_SUCCESS = "AREAS_DELETE_SUCCESS";
export const AREAS_READ_SUCCESS = "AREAS_READ_SUCCESS";

// AREAS failed
export const AREAS_REQUEST_FAILED = "AREAS_REQUEST_FAILED";
