import {
  AUTORIZACION_LIST,
  AUTORIZACION_FILTER,
  AUTORIZACION_CREATE,
  AUTORIZACION_UPDATE,
  AUTORIZACION_UPDATE_SUCCESS,
  AUTORIZACION_CREATE_SUCCESS,
  AUTORIZACION_LIST_SUCCESS,
  AUTORIZACION_REQUEST_FAILED,
} from "./actionTypes";

export const autorizacionList = () => {
  return {
    type: AUTORIZACION_LIST,
    payload: {},
  };
};

export const autorizacionCreate = (autorizacion) => {
  return {
    type: AUTORIZACION_CREATE,
    payload: autorizacion,
  };
};

export const autorizacionFilter = (changeUrl) => {
  return {
    type: AUTORIZACION_FILTER,
    payload: { changeUrl },
  };
};

export const autorizacionUpdate = (id, data) => {
  return {
    type: AUTORIZACION_UPDATE,
    payload: { id, data },
  };
};

export const autorizacionListSuccess = (autorizacion) => {
  return {
    type: AUTORIZACION_LIST_SUCCESS,
    payload: autorizacion,
  };
};

export const autorizacionCreateSuccess = (autorizacionCreated) => {
  return {
    type: AUTORIZACION_CREATE_SUCCESS,
    payload: autorizacionCreated,
  };
};

export const autorizacionUpdateSuccess = (autorizacion) => {
  return {
    type: AUTORIZACION_UPDATE_SUCCESS,
    payload: autorizacion,
  };
};

export const autorizacionRequestFailed = (error) => {
  return {
    type: AUTORIZACION_REQUEST_FAILED,
    payload: error,
  };
};
