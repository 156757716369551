import React, { useState } from "react";
import {
  Card,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Col,
  Button,
} from "reactstrap";
import CallCenterConfirmacion from "../Formularios.jsx/CallCenterConfirmacion";
import Factura from "../Formularios.jsx/Factura";
import CoordTransporte from "../Formularios.jsx/CoordTransporte";
import Seguimiento from "../Formularios.jsx/Seguimiento";
import Coordinador from "../Formularios.jsx/Coordinador";
import TableConfirmacion from "./TableConfirmacion";
import TableFactura from "./TableFactura";
import TableTransporte from "./TableTransporte";
import TableSeguimiento from "./TableSeguimiento";
import TableGiro from "./TableGiro";
import classnames from "classnames";
import styles from "../CasosDetails.module.scss";
import ComponentsButtons from "./ComponentsButtons";

function TableRutero(props) {
  const [rutero, setRutero] = useState(false);
  const [NavRutero, setNavRutero] = useState(false);

  const [itemFechaServicio, setItemFechaServicio] = useState(null);
  const [itemHoraServicio, setItemHoraServicio] = useState(null);
  const [tipodeServicio, setTipodeServicio] = useState(null);
  const [ciudad_origen, setCiudad_origen] = useState(null);
  const [ciudad_destino, setCiudad_destino] = useState(null);
  const [vereda, setVereda] = useState(null);
  const [direccion_origen, setDireccion_origen] = useState(null);
  const [direccion_destino, setDireccion_destino] = useState(null);
  const [cantida, setCantida] = useState(null);
  const [ServicioGestionar, setServicioGestionar] = useState(null);

  const [ModalConfirm, setModalConfirm] = useState(false);
  const [ModalSeguimiento, setModalSeguimiento] = useState(false);
  const [ModalCoordinador, setModalCoordinador] = useState(false);
  const [ModalCoordTransporte, setModalCoordTransporte] = useState(false);
  const [ModalFactura, setModalFactura] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedNumGestion, setNumGestion] = useState(null);

  const [active, setActive] = useState(false);

  const onCloseClick = () => {
    setModalConfirm(false);
  };
  const onCloseClickFactura = () => {
    setModalFactura(false);
  };
  const onCloseClickCoordTransporte = () => {
    setModalCoordTransporte(false);
  };

  const onCloseClickSeguimiento = () => {
    setModalSeguimiento(false);
  };

  const onCloseClickCoordinador = () => {
    setModalCoordinador(false);
  };

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleRowClick = (id, num) => {
    setSelectedId(id);
    setNavRutero(id);
    setNumGestion(num);
  };

  console.log(props);
  return (
    <>
      {ModalConfirm ? (
        <CallCenterConfirmacion
          ModalConfirm={ModalConfirm}
          onCloseClick={onCloseClick}
          itemFechaServicio={itemFechaServicio}
          itemHoraServicio={itemHoraServicio}
          tipodeServicio={tipodeServicio}
          ciudad_origen={ciudad_origen}
          ciudad_destino={ciudad_destino}
          vereda={vereda}
          direccion_origen={direccion_origen}
          direccion_destino={direccion_destino}
          cantida={cantida}
          ServicioGestionar={ServicioGestionar}
          id={rutero}
          selectedNumGestion = {selectedNumGestion}
        >
          {" "}
        </CallCenterConfirmacion>
      ) : null}

      {ModalFactura ? (
        <Factura
          ModalFactura={ModalFactura}
          onCloseClick={onCloseClickFactura}
          itemFechaServicio={itemFechaServicio}
          itemHoraServicio={itemHoraServicio}
          tipodeServicio={tipodeServicio}
          ciudad_origen={ciudad_origen}
          ciudad_destino={ciudad_destino}
          vereda={vereda}
          direccion_origen={direccion_origen}
          direccion_destino={direccion_destino}
          cantida={cantida}
          ServicioGestionar={ServicioGestionar}
          id={rutero}
          selectedNumGestion = {selectedNumGestion}
        >
          {" "}
        </Factura>
      ) : null}

      {ModalSeguimiento ? (
        <Seguimiento
          ModalSeguimiento={ModalSeguimiento}
          onCloseClick={onCloseClickSeguimiento}
          itemFechaServicio={itemFechaServicio}
          itemHoraServicio={itemHoraServicio}
          tipodeServicio={tipodeServicio}
          ciudad_origen={ciudad_origen}
          ciudad_destino={ciudad_destino}
          vereda={vereda}
          direccion_origen={direccion_origen}
          direccion_destino={direccion_destino}
          cantida={cantida}
          ServicioGestionar={ServicioGestionar}
          id={rutero}
          selectedNumGestion = {selectedNumGestion}
        >
          {" "}
        </Seguimiento>
      ) : null}
      {ModalCoordTransporte ? (
        <CoordTransporte
          ModalCoordTransporte={ModalCoordTransporte}
          onCloseClick={onCloseClickCoordTransporte}
          itemFechaServicio={itemFechaServicio}
          itemHoraServicio={itemHoraServicio}
          tipodeServicio={tipodeServicio}
          ciudad_origen={ciudad_origen}
          ciudad_destino={ciudad_destino}
          vereda={vereda}
          direccion_origen={direccion_origen}
          direccion_destino={direccion_destino}
          cantida={cantida}
          ServicioGestionar={ServicioGestionar}
          id={rutero}
          selectedNumGestion = {selectedNumGestion}
        >
          {" "}
        </CoordTransporte>
      ) : null}
      {ModalCoordinador ? (
        <Coordinador
          ModalCoordinador={ModalCoordinador}
          onCloseClick={onCloseClickCoordinador}
          itemFechaServicio={itemFechaServicio}
          itemHoraServicio={itemHoraServicio}
          tipodeServicio={tipodeServicio}
          ciudad_origen={ciudad_origen}
          ciudad_destino={ciudad_destino}
          vereda={vereda}
          direccion_origen={direccion_origen}
          direccion_destino={direccion_destino}
          cantida={cantida}
          ServicioGestionar={ServicioGestionar}
          id={rutero}
          selectedNumGestion = {selectedNumGestion}
        >
          {" "}
        </Coordinador>
      ) : null}

      <Col className="d-flex justify-content-end mb-1">
        <Button onClick={() => setActive(true)}>
          <span
            className={`fas fa-align-justify fa-md ${
              active ? "text-primary" : "text-dark"
            }`}
          />
        </Button>{" "}
        <Button onClick={() => setActive(false)}>
          <span
            className={`fas fa-grip-vertical fa-lg ${
              active ? "text-dark" : "text-primary"
            } ms-1`}
          />
        </Button>
      </Col>

      {
        active ? (
          <Table
            className="table table-bordered table-striped text-center mb-3"
            hover
            responsive
          >
            <thead key={props.Rutero.rutero.id}>
              <tr>
                <th style={{ alignContent: "center", width: "115px" }}>
                  Fecha de Servicio
                </th>
                <th style={{ alignContent: "center" }}>Hora de Servicio</th>
                <th style={{ alignContent: "center" }}>Tipo de Servicio</th>
                <th style={{ alignContent: "center" }}>Ciudad Origen </th>

                <th style={{ alignContent: "center" }}>Ciudad Destino </th>
                <th style={{ alignContent: "center" }}>Vereda </th>
                <th style={{ alignContent: "center" }}>Direccion Origen </th>

                <th style={{ alignContent: "center" }}>Direccion Destino </th>
                <th style={{ alignContent: "center" }}>Cantidad </th>
                <th style={{ alignContent: "center" }}>
                  Servicio a gestionar{" "}
                </th>
                <th style={{ alignContent: "center" }}>Accion </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(props.Rutero.rutero)
                ? props.Rutero.rutero.map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        <tr
                          onClick={() => handleRowClick(item.id)}
                          key={item.id}
                          style={{}}
                        >
                          <th
                            style={{
                              alignContent: "center",
                              backgroundColor:
                                selectedId === item.id
                                  ? "lightgreen"
                                  : "transparent",
                            }}
                          >
                            {" "}
                            {item.fecha_servicio}
                          </th>
                          <th
                            style={{
                              alignContent: "center",
                              backgroundColor:
                                selectedId === item.id
                                  ? "lightgreen"
                                  : "transparent",
                            }}
                          >
                            {" "}
                            {item.hora_servicio}
                          </th>
                          <th
                            style={{
                              alignContent: "center",
                              backgroundColor:
                                selectedId === item.id
                                  ? "lightgreen"
                                  : "transparent",
                            }}
                          >
                            {" "}
                            {item.tipo_servicio}
                          </th>
                          <th
                            style={{
                              alignContent: "center",
                              backgroundColor:
                                selectedId === item.id
                                  ? "lightgreen"
                                  : "transparent",
                            }}
                          >
                            {" "}
                            {item.ciudad_origen}
                          </th>
                          <th
                            style={{
                              alignContent: "center",
                              backgroundColor:
                                selectedId === item.id
                                  ? "lightgreen"
                                  : "transparent",
                            }}
                          >
                            {" "}
                            {item.ciudad_destino}
                          </th>
                          <th
                            style={{
                              alignContent: "center",
                              backgroundColor:
                                selectedId === item.id
                                  ? "lightgreen"
                                  : "transparent",
                            }}
                          >
                            {" "}
                            {item.vereda}
                          </th>
                          <th
                            style={{
                              alignContent: "center",
                              backgroundColor:
                                selectedId === item.id
                                  ? "lightgreen"
                                  : "transparent",
                            }}
                          >
                            {" "}
                            {item.direccion_origen}
                          </th>
                          <th
                            style={{
                              alignContent: "center",
                              backgroundColor:
                                selectedId === item.id
                                  ? "lightgreen"
                                  : "transparent",
                            }}
                          >
                            {" "}
                            {item.direccion_destino}
                          </th>
                          <th
                            style={{
                              alignContent: "center",
                              backgroundColor:
                                selectedId === item.id
                                  ? "lightgreen"
                                  : "transparent",
                            }}
                          >
                            {" "}
                            {item.cantida}
                          </th>
                          <th
                            style={{
                              alignContent: "center",
                              backgroundColor:
                                selectedId === item.id
                                  ? "lightgreen"
                                  : "transparent",
                            }}
                          >
                            {" "}
                            {item.sev_gestionar}
                          </th>

                          <th
                            style={{
                              display: "flex",
                              backgroundColor:
                                selectedId === item.id
                                  ? "lightgreen"
                                  : "transparent",
                            }}
                          >
                            <ComponentsButtons
                              setModalConfirm={setModalConfirm}
                              setModalCoordTransporte={setModalCoordTransporte}
                              setModalSeguimiento={setModalSeguimiento}
                              setModalFactura={setModalFactura}
                              setModalCoordinador={setModalCoordinador}
                              setItemFechaServicio={setItemFechaServicio}
                              setItemHoraServicio={setItemHoraServicio}
                              setTipodeServicio={setTipodeServicio}
                              setCiudad_origen={setCiudad_origen}
                              setCiudad_destino={setCiudad_destino}
                              setVereda={setVereda}
                              setDireccion_origen={setDireccion_origen}
                              setDireccion_destino={setDireccion_destino}
                              setCantida={setCantida}
                              setServicioGestionar={setServicioGestionar}
                              setRutero={setRutero}
                              styles={styles}
                              item={item}
                            ></ComponentsButtons>
                          </th>
                        </tr>
                      </React.Fragment>
                    );
                  })
                : []}
            </tbody>
          </Table>
        ) : (
          <div className={`text-center d-flex flex-wrap ${styles["card-container"]}`}>
            {Array.isArray(props.Rutero.rutero)
              ? props.Rutero.rutero.map((item, idx) => {
                  return (
                    <Card
                      item
                      lg={4}
                      key={idx}
                      className={styles["content_cart_"]}
                      onClick={() => handleRowClick(item.id, item.num_gestion)}
                      style={{
                        backgroundColor:
                          selectedId === item.id ? "#33a2e22c" : "initial",
                        color: selectedId === item.id ? "#fff" : "initial", // Cambia el color del texto si es necesario
                      }}
                    >
                      {/* Contenido de la tarjeta */}

                      <div style={{ paddingBottom: "1rem" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p>Numero: </p>
                          </div>
                          <div>
                            <span style={{ fontWeight: "600" }}>
                              {item.num_gestion}
                            </span>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p> Fecha: </p>
                          </div>
                          <div>
                            <span style={{ fontWeight: "600" }}>
                              {item.fecha_servicio}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p> Hora: </p>
                          </div>
                          <div>
                            <span style={{ fontWeight: "600" }}>
                              {item.hora_servicio}
                            </span>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p> Ciudad:</p>
                          </div>
                          <div>
                            <span style={{ fontWeight: "600" }}>
                              {item.ciudad_origen}
                            </span>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p> Departamento:</p>
                          </div>
                          <div>
                            <span style={{ fontWeight: "600" }}>
                              {item.departamento}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className={styles["fixed-button"]}>
                        <ComponentsButtons
                          setModalConfirm={setModalConfirm}
                          setModalCoordTransporte={setModalCoordTransporte}
                          setModalSeguimiento={setModalSeguimiento}
                          setModalFactura={setModalFactura}
                          setModalCoordinador={setModalCoordinador}
                          setItemFechaServicio={setItemFechaServicio}
                          setItemHoraServicio={setItemHoraServicio}
                          setTipodeServicio={setTipodeServicio}
                          setCiudad_origen={setCiudad_origen}
                          setCiudad_destino={setCiudad_destino}
                          setVereda={setVereda}
                          setDireccion_origen={setDireccion_origen}
                          setDireccion_destino={setDireccion_destino}
                          setCantida={setCantida}
                          setServicioGestionar={setServicioGestionar}
                          setRutero={setRutero}
                          styles={styles}
                          item={item}
                        ></ComponentsButtons>
                      </div>
                    </Card>
                  );
                })
              : null}{" "}
            {/* Retornar `null` si no es un array */}
          </div>
        ) // Retornar `null` si no es un array
      }

      {NavRutero ? (
        <Card
          style={{
            width: "100%",
            height: "100%",
            border: "1px",
            marginTop: "50px",
          }}
        >
          <div
            style={{
              border: "2px solid #134461",

              borderRadius: "10px 10px 0 0",
              backgroundColor: "#33a2e22c",
            }}
          >
            <Nav tabs justified className="nav-tabs-custom" role="tablist">
              <h1
                style={{
                  paddingLeft: "10px",
                  paddingTop: "10px",
                  color: "black",
                  marginRight: "10px",
                  fontSize: "32px",
                }}
              >
                Rutero : {selectedNumGestion}
              </h1>
              <NavItem>
                <NavLink
                  to="#"
                  className={`p-2 ${classnames({
                    active: activeTab === "1",
                  })}`}
                  onClick={() => {
                    toggle("1");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <i class="fas fa-wheelchair" aria-hidden="true"></i>
                  <span className="d-none d-sm-block">Confirmacion</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="#"
                  className={`p-2 ${classnames({
                    active: activeTab === "2",
                  })}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  <i class="fas fa-car" aria-hidden="true"></i>
                  <span className="d-none d-sm-block">Transporte</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="#"
                  className={`p-2 ${classnames({
                    active: activeTab === "3",
                  })}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  <i class="fas fa-check-circle" aria-hidden="true"></i>

                  <span className="d-none d-sm-block">Seguimiento</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="#"
                  className={`p-2 ${classnames({
                    active: activeTab === "4",
                  })}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggle("4");
                  }}
                >
                  <i class="fas fa-thumbtack" aria-hidden="true"></i>
                  <span className="d-none d-sm-block">Coordinador</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="#"
                  className={`p-2 ${classnames({
                    active: activeTab === "5",
                  })}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    toggle("5");
                  }}
                >
                  <i class="fas fa-money-bill" aria-hidden="true"></i>
                  <span className="d-none d-sm-block">Facturador</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <div
            style={{
              border: "2px solid #134461",
              borderTop: "none",
              borderRadius: "0 0 10px 10px",
            }}
          >
            <TabContent className="p-4" activeTab={activeTab}>
              <TabPane id="1" role="tabpanel" tabId="1">
                <TableConfirmacion id={NavRutero}></TableConfirmacion>
              </TabPane>

              <TabPane id="2" role="tabpanel" tabId="2">
                <TableTransporte id={NavRutero}></TableTransporte>
              </TabPane>
              <TabPane id="3" role="tabpanel" tabId="3">
                <TableSeguimiento id={NavRutero}></TableSeguimiento>
              </TabPane>
              <TabPane id="4" role="tabpanel" tabId="4">
                <TableGiro id={NavRutero}></TableGiro>
              </TabPane>
              <TabPane id="5" role="tabpanel" tabId="5">
                <TableFactura id={NavRutero}></TableFactura>
              </TabPane>
            </TabContent>
          </div>
        </Card>
      ) : null}
    </>
  );
}

export default TableRutero;
