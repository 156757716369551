import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/reducer";

// Administration
import User from "./admin/user/reducer";

//roles
import Rol from "./roles/reducer"

// Accounts
import Account from "./account/reducer"

import Campaign from "./campaign/reducer"

// Leads
import Leads from "./leads/reducer"

//Contacts
import Contacts from "./contacts/reducer";


// Dato Contacto
import ContactsData from "./contactsData/reducer";

// Dato Contacto
import ContactsDocument from "./contactsDocument/reducer";
//GESTION CONTACTO
import CalificacionContacto from "./calificacionContacto/reducer"
import DescripcionCalificacion from "./descripcionCalificacion/reducer"
import GestionesContacto from "./gestionesContacto/reducer"
import TipoGestionContacto from "./tipoGestionContacto/reducer";
import Audits from "./audits/reducer";
import Casos from "./casos/reducer"
import Survey from "./survey/reducer"
import Pisos from "./pisos/reducer"
import Areas from "./areas/reducer"
import Rooms from "./rooms/reducer"
import Autorizacion from "./autorizacion/reducer"
import Contactshc from "./contactshc/reducer"
import Rutero from "./rutero/reducer"
import Confirmacion from "./confirmacion/reducer"
import Factura from "./factura/reducer"
import Coordinacion from "./coordinacion/reducer"
import Seguimiento from "./seguimiento/reducer"
import Giro from "./giro/reducer"
 


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  User,
  Rol,
  Account,
  Campaign,
  Leads,
  Contacts,
  ContactsData,
  ContactsDocument,
  CalificacionContacto,
  DescripcionCalificacion,
  TipoGestionContacto,
  GestionesContacto,
  Audits,
  Casos,
  Survey,
  Pisos,
  Areas,
  Rooms,
  Autorizacion,
  Contactshc,
  Rutero,
  Confirmacion,
  Factura,
  Coordinacion,
  Seguimiento,
  Giro


});

export default rootReducer;
