import {
  GIRO_LIST,
  GIRO_FILTER,
  GIRO_CREATE,
  GIRO_UPDATE,
  GIRO_UPDATE_SUCCESS,
  GIRO_CREATE_SUCCESS,
  GIRO_LIST_SUCCESS,
  GIRO_REQUEST_FAILED,
} from "./actionTypes";

export const giroList = () => {
  return {
    type: GIRO_LIST,
    payload: {},
  };
};

export const giroCreate = (giro) => {
  return {
    type: GIRO_CREATE,
    payload: giro,
  };
};

export const giroFilter = (changeUrl) => {
  return {
    type: GIRO_FILTER,
    payload: { changeUrl },
  };
};

export const giroUpdate = (id, data) => {
  return {
    type: GIRO_UPDATE,
    payload: { id, data },
  };
};

export const giroListSuccess = (giro) => {
  return {
    type: GIRO_LIST_SUCCESS,
    payload: giro,
  };
};

export const giroCreateSuccess = (giroCreated) => {
  return {
    type: GIRO_CREATE_SUCCESS,
    payload: giroCreated,
  };
};

export const giroUpdateSuccess = (giro) => {
  return {
    type: GIRO_UPDATE_SUCCESS,
    payload: giro,
  };
};

export const giroRequestFailed = (error) => {
  return {
    type: GIRO_REQUEST_FAILED,
    payload: error,
  };
};
