import React, { useEffect } from 'react'
import {
    
    Table
  } from "reactstrap";
  import {  useDispatch, useSelector } from "react-redux";

  import { seguimientoFilter } from '../../../store/actions';
function TableSeguimiento(props) {
    
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(seguimientoFilter(`rutero/?id=${props.id}`))
     
    }, [dispatch,props.id]);
    
    const seguimiento = useSelector((state) => state.Seguimiento.seguimiento);

  return (
    <div>
         
    <Table
        className="table table-bordered table-striped text-center mb-3"
        hover
        responsive
      > <thead className="table-dark align-middle" >
        <tr key={props.id}>
        <th  style={{minWidth: "150px"}}>Funcionario y Fecha</th>
        <th  style={{minWidth: "150px"}}>Nota del Seguimiento</th>
        <th  style={{minWidth: "150px"}}>Queja</th>
        <th  style={{minWidth: "150px"}}>Observaciones</th>
        <th  style={{minWidth: "150px"}}>Interno</th>
       
        <th  style={{minWidth: "150px"}}>Servicio Prestado</th>
        <th  style={{minWidth: "150px"}}>Atencion Conduttor</th>
        <th  style={{minWidth: "150px"}}>Servicio Prestado a tiempo</th>
        <th  style={{minWidth: "150px"}}>Vehiculo Blanco</th>
        <th  style={{minWidth: "150px"}}>Presento Novedad con el Servicio</th>
        <th  style={{minWidth: "150px"}}>Traslado Unico</th>
        <th  style={{minWidth: "150px"}}>Calificacion Servicio</th>
        </tr>
        </thead>
        <tbody>

       
        {Array.isArray(seguimiento)
            ? seguimiento.map((item) => {
              return (
              
                <tr key={item.id}>
                <th  style={{ alignContent: "center" }}> {item.user_time}</th>
                <th  style={{ alignContent: "center" }}> {item.nota}</th>
                <th  style={{ alignContent: "center" }}> {item.queja}</th>
                <th  style={{ alignContent: "center" }}> {item.observaciones}</th>
                <th  style={{ alignContent: "center" }}> {item.interno}</th>
                <th  style={{ alignContent: "center" }}> {item.servicio_prestado? "Si":"No"}</th>
                <th  style={{ alignContent: "center" }}> {item.valoracion_cond}</th>
                <th  style={{ alignContent: "center" }}> {item.servicio_tiempo ? "Si":"No"}</th>
                <th  style={{ alignContent: "center" }}> {item.vehiculo_blanco ? "Si":"No"}</th>
                <th  style={{ alignContent: "center" }}> {item.novedad ? "Si":"No"}</th>
                <th  style={{ alignContent: "center" }}> {item.valoracion_servicio}</th>
                <th  style={{ alignContent: "center" }}> {item.valoracion_servicio}</th>
                <th  style={{ alignContent: "center" }}> {item.trasl_unico ? "Si":"No"}</th>

                    </tr>
            
               )}
            )
            : []}

        </tbody>
        </Table>
    </div>
  )
}

export default TableSeguimiento
