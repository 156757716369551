import {
  FACTURA_LIST,
  FACTURA_FILTER,
  FACTURA_CREATE,
  FACTURA_UPDATE,
  FACTURA_UPDATE_SUCCESS,
  FACTURA_CREATE_SUCCESS,
  FACTURA_LIST_SUCCESS,
  FACTURA_REQUEST_FAILED,
} from "./actionTypes";

export const facturaList = () => {
  return {
    type: FACTURA_LIST,
    payload: {},
  };
};

export const facturaCreate = (factura) => {
  return {
    type: FACTURA_CREATE,
    payload: factura,
  };
};

export const facturaFilter = (changeUrl) => {
  return {
    type: FACTURA_FILTER,
    payload: { changeUrl },
  };
};

export const facturaUpdate = (id, data) => {
  return {
    type: FACTURA_UPDATE,
    payload: { id, data },
  };
};

export const facturaListSuccess = (factura) => {
  return {
    type: FACTURA_LIST_SUCCESS,
    payload: factura,
  };
};

export const facturaCreateSuccess = (facturaCreated) => {
  return {
    type: FACTURA_CREATE_SUCCESS,
    payload: facturaCreated,
  };
};

export const facturaUpdateSuccess = (factura) => {
  return {
    type: FACTURA_UPDATE_SUCCESS,
    payload: factura,
  };
};

export const facturaRequestFailed = (error) => {
  return {
    type: FACTURA_REQUEST_FAILED,
    payload: error,
  };
};
