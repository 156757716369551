import {
  PISOS_LIST,
  PISOS_FILTER,
  PISOS_CREATE,
  PISOS_UPDATE,
  PISOS_UPDATE_SUCCESS,
  PISOS_CREATE_SUCCESS,
  PISOS_LIST_SUCCESS,
  PISOS_REQUEST_FAILED,
  PISOS_FULLLIST,
  PISOS_FULLLIST_SUCCESS,
} from "./actionTypes";

export const pisosList = () => {
  return {
    type: PISOS_LIST,
    payload: {},
  };
};

export const pisosFullList = () => {
  return {
    type: PISOS_FULLLIST,
    payload: {},
  };
};

export const pisosCreate = (pisos) => {
  return {
    type: PISOS_CREATE,
    payload: pisos,
  };
};

export const pisosFilter = (changeUrl) => {
  return {
    type: PISOS_FILTER,
    payload: { changeUrl },
  };
};

export const pisosUpdate = (id, data) => {
  return {
    type: PISOS_UPDATE,
    payload: { id, data },
  };
};

export const pisosListSuccess = (pisos) => {
  return {
    type: PISOS_LIST_SUCCESS,
    payload: pisos,
  };
};

export const pisosFullListSuccess = (actividad_diaria) => {
  return {
    type: PISOS_FULLLIST_SUCCESS,
    payload: actividad_diaria,
  };
};

export const pisosCreateSuccess = (pisosCreated) => {
  return {
    type: PISOS_CREATE_SUCCESS,
    payload: pisosCreated,
  };
};

export const pisosUpdateSuccess = (pisos) => {
  return {
    type: PISOS_UPDATE_SUCCESS,
    payload: pisos,
  };
};

export const pisosRequestFailed = (error) => {
  return {
    type: PISOS_REQUEST_FAILED,
    payload: error,
  };
};
