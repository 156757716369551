import React, { useEffect, useState } from 'react'
import { getConfirmacionFilterAsync, getFacturaFilterAsync,
    getCoordinacionFilterAsync,getSeguimientoFilterAsync, getGiroFilterAsync
 } from '../../../helpers/backend';

import {   useSelector } from "react-redux";

function ComponentsButtons(props) {
 

    const [confirmData, setConfirmData] = useState(null)
    const [coordData, setCoordData] = useState(null)
    const [segData, setSegData] = useState(null)
    const [giroData, setGiroData] = useState(null)
    const [factData, setFactData] = useState(null)
    


    const confirmacion = useSelector((state) => state.Confirmacion.confirmacionCreated);
    const coordinacion = useSelector((state) => state.Coordinacion.coordinacionCreated);
    const seguimiento = useSelector((state) => state.Seguimiento.seguimientoCreated);
    const giro = useSelector((state) => state.Giro.giroCreated);
    const factura = useSelector((state) => state.Factura.facturaCreated);


    const getConfirmData = async () => {
        const response = await getConfirmacionFilterAsync(`rutero/?id=${props.item.id}`);
        const data = response.data;
    
        setConfirmData(data);
      };

    const getCoordData = async () => {
        const response = await getCoordinacionFilterAsync(`rutero/?id=${props.item.id}`);
        const data = response.data;
    
        setCoordData(data);
      };
    const getSeguiData = async () => {
        const response = await getSeguimientoFilterAsync(`rutero/?id=${props.item.id}`);
        const data = response.data;
    
        setSegData(data);
      };
    const getGiroData = async () => {
        const response = await getGiroFilterAsync(`rutero/?id=${props.item.id}`);
        const data = response.data;
    
        setGiroData(data);
      };
    const getFacturaData = async () => {
        const response = await getFacturaFilterAsync(`rutero/?id=${props.item.id}`);
        const data = response.data;
    
        setFactData(data);
      };


     
      useEffect(() => {
        getConfirmData();
        getCoordData()
        getSeguiData()
        getGiroData()
        getFacturaData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [confirmacion, coordinacion, seguimiento,giro, factura]);



 

  return (
    <React.Fragment>
        <button
                          // ç  style={{ boxShadow: botonActivo === "TODOS" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none"}}
                          style={{
                            margin: "2px",
                            maxWidth: "56px",
                            minWidth: "35px",
                            maxHeight: "59px",
                            minHeight: "27px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            backgroundColor: confirmData?.length > 0
                            ? (confirmData[0].status === 'CONFIRMADO' ? '#73a839' : 
                               confirmData[0].status === 'NO CONFIRMADO' ? '#ff8000' : '') 
                            : ''
                          }}
                          className={`btn btn-sm ms-1 ${
                            confirmData && confirmData.length === 0 ? 'btn-primary' : ''
                          } 
                          
                          ${props.styles['tooltip-container']}`}
                                           
                          disabled={confirmData?.length > 0 && confirmData[0].status === 'CONFIRMADO'} 
                          onClick={() => {
                         
                            props.setModalConfirm(true);
                            props.setItemFechaServicio(props.item.fecha_servicio);
                            props.setItemHoraServicio(props.item.hora_servicio);
                            props.setTipodeServicio(props.item.tipo_servicio);
                            props.setCiudad_origen(props.item.ciudad_origen);
                            props.setCiudad_destino(props.item.ciudad_destino);
                            props.setVereda(props.item.vereda);
                            props.setDireccion_origen(props.item.direccion_origen);
                            props.setDireccion_destino(props.item.direccion_destino);
                            props.setCantida(props.item.cantida);
                            props.setServicioGestionar(props.item.sev_gestionar);

                            props.setRutero(props.item.id);
                          }}
                        >
                             <span className={`${props.styles['tooltip']}`}>Confirmacion</span>
                         <i className="fas fa-wheelchair" aria-hidden="true"></i>
                        </button>
                        <button
                          style={{
                            margin: "2px",
                            maxWidth: "56px",
                            minWidth: "35px",
                            maxHeight: "59px",
                            minHeight: "27px",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                          disabled={ coordData && coordData.length > 0 ? true : false} 
                          // ç  style={{ boxShadow: botonActivo === "TODOS" ? "0px 0px 5px 4px rgba(0, 0, 0, 0.3)" : "none"}}
                        className={`btn btn-sm ms-1 ${
                            coordData && coordData.length === 0 ? 'btn-primary' : ''
                          } ${
                            coordData && coordData.length > 0 ?  'btn-success' : ''
                          } ${props.styles['tooltip-container']}`}
                          onClick={() => {
                            props.setModalCoordTransporte(true);
                            props.setItemFechaServicio(props.item.fecha_servicio);
                            props.setItemHoraServicio(props.item.hora_servicio);
                            props.setTipodeServicio(props.item.tipo_servicio);
                            props.setCiudad_origen(props.item.ciudad_origen);
                            props.setCiudad_destino(props.item.ciudad_destino);
                            props.setVereda(props.item.vereda);
                            props.setDireccion_origen(props.item.direccion_origen);
                            props.setDireccion_destino(props.item.direccion_destino);
                            props.setCantida(props.item.cantida);
                            props.setServicioGestionar(props.item.sev_gestionar);

                            props.setRutero(props.item.id);
                          }}
                        >
                            <span className={`${props.styles['tooltip']}`}>Transporte</span>
                             <i className="fas fa-car" aria-hidden="true"></i>
                        </button>

                        {/* Seguimiento */}
                        <button
                          style={{
                            margin: "2px",
                            maxWidth: "56px",
                            minWidth: "35px",
                            maxHeight: "59px",
                            minHeight: "27px",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                          disabled={ segData && segData.length > 0 ? true : false} 
                          className={`btn btn-sm ms-1 ${
                            segData && segData.length === 0 ? 'btn-primary' : ''
                          } ${
                            segData && segData.length > 0 ?  'btn-success' : ''
                          } ${props.styles['tooltip-container']}`}
                          onClick={() => {
                            props.setModalSeguimiento(true);
                            props.setItemFechaServicio(props.item.fecha_servicio);
                            props.setItemHoraServicio(props.item.hora_servicio);
                            props.setTipodeServicio(props.item.tipo_servicio);
                            props.setCiudad_origen(props.item.ciudad_origen);
                            props.setCiudad_destino(props.item.ciudad_destino);
                            props.setVereda(props.item.vereda);
                            props.setDireccion_origen(props.item.direccion_origen);
                            props.setDireccion_destino(props.item.direccion_destino);
                            props.setCantida(props.item.cantida);
                            props.setServicioGestionar(props.item.sev_gestionar);

                            props.setRutero(props.item.id);
                          }}
                        >
                            <span className={`${props.styles['tooltip']}`}>Seguimiento</span>
                          <i className="fas fa-check-circle" aria-hidden="true"></i>
                      
                        </button>

                        {/* Giro */}
                        <button
                          style={{
                            margin: "2px",
                            maxWidth: "56px",
                            minWidth: "35px",
                            maxHeight: "59px",
                            minHeight: "27px",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                          disabled={ giroData && giroData.length > 0 ? true : false} 
                          className={`btn btn-sm ms-1 ${
                            giroData && giroData.length === 0 ? 'btn-primary' : ''
                          } ${
                            giroData && giroData.length > 0 ?  'btn-success' : ''
                          } ${props.styles['tooltip-container']}`}
                          onClick={() => {
                            props.setModalCoordinador(true);
                            props.setItemFechaServicio(props.item.fecha_servicio);
                            props.setItemHoraServicio(props.item.hora_servicio);
                            props.setTipodeServicio(props.item.tipo_servicio);
                            props.setCiudad_origen(props.item.ciudad_origen);
                            props.setCiudad_destino(props.item.ciudad_destino);
                            props.setVereda(props.item.vereda);
                            props.setDireccion_origen(props.item.direccion_origen);
                            props.setDireccion_destino(props.item.direccion_destino);
                            props.setCantida(props.item.cantida);
                            props.setServicioGestionar(props.item.sev_gestionar);

                            props.setRutero(props.item.id);
                          }}
                        >
                            <span className={`${props.styles['tooltip']}`}>Coordinación</span>
                           <i className="fas fa-thumbtack" aria-hidden="true"></i>
                     
                        </button>

                        {/* Factura */}
                        <button
                          style={{
                            margin: "2px",
                            maxWidth: "56px",
                            minWidth: "35px",
                            maxHeight: "59px",
                            minHeight: "27px",
                            fontSize: "12px",
                            fontWeight: "bold",
                          }}
                          disabled={ factData && factData.length > 0 ? true : false} 
                          className={`btn btn-sm ms-1 ${
                            factData && factData.length === 0 ? 'btn-primary' : ''
                          } ${
                            factData && factData.length > 0 ?  'btn-success' : ''
                          } ${props.styles['tooltip-container']}`}
                          onClick={() => {
                             props.setModalFactura(true);
                             props.setItemFechaServicio(props.item.fecha_servicio);
                            props.setItemHoraServicio(props.item.hora_servicio);
                            props.setTipodeServicio(props.item.tipo_servicio);
                            props.setCiudad_origen(props.item.ciudad_origen);
                            props.setCiudad_destino(props.item.ciudad_destino);
                            props.setVereda(props.item.vereda);
                            props.setDireccion_origen(props.item.direccion_origen);
                            props.setDireccion_destino(props.item.direccion_destino);
                            props.setCantida(props.item.cantida);
                            props.setServicioGestionar(props.item.sev_gestionar);

                            props.setRutero(props.item.id);
                          }}
                        >
                            <span className={`${props.styles['tooltip']}`}>Factura</span>
                         <i className="fas fa-money-bill" aria-hidden="true"></i>
                        
                        </button> 
    </React.Fragment>
  )
}

export default ComponentsButtons
