import {
  SEGUIMIENTO_LIST,
  SEGUIMIENTO_FILTER,
  SEGUIMIENTO_CREATE,
  SEGUIMIENTO_CREATE_SUCCESS,
  SEGUIMIENTO_LIST_SUCCESS,
  SEGUIMIENTO_REQUEST_FAILED,
  SEGUIMIENTO_UPDATE,
  SEGUIMIENTO_UPDATE_SUCCESS,
} from "../../store/seguimiento/actionTypes";

const initialState = {
  seguimiento: [],
  seguimientoCreated: {},
  seguimientoUpdated: {},
  loading: false,
  error: "",
};

const seguimiento = (state = initialState, action) => {
  switch (action.type) {
    case SEGUIMIENTO_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SEGUIMIENTO_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SEGUIMIENTO_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SEGUIMIENTO_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SEGUIMIENTO_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        seguimientoCreated: action.payload,
        error: "",
      };
      break;
    case SEGUIMIENTO_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        seguimiento: action.payload,
        seguimientoCreated: {},
        seguimientoUpdated: {},
        error: "",
      };
      break;
    case SEGUIMIENTO_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        seguimientoUpdated: action.payload,
        error: "",
      };
      break;
    case SEGUIMIENTO_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default seguimiento;
