// COORDINACION requests
export const COORDINACION_LIST = "COORDINACION_LIST";
export const COORDINACION_CREATE = "COORDINACION_CREATE";
export const COORDINACION_FILTER = "COORDINACION_FILTER";
export const COORDINACION_UPDATE = "COORDINACION_UPDATE";
export const COORDINACION_DELETE = "COORDINACION_DELETE";
export const COORDINACION_READ = "COORDINACION_READ";

// COORDINACION success
export const COORDINACION_LIST_SUCCESS = "COORDINACION_LIST_SUCCESS";
export const COORDINACION_CREATE_SUCCESS = "COORDINACION_CREATE_SUCCESS";
export const COORDINACION_UPDATE_SUCCESS = "COORDINACION_UPDATE_SUCCESS";
export const COORDINACION_DELETE_SUCCESS = "COORDINACION_DELETE_SUCCESS";
export const COORDINACION_READ_SUCCESS = "COORDINACION_READ_SUCCESS";

// COORDINACION failed
export const COORDINACION_REQUEST_FAILED = "COORDINACION_REQUEST_FAILED";
