import {
  RUTERO_LIST,
  RUTERO_FILTER,
  RUTERO_CREATE,
  RUTERO_UPDATE,
  RUTERO_UPDATE_SUCCESS,
  RUTERO_CREATE_SUCCESS,
  RUTERO_LIST_SUCCESS,
  RUTERO_REQUEST_FAILED,
} from "./actionTypes";

export const ruteroList = () => {
  return {
    type: RUTERO_LIST,
    payload: {},
  };
};

export const ruteroCreate = (rutero) => {
  return {
    type: RUTERO_CREATE,
    payload: rutero,
  };
};

export const ruteroFilter = (changeUrl) => {
  return {
    type: RUTERO_FILTER,
    payload: { changeUrl },
  };
};

export const ruteroUpdate = (id, data) => {
  return {
    type: RUTERO_UPDATE,
    payload: { id, data },
  };
};

export const ruteroListSuccess = (rutero) => {
  return {
    type: RUTERO_LIST_SUCCESS,
    payload: rutero,
  };
};

export const ruteroCreateSuccess = (ruteroCreated) => {
  return {
    type: RUTERO_CREATE_SUCCESS,
    payload: ruteroCreated,
  };
};

export const ruteroUpdateSuccess = (rutero) => {
  return {
    type: RUTERO_UPDATE_SUCCESS,
    payload: rutero,
  };
};

export const ruteroRequestFailed = (error) => {
  return {
    type: RUTERO_REQUEST_FAILED,
    payload: error,
  };
};
