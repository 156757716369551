import {
  GIRO_LIST,
  GIRO_FILTER,
  GIRO_CREATE,
  GIRO_CREATE_SUCCESS,
  GIRO_LIST_SUCCESS,
  GIRO_REQUEST_FAILED,
  GIRO_UPDATE,
  GIRO_UPDATE_SUCCESS,
} from "../../store/giro/actionTypes";

const initialState = {
  giro: [],
  giroCreated: {},
  giroUpdated: {},
  loading: false,
  error: "",
};

const giro = (state = initialState, action) => {
  switch (action.type) {
    case GIRO_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GIRO_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GIRO_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GIRO_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GIRO_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        giroCreated: action.payload,
        error: "",
      };
      break;
    case GIRO_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        giro: action.payload,
        giroCreated: {},
        giroUpdated: {},
        error: "",
      };
      break;
    case GIRO_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        giroUpdated: action.payload,
        error: "",
      };
      break;
    case GIRO_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default giro;
