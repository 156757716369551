// GIRO requests
export const GIRO_LIST = "GIRO_LIST";
export const GIRO_CREATE = "GIRO_CREATE";
export const GIRO_FILTER = "GIRO_FILTER";
export const GIRO_UPDATE = "GIRO_UPDATE";
export const GIRO_DELETE = "GIRO_DELETE";
export const GIRO_READ = "GIRO_READ";

// GIRO success
export const GIRO_LIST_SUCCESS = "GIRO_LIST_SUCCESS";
export const GIRO_CREATE_SUCCESS = "GIRO_CREATE_SUCCESS";
export const GIRO_UPDATE_SUCCESS = "GIRO_UPDATE_SUCCESS";
export const GIRO_DELETE_SUCCESS = "GIRO_DELETE_SUCCESS";
export const GIRO_READ_SUCCESS = "GIRO_READ_SUCCESS";

// GIRO failed
export const GIRO_REQUEST_FAILED = "GIRO_REQUEST_FAILED";
