import { call, put, takeEvery } from "redux-saga/effects";

import {
  AUTORIZACION_LIST,
  AUTORIZACION_FILTER,
  AUTORIZACION_CREATE,
  AUTORIZACION_UPDATE,
} from "./actionTypes";

import {
 autorizacionListSuccess,
 autorizacionCreateSuccess,
 autorizacionUpdateSuccess,
 autorizacionRequestFailed,
} from "./actions";

import {
  getAutorizacionList,
  getAutorizacionFilter,
  postAutorizacionCreate,
  patchAutorizacionUpdate,
} from "../../helpers/backend";

function*autorizacionListSaga() {
  console.log("Trayendo lista de autorizacion...");
  try {
    const response = yield call(getAutorizacionList);
    if (response.status === 200) {
      yield put(autorizacionListSuccess(response.data));
    } else {
      yield put(autorizacionRequestFailed(response.data));
    }
  } catch (error) {
    yield put(autorizacionRequestFailed(error.response.data));
  }
}

function*autorizacionCreateSaga({ payload:autorizacion }) {
  console.log("Creando nuevo autorizacion...");
  try {
    const response = yield call(postAutorizacionCreate,autorizacion);
    if (response.status === 201) {
      yield put(autorizacionCreateSuccess(response.data));
    } else {
      yield put(autorizacionRequestFailed(response.data));
    }
  } catch (error) {
    yield put(autorizacionRequestFailed(error.response.data));
  }
}

function*autorizacionFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getAutorizacionFilter, { changeUrl });
    if (response.status === 200) {
      yield put(autorizacionListSuccess(response.data));
    } else {
      yield put(autorizacionRequestFailed(response.data));
    }
  } catch (error) {
    yield put(autorizacionRequestFailed(error.response.data));
  }
}

function*autorizacionUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del autorizacion...");
  try {
    const response = yield call(patchAutorizacionUpdate, { id }, data);
    if (response.status === 200) {
      yield put(autorizacionUpdateSuccess(response.data));
    } else {
      yield put(autorizacionRequestFailed(response.data));
    }
  } catch (error) {
    yield put(autorizacionRequestFailed(error.response.data));
  }
}

export default function*autorizacionSaga() {
  yield takeEvery(AUTORIZACION_LIST,autorizacionListSaga);
  yield takeEvery(AUTORIZACION_FILTER,autorizacionFilterSaga);
  yield takeEvery(AUTORIZACION_CREATE,autorizacionCreateSaga);
  yield takeEvery(AUTORIZACION_UPDATE,autorizacionUpdateSaga);
}
