// CONTACTSHC requests
export const CONTACTSHC_LIST = "CONTACTSHC_LIST";
export const CONTACTSHC_FULLLIST = "CONTACTSHC_FULLLIST";
export const CONTACTSHC_CREATE = "CONTACTSHC_CREATE";
export const CONTACTSHC_FILTER = "CONTACTSHC_FILTER";
export const CONTACTSHC_UPDATE = "CONTACTSHC_UPDATE";
export const CONTACTSHC_DELETE = "CONTACTSHC_DELETE";
export const CONTACTSHC_READ = "CONTACTSHC_READ";

// CONTACTSHC success
export const CONTACTSHC_LIST_SUCCESS = "CONTACTSHC_LIST_SUCCESS";
export const CONTACTSHC_CREATE_SUCCESS = "CONTACTSHC_CREATE_SUCCESS";
export const CONTACTSHC_UPDATE_SUCCESS = "CONTACTSHC_UPDATE_SUCCESS";
export const CONTACTSHC_DELETE_SUCCESS = "CONTACTSHC_DELETE_SUCCESS";
export const CONTACTSHC_READ_SUCCESS = "CONTACTSHC_READ_SUCCESS";
export const CONTACTSHC_FULLLIST_SUCCESS = "CONTACTSHC_FULLLIST_SUCCESS";

// CONTACTSHC failed
export const CONTACTSHC_REQUEST_FAILED = "CONTACTSHC_REQUEST_FAILED";
