import React, { useEffect, useState } from "react";  
import MetaTags from 'react-meta-tags';  
import { Container, Card, Table, Button, Row, Col } from "reactstrap";  
import Breadcrumbs from "../../components/Common/Breadcrumb";  
import Loader from "../../components/Common/Loader";  
import { getGestionesContactoRead, getReportSupervisor } from "../../helpers/backend";  
import { Link } from "react-router-dom/cjs/react-router-dom";  
import ModalManagementDetails from "../Whatsapp/components/ModalManagementDetails";

const ReportSupervisor = () => {  
  const [loading, setLoading] = useState(false);  
  const [data, setData] = useState([]);  
  const [enEspera, setEnEspera] = useState({});  
  const [gestionesData, setGestionesData] = useState([]);  
  const [detallesModal, setDetallesModal] = useState(false);
  const [GestionDetallada, setGestionDetallada] = useState(false);

  const getReportData = async () => {  
    setLoading(true);  
    const response = await getReportSupervisor();  
    setData(response.data.agentes);  
    setEnEspera(response.data.en_espera);  
    setLoading(false);  
    console.log(response);  
  };  

  useEffect(() => {  
    getReportData();  
  }, []);  

  const handleGestionesClick = (gestiones) => {  
    setGestionesData(gestiones);  
  };  

  const handleCloseDetails = () => {  
    setGestionesData([]); // Limpiamos los datos de gestiones para ocultar la tabla  
  };  
 const handleGestionDetallada = async (id) =>{
  const response = await getGestionesContactoRead(id)
  setGestionDetallada(response.data)
  setDetallesModal(true)
 }
  return (  
    <React.Fragment>  
      <div className="page-content">  
        <MetaTags>  
          <title> TrueContact | Reporte de Supervisión </title>  
        </MetaTags>  
        <Container fluid>  
          <Breadcrumbs title="TrueContact" breadcrumbItem="Reporte de Supervisión" />  
          {loading ? (  
            <Loader />  
          ) : (  
            <Card className="p-2"> 
             {detallesModal ? (
              <ModalManagementDetails
                isOpen={detallesModal}
                onCloseClick={()=> setDetallesModal(false)}
                GestionDetallada={GestionDetallada}
              ></ModalManagementDetails>
              ) : null} 
              <Row>  
                <Col>  
                  <h5 className="p-3">Conversaciones Atendidas</h5>  
                  <Table className="table text-center mb-3" hover>  
                    <thead className="table-dark align-middle">  
                      <tr>  
                        <th>Agente</th>  
                        <th>Estado</th>  
                        <th>En Gestión</th>  
                        <th>Finalizadas Hoy</th> 
                        <th>Última Conexión</th> 
                      </tr>  
                    </thead>  
                    <tbody>  
                      {data.map((agente) => (  
                        <tr key={agente.id}>  
                          <td>{agente.full_name}</td>  
                          <td>  
                            {agente.status === "CONECTADO" ? (  
                              <span className="badge bg-success">Conectado</span>  
                            ) : agente.status === "DESCONECTADO" ? (  
                              <span className="badge bg-danger">Desconectado</span>  
                            ) : null}  
                          </td>  
                          <td>  
                            <Link to="#" onClick={() => handleGestionesClick(agente.gestiones_en_gestion)}>  
                              {agente.gestiones_en_gestion_count}  
                            </Link>  
                          </td>  
                          <td>  
                            <Link to="#" onClick={() => handleGestionesClick(agente.gestiones_finalizadas_hoy)}>  
                              {agente.gestiones_finalizadas_hoy_count}  
                            </Link>  
                          </td>  
                          <td>{agente.last_login}</td> 
                        </tr>  
                      ))}  
                    </tbody>  
                  </Table>  
                </Col>  
                <Col>  
                  <h5 className="p-3">Conversaciones en Espera</h5>  
                  <Table className="table text-center mb-0">  
                    <thead className="table-dark align-middle">  
                      <tr>  
                        <th>Campaña</th>  
                        <th>En Espera</th>  
                      </tr>  
                    </thead>  
                    <tbody>  
                      {Object.entries(enEspera).map(([campaign, details]) => (  
                        <tr key={campaign}>  
                          <td>{campaign}</td>  
                          <td>  
                            <Link to="#" onClick={() => handleGestionesClick(details.gestiones)}>  
                              {details.count}  
                            </Link>  
                          </td>  
                        </tr>  
                      ))}  
                    </tbody>  
                  </Table>  
                </Col>  
              </Row>  
              {gestionesData.length > 0 && (  
                <Row className="d-flex justify-content-center">  
                  <Col className="col-lg-10 ">  
                    <h5 className="p-3">Detalles de Gestiones  
                      <Button color="danger" className="ms-2 btn-sm" onClick={handleCloseDetails}>Cerrar</Button>  
                    </h5>  
                    <Table className="table text-center mb-3" hover>  
                      <thead className="table-dark align-middle">  
                        <tr>  
                          <th>Contacto</th>  
                          <th>Estado</th>  
                          <th>Fecha</th>  
                          <th>Canal de Comunicación</th>
                          <th>Acción</th>  
                        </tr>  
                      </thead>  
                      <tbody>  
                        {gestionesData.map((gestion) => (  
                          <tr key={gestion.id}>  
                            <td>{gestion.contacto}</td>  
                            <td>{gestion.estado}</td>  
                            <td>{gestion.created_date}</td>  
                            <td>{gestion.type}</td>
                            <td><Link to= "#" onClick={()=> handleGestionDetallada(gestion.id)}><span className="fas fa-eye fa-lg"></span></Link></td>  
                          </tr>  
                        ))}  
                      </tbody>  
                    </Table>  
                  </Col>  
                </Row>  
              )}  
            </Card>  
          )}  
        </Container>  
      </div>  
    </React.Fragment>  
  );  
};  

export default ReportSupervisor;