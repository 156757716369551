import {
  AREAS_LIST,
  AREAS_FILTER,
  AREAS_CREATE,
  AREAS_CREATE_SUCCESS,
  AREAS_LIST_SUCCESS,
  AREAS_REQUEST_FAILED,
  AREAS_UPDATE,
  AREAS_UPDATE_SUCCESS,
  AREAS_FULLLIST,
  AREAS_FULLLIST_SUCCESS,
} from "../../store/areas/actionTypes";

const initialState = {
  areas: [],
  areasCreated: {},
  areasUpdated: {},
  loading: false,
  error: "",
};

const areas = (state = initialState, action) => {
  switch (action.type) {
    case AREAS_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AREAS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
      case AREAS_FULLLIST:
        state = {
          ...state,
          loading: true,
        };
        break;
    case AREAS_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AREAS_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AREAS_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        areasCreated: action.payload,
        error: "",
      };
      break;
    case AREAS_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        areas: action.payload,
        areasCreated: {},
        areasUpdated: {},
        error: "",
      };
      break;
      case AREAS_FULLLIST_SUCCESS:
        state = {
          ...state,
          loading: false,
          areas: action.payload,
          areasCreated: {},
          areasUpdated: {},
          error: "",
        };
        break;
    case AREAS_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        areasUpdated: action.payload,
        error: "",
      };
      break;
    case AREAS_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default areas;
