import React, {useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Table,
  Input,
  Button,
  InputGroup,
  Modal,
  Label,
} from "reactstrap";


// Redux
import { connect, useDispatch} from "react-redux";
import { withRouter, Link} from "react-router-dom";


//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Pagination from "../../components/Common/Pagination";
import Loader from "../../components/Common/Loader";

//Action
import { tipoGestionContactoList } from "../../store/actions";
import { tipoGestionContactoListChange } from "../../store/actions";
import { getTipoGestionContactoRead, patchTipoGestionContactoUpdate } from "../../helpers/backend";
import SweetAlert from "react-bootstrap-sweetalert";


const TipoGestionContacto = (props) => {
const dispatch= useDispatch();
const user = JSON.parse(localStorage.getItem("user"))
const groups  =String(user.groups);


const [search, setSearch] = useState("");
const [tipoGestionContacto, setTipoGestionContacto]= useState("")
const [tipoGestionContactoId, setTipoGestionContactoId]= useState(null)
const [show, setShow] = useState(false);
const [showAlert, setShowAlert] = useState(false);

const [tipoGestionContactoDetail, setTipoGestionContactoDetail]= useState({})
const getTipoGestionContactoDetailData = async (id) => {
  const response = await getTipoGestionContactoRead(id);
  const data = response.data;
  setTipoGestionContactoDetail(data);
  setTipoGestionContactoId(id)
};
useEffect(() => {
  dispatch(tipoGestionContactoList());
},[dispatch]);

useEffect(() => {
  if (tipoGestionContactoDetail.name) {
    setTipoGestionContacto(tipoGestionContactoDetail.name);
  }
}, [tipoGestionContactoDetail]);

const onChange = (e) => {
  setSearch(e.target.value);
};

const onSubmit = (e) => {
  e.preventDefault();
  dispatch(tipoGestionContactoListChange(`?search=${search}`));
};

const onUpdateTipoGestionContacto= () =>{
  patchTipoGestionContactoUpdate(tipoGestionContactoId,{
    name: tipoGestionContacto,
  })
  setShow(false)
  setShowAlert(true)
}

const resetSearch = () => {
  dispatch(tipoGestionContactoListChange(`?search=`));
  setSearch("");
};

const params = {
  count: props.tipoGestionContacto.count,
  next: props.tipoGestionContacto.next,
  previous: props.tipoGestionContacto.previous,
  results: props.tipoGestionContacto.results,
};

  return (
    <React.Fragment>
    <div className="page-content">
      <MetaTags>
        <title>TrueContact | Tipo de Gestion </title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumb */}
        <Breadcrumb title={"TrueContact"} breadcrumbItem={"Tipo de Gestion"} />
        {props.loading ? (
          <Loader />
        ) : (
          <div>
            <Card className= "col-lg-10 mx-auto  mt-3">
              <Modal
                  isOpen={show}
                  centered={true}
                  toggle = {()=> setShow(false)}                 

                >
                  <div className="modal-header">
                      <h3 className="modal-title mt-2">
                         Actualizar Tipo de Gestion 
                      </h3>
                      <button
                          type="button"
                          onClick={() => {
                              setShow(false);
                          }}
                          className="btn btn-lg"
                          data-dismiss="modal"
                          aria-label="Close"
                      >
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div className="modal-body">
                  <Row className="col-lg-12 justify-content-center">
                  <Col lg={10} className="mb-3">
                      <Label
                        for="name"
                        className="form-label"
                      >
                     Nombre
                      </Label>
                      <Input
                          type="text"
                          className="form-control"
                          name="name"
                          value={tipoGestionContacto}
                          onChange= {(e)=> setTipoGestionContacto(e.target.value) }                        
                          
                        />
                    </Col>
                    </Row>

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary text-dark"
                      onClick={() => {                        
                        setShow(false)
                      }}
                      > Atras
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={()=> onUpdateTipoGestionContacto()}   
                    >
                          Actualizar
                    </button>
                  </div>
                </div>
              </Modal>
              {showAlert  ? (
              <SweetAlert
                title="Hecho!"
                success
                timeout={2000}
                showConfirm={false}
                onConfirm={() => {
                  dispatch (tipoGestionContactoList());                  
                  setShowAlert(false)
                }}
              >
                Se ha Actualizado el  Tipo de Gestion del Contacto con éxito 
              </SweetAlert>
            ) : null}
              <CardBody>
                <Row>
                  <Col md={7}>
                  {groups.includes("SuperAdmin") ||  groups.includes("Supervisor") || groups.includes("Operador") || groups.includes("Crear y modificar TipoGestionContacto") ? (
                    <Link
                      className="btn btn-primary waves-effect waves-light"
                      to="/tipoGestionContacto/add_tipoGestion"
                    >
                      Agregar{" "}
                      <span className=""></span>
                    </Link>
                  ):null}
                    <Link
                      className="btn btn-success waves-effect waves-light ms-1"
                      to="/campaign"
                    >
                     Volver
                      <span className=""></span>
                    </Link>
                  </Col>
                  <Col md={5}>
                    <Form
                      className="search-box"
                      onSubmit={onSubmit}
                    >
                      <InputGroup>
                        <Input
                          type="text"
                          className="form-control"
                          name="search"
                          placeholder="Buscar"
                          value={search}
                          onChange={onChange}
                          
                        />
                        <i className="bx bx-search-alt search-icon" />
                        <Button color="primary" onClick={resetSearch}>
                          <span className=" bx bx-x fa-lg align-middle"></span>
                        </Button>
                      </InputGroup>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className= "col-lg-10 mx-auto  mt-3">
            {params.count !== 0 ? (
                <CardBody>
                  <h4 className="card-title">Tipo de Gestion del Contacto</h4>
                  <div className="table-responsive">
                    <Table
                      className="table  mb-4 text-center "
                      hover
                      responsive

                    >
                      <thead className="table-dark">
                        <tr>
                          <th>#</th>
                          <th>Nombre del Tipo de Gestion</th>
                          {groups.includes("SuperAdmin") ||  groups.includes("Supervisor") || groups.includes("Operador") || groups.includes("Crear y modificar TipoGestionContacto") ? (
                          <th>Acción</th> 
                          ):null}
                        </tr>
                      </thead>
                      <tbody>
                          {params.results &&
                            params.results.map((tipoGestionContacto, idx) => (
                              <tr key={idx}>
                                <th scope="row">{idx + 1}</th>
                                <td>{tipoGestionContacto.name}</td>                                
                                <td>
                                {groups.includes("SuperAdmin") ||  groups.includes("Supervisor") || groups.includes("Operador") || groups.includes("Crear y modificar TipoGestionContacto") ? (
                                <Link to = '#'
                                onClick={()=> { getTipoGestionContactoDetailData(tipoGestionContacto.id); setShow(true) }}                               
                                  >
                                    <span className="bx bx-edit bx-sm align-middle"></span>
                                  </Link> 
                                  ):null}
                                </td> 
                              </tr>
                            ))}
                        </tbody>
                      
                    </Table>
                  </div>

                   <Pagination
                      count={params.count}
                      next={params.next}
                      previous={params.previous}
                      limit={10}
                      baseUrl="tramite/tipoGestionContacto/"
                      filterFunction={tipoGestionContactoListChange}
                    />

                </CardBody>
          ) : ( 
                <CardBody>
                  <h4 className="card-title">No se encontraron resultados</h4>
                </CardBody>
          )}
            </Card>
        
          </div>
        )}
      </Container>

    </div>
  </React.Fragment>
);
};
const mapStateToProps = (state) => {
  const { error, loading, tipoGestionContacto } = state.TipoGestionContacto;
  return { error, loading, tipoGestionContacto };
};

export default withRouter(connect(mapStateToProps)(TipoGestionContacto));