// AUTORIZACION requests
export const AUTORIZACION_LIST = "AUTORIZACION_LIST";
export const AUTORIZACION_CREATE = "AUTORIZACION_CREATE";
export const AUTORIZACION_FILTER = "AUTORIZACION_FILTER";
export const AUTORIZACION_UPDATE = "AUTORIZACION_UPDATE";
export const AUTORIZACION_DELETE = "AUTORIZACION_DELETE";
export const AUTORIZACION_READ = "AUTORIZACION_READ";

// AUTORIZACION success
export const AUTORIZACION_LIST_SUCCESS = "AUTORIZACION_LIST_SUCCESS";
export const AUTORIZACION_CREATE_SUCCESS = "AUTORIZACION_CREATE_SUCCESS";
export const AUTORIZACION_UPDATE_SUCCESS = "AUTORIZACION_UPDATE_SUCCESS";
export const AUTORIZACION_DELETE_SUCCESS = "AUTORIZACION_DELETE_SUCCESS";
export const AUTORIZACION_READ_SUCCESS = "AUTORIZACION_READ_SUCCESS";

// AUTORIZACION failed
export const AUTORIZACION_REQUEST_FAILED = "AUTORIZACION_REQUEST_FAILED";
