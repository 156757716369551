import React, { useState } from "react";
import { Col, Row, Form, Input, Label, Modal, Button, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { seguimientoCreate, seguimientoFilter } from "../../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import TableConfirmacion from "../Tables/TableConfirmacion";
import TableTransporte from "../Tables/TableTransporte";
import TableRuteroInfo from "../Tables/TableRuteroInfo";
function Seguimiento(props) {
  const dispatch = useDispatch();
  const Seguimiento = useSelector((state) => state.Seguimiento);
  const [servicio_prestado, setservicio_prestado] = useState(null);
  const [servicio_tiempo, setservicio_tiempo] = useState(null);
  const [vehiculo_blanco, setvehiculo_blanco] = useState(null);
  const [novedad, setnovedad] = useState(null);
  const [trasl_unico, settrasl_unico] = useState(null);

  const coordinacion = useSelector((state) => state.Coordinacion.coordinacion);

  const confirmacion = useSelector((state) => state.Confirmacion.confirmacion);

  const [formData, setFormData] = useState({
    nota: "",

    queja: "",
    observaciones: "",
    interno: "",

    valoracion_cond: "",
    valoracion_servicio: "",
  });

  const {
    nota,
    queja,
    observaciones,
    interno,
    valoracion_cond,
    valoracion_servicio,
  } = formData;

  const onChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onChangeBoolean = (event) => {
    const value = event.target.value === "true";
    console.log(value); // Esto será true o false
    setservicio_prestado(value);
    // Luego puedes enviar 'value' al backend como un booleano
  };
  const onChangeServicioTiempo = (event) => {
    const value = event.target.value === "true";
    console.log(value); // Esto será true o false
    setservicio_tiempo(value);
    // Luego puedes enviar 'value' al backend como un booleano
  };
  const onChangeVehiculoBlanco = (event) => {
    const value = event.target.value === "true";
    console.log(value); // Esto será true o false
    setvehiculo_blanco(value);
    // Luego puedes enviar 'value' al backend como un booleano
  };

  const onChangeNoveda = (event) => {
    const value = event.target.value === "true";
    console.log(value); // Esto será true o false
    setnovedad(value);
    // Luego puedes enviar 'value' al backend como un booleano
  };

  const onChangeTraslado = (event) => {
    const value = event.target.value === "true";
    console.log(value); // Esto será true o false
    settrasl_unico(value);
    // Luego puedes enviar 'value' al backend como un booleano
  };

  const onSubmit = (event) => {
    event.preventDefault();

    dispatch(
      seguimientoCreate({
        rutero: props.id,
        nota,
        queja,
        observaciones,
        interno,
        servicio_prestado,
        valoracion_cond,
        servicio_tiempo,
        vehiculo_blanco,
        novedad,
        valoracion_servicio,
        trasl_unico,
      })
    );
  };

  return (
    <div>
      {Seguimiento.seguimientoCreated.id ? (
        <SweetAlert
          title="¡Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            dispatch(seguimientoFilter(`rutero/?id=${props.id}`));
            // resetFormData();

            props.onCloseClick();
          }}
        >
          Se ha agregado el Seguimiento correctamente.
        </SweetAlert>
      ) : null}

      <Modal
        isOpen={props.ModalSeguimiento}
        toggle={props.onCloseClick}
        size="xl"
      >
        <div className="modal-header">
          <h3 className="modal-title mt-2">Crear Seguimiento</h3>
          <button
            type="button"
            onClick={props.onCloseClick}
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            {/* <span aria-hidden="true">&times;</span> */}
          </button>
        </div>
        <div className="d-flex justify-content-center">

<h4 style={{ margin: "10px" }}>Rutero: {props.selectedNumGestion}</h4>
</div>

        <div style={{ margin: "10px" }}>
          <TableRuteroInfo
            itemFechaServicio={props.itemFechaServicio}
            itemHoraServicio={props.itemHoraServicio}
            tipodeServicio={props.tipodeServicio}
            ciudad_origen={props.ciudad_origen}
            ciudad_destino={props.ciudad_destino}
            vereda={props.vereda}
            direccion_origen={props.direccion_origen}
            direccion_destino={props.direccion_destino}
            cantida={props.cantida}
          ></TableRuteroInfo>
          <TableConfirmacion></TableConfirmacion>
          <TableTransporte></TableTransporte>
        </div>

        <Form className="form-control">
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="nota" className="form-label">
                Nota del Seguimiento del servicio
              </Label>
              <Input
                id="nota"
                name="nota"
                type="text"
                onChange={onChange}
                value={nota}
              />
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="queja" className="form-label">
                Queja
              </Label>
              <Input
                id="queja"
                name="queja"
                type="text"
                onChange={onChange}
                value={queja}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="observaciones" className="form-label">
                Observaciones servicio Compartido
              </Label>
              <Input
                id="observaciones"
                name="observaciones"
                type="text"
                onChange={onChange}
                value={observaciones}
              />
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="interno" className="form-label">
                Interno 
              </Label>
              <select
                className="form-select"
                id="interno"
                name="interno"
                onChange={onChange}
                value={interno}
                required
              >
                <option defaultValue>Seleccione una opción</option>
                <option value="CABIFY">CABIFY</option>
                <option value="GIRO EVENTO">GIRO EVENTO</option> 
                <option value="GIRO">GIRO</option>
                <option value="360">360</option>
                <option value="CONDUCTOR">CONDUCTOR</option>
              </select>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="servicio_prestado" className="form-label">
                ¿El servicio fue prestado?
              </Label>
              <select
                className="form-select"
                id="servicio_prestado"
                name="servicio_prestado"
                onChange={onChangeBoolean}
                value={servicio_prestado}
                required
              >
                <option defaultValue>Seleccione una opción</option>
                <option value="true">SI</option>
                <option value="false">NO</option>
              </select>
              {Seguimiento.error && Seguimiento.error.servicio_prestado ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {" "}
                  {Seguimiento.error.servicio_prestado}
                </p>
              ) : null}
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="valoracion_cond" className="form-label">
                ¿Cómo fue la atención prestada por el conductor?
              </Label>
              <select
                className="form-select"
                id="valoracion_cond"
                name="valoracion_cond"
                onChange={onChange}
                value={valoracion_cond}
                required
              >
                <option defaultValue>Seleccione una opción</option>
                <option value="EXCELENTE">EXCELENTE</option>
                <option value="BUENO">BUENO</option>
                <option value="REGULAR">REGULAR</option>
                <option value="MALO">MALO</option>
              </select>
            </Col>
          </Row>

          <Row>
            <Col lg={6} className="mb-3">
              <Label for="servicio_tiempo" className="form-label">
                ¿El servicio se prestó a tiempo?
              </Label>
              <select
                className="form-select"
                id="servicio_tiempo"
                name="servicio_tiempo"
                onChange={onChangeServicioTiempo}
                value={servicio_tiempo}
                required
              >
                <option defaultValue>Seleccione una opción</option>
                <option value="true">SI</option>
                <option value="false">NO</option>
              </select>
              {Seguimiento.error && Seguimiento.error.servicio_tiempo ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {" "}
                  {Seguimiento.error.servicio_tiempo}
                </p>
              ) : null}
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="vehiculo_blanco" className="form-label">
                ¿El vehiculo que le prestó el servicio fue Blanco?
              </Label>
              <select
                className="form-select"
                id="vehiculo_blanco"
                name="vehiculo_blanco"
                onChange={onChangeVehiculoBlanco}
                value={vehiculo_blanco}
                required
              >
                <option defaultValue>Seleccione una opción</option>
                <option value="true">SI</option>
                <option value="false">NO</option>
              </select>
              {Seguimiento.error && Seguimiento.error.vehiculo_blanco ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {" "}
                  {Seguimiento.error.vehiculo_blanco}
                </p>
              ) : null}
            </Col>
          </Row>
          <Row></Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="novedad" className="form-label">
                ¿Presentó alguna novedad con el servicio?
              </Label>
              <select
                className="form-select"
                id="novedad"
                name="novedad"
                onChange={onChangeNoveda}
                value={novedad}
                required
              >
                <option defaultValue>Seleccione una opción</option>
                <option value="true">SI</option>
                <option value="false">NO</option>
              </select>

              {Seguimiento.error && Seguimiento.error.novedad ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {" "}
                  {Seguimiento.error.novedad}
                </p>
              ) : null}
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="trasl_unico" className="form-label">
                ¿Su traslado fue único?
              </Label>
              <select
                className="form-select"
                id="trasl_unico"
                name="trasl_unico"
                onChange={onChangeTraslado}
                value={trasl_unico}
                required
              >
                <option defaultValue>Seleccione una opción</option>
                <option value="true">SI</option>
                <option value="false">NO</option>
              </select>
              {Seguimiento.error && Seguimiento.error.trasl_unico ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {" "}
                  {Seguimiento.error.trasl_unico}
                </p>
              ) : null}
            </Col>
          </Row>

          <Row>
            <Col lg={6} className="mb-3">
              <Label for="valoracion_servicio" className="form-label">
                ¿Cómo califica la calidad de nuestro servicio?
              </Label>
              <select
                className="form-select"
                id="valoracion_servicio"
                name="valoracion_servicio"
                onChange={onChange}
                value={valoracion_servicio}
                required
              >
                <option defaultValue>Seleccione una opción</option>
                <option value="EXCELENTE">EXCELENTE</option>
                <option value="BUENO">BUENO</option>
                <option value="REGULAR">REGULAR</option>
                <option value="MALO">MALO</option>
              </select>
            </Col>
          </Row>
        </Form>

        <Row className="col-lg-12 justify-content-center mt-4">
          <Col md={1} className="mx-1">
            <Button color="primary" type="button" onClick={onSubmit}>
              Enviar
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default Seguimiento;
