import React, { useState } from "react";
import { Col, Row, Form, Input, Label, Modal,  Button } from "reactstrap";
import CurrencyInput from "react-currency-input-field";
import { useDispatch, useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { giroCreate, giroFilter } from "../../../store/actions";
import TableConfirmacion from "../Tables/TableConfirmacion";
import TableRuteroInfo from "../Tables/TableRuteroInfo";
import TableSeguimiento from "../Tables/TableSeguimiento";
import TableTransporte from "../Tables/TableTransporte";
function Coordinador(props) {
  const dispatch = useDispatch();

  const giro = useSelector((state) => state.Giro);
  const [total_giro, settotal_giro] = useState("");
  const [valor_flete, setvalor_flete] = useState("");
  const [f_contable, setf_contable] = useState("");

  const onChangeFechaContable = (e) => {
    setf_contable(e.target.value);
  };
  const [formData, setFormData] = useState({
    empresa: "",

    observacion: "",
    pin_gelsa: "",
    conductor : "",
    placa : "",
    bauche : "",
  });

  const {
    empresa,

    observacion,
    pin_gelsa,
    conductor,
    placa,
    bauche
  } = formData;

  const onChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      giroCreate({
        rutero: props.id,
        total_giro,
        f_contable,
        empresa,
        valor_flete,
        observacion,
        pin_gelsa,
        conductor,
        placa,
        bauche
        // Pasa "Colombia" como valor de la propiedad 'pais'
      })
    );
  };

  return (
    <div>
      {giro.giroCreated.id ? (
        <SweetAlert
          title="¡Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            dispatch(giroFilter(`rutero/?id=${props.id}`));

            props.onCloseClick();
          }}
        >
          Se ha agregado el Giro correctamente.
        </SweetAlert>
      ) : null}

      <Modal
        isOpen={props.ModalCoordinador}
        toggle={props.onCloseClick}
        size="xl"
      >
        <div className="modal-header">
          <h3 className="modal-title mt-2">Coordinador </h3>
          <button
            type="button"
            onClick={props.onCloseClick}
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            {/* <span aria-hidden="true">&times;</span> */}
          </button>
        </div>
        <div className="d-flex justify-content-center">

<h4 style={{ margin: "10px" }}>Rutero: {props.selectedNumGestion}</h4>
</div>

        <div style={{ margin: "10px" }}>
          <TableRuteroInfo
            itemFechaServicio={props.itemFechaServicio}
            itemHoraServicio={props.itemHoraServicio}
            tipodeServicio={props.tipodeServicio}
            ciudad_origen={props.ciudad_origen}
            ciudad_destino={props.ciudad_destino}
            vereda={props.vereda}
            direccion_origen={props.direccion_origen}
            direccion_destino={props.direccion_destino}
            cantida={props.cantida}
          ></TableRuteroInfo>
          <TableConfirmacion></TableConfirmacion>
          <TableTransporte></TableTransporte>
          <TableSeguimiento></TableSeguimiento>
        </div>
        <Form className="form-control">
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="total_giro" className="form-label">
                TOTAL A GIRAR AL USUARIO
              </Label>
              <CurrencyInput
                id="total_giro"
                name="total_giro"
                className="form-control"
                placeholder="$0"
                value={total_giro}
                decimalsLimit={3}
                prefix="$"
                onValueChange={(value, name, values) => settotal_giro(value)}
              />

              {giro.error && giro.error.total_giro ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {giro.error.total_giro}
                </p>
              ) : null}
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="nombre_empresa" className="form-label">
                FECHA CONTABLE DE PAGO
              </Label>
              <input
                className="form-control d-block"
                placeholder="Fecha"
                onChange={(e) => onChangeFechaContable(e)}
                type="date"
              />

              {giro.error && giro.error.f_contable ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {giro.error.f_contable}
                </p>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="empresa" className="form-label">
                EMPRESA DE PAGO
              </Label>
              <Input
                id="empresa"
                name="empresa"
                type="text"
                onChange={onChange}
                value={empresa}
                required
              />
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="valor_flete" className="form-label">
                VALOR FLETE ENVIO
              </Label>
              <CurrencyInput
                id="valor_flete"
                name="valor_flete"
                className="form-control"
                placeholder="0$"
                value={valor_flete}
                decimalsLimit={3}
                prefix="$"
                onValueChange={(value, name, values) => setvalor_flete(value)}
              />
              {giro.error && giro.error.valor_flete ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {giro.error.valor_flete}
                </p>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="observacion" className="form-label">
                OBSERVACION CONTABILIDAD
              </Label>
              <Input
                id="observacion"
                name="observacion"
                type="text"
                onChange={onChange}
                value={observacion}
                required
              />
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="pin_gelsa" className="form-label">
                Pin Transacción
              </Label>
              <Input
                id="pin_gelsa"
                name="pin_gelsa"
                type="text"
                onChange={onChange}
                value={pin_gelsa}
              />
            </Col>
            </Row>

            <Row>
            <Col lg={6} className="mb-3">
              <Label for="conductor" className="form-label">
                Conductor
              </Label>
              <Input
                id="conductor"
                name="conductor"  
                type="text"
                onChange={onChange}
                value={conductor} 
              />
            </Col>
            <Col lg={6} className="mb-3"> 
              <Label for="placa" className="form-label">
                Placa
              </Label>              
              <Input
                id="placa"    
                name="placa"
                type="text"   
                onChange={onChange} 
                value={placa} 
              />              
            </Col>            
        

            </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="bauche" className="form-label">
                Bauche
              </Label>
              <Input
                id="bauche"
                name="bauche"
                type="text"
                onChange={onChange}
                value={bauche}
              />
            </Col>    
         
          </Row>

          <Row className="col-lg-12 justify-content-center mt-4">
            <Col md={1} className="mx-1">
              <Button color="primary" type="button" onClick={onSubmit}>
                Enviar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default Coordinador;
