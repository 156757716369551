// FACTURA requests
export const FACTURA_LIST = "FACTURA_LIST";
export const FACTURA_CREATE = "FACTURA_CREATE";
export const FACTURA_FILTER = "FACTURA_FILTER";
export const FACTURA_UPDATE = "FACTURA_UPDATE";
export const FACTURA_DELETE = "FACTURA_DELETE";
export const FACTURA_READ = "FACTURA_READ";

// FACTURA success
export const FACTURA_LIST_SUCCESS = "FACTURA_LIST_SUCCESS";
export const FACTURA_CREATE_SUCCESS = "FACTURA_CREATE_SUCCESS";
export const FACTURA_UPDATE_SUCCESS = "FACTURA_UPDATE_SUCCESS";
export const FACTURA_DELETE_SUCCESS = "FACTURA_DELETE_SUCCESS";
export const FACTURA_READ_SUCCESS = "FACTURA_READ_SUCCESS";

// FACTURA failed
export const FACTURA_REQUEST_FAILED = "FACTURA_REQUEST_FAILED";
