import {
  CONTACTSHC_LIST,
  CONTACTSHC_FILTER,
  CONTACTSHC_CREATE,
  CONTACTSHC_CREATE_SUCCESS,
  CONTACTSHC_LIST_SUCCESS,
  CONTACTSHC_REQUEST_FAILED,
  CONTACTSHC_UPDATE,
  CONTACTSHC_UPDATE_SUCCESS,
  CONTACTSHC_FULLLIST,
  CONTACTSHC_FULLLIST_SUCCESS,
} from "../../store/contactshc/actionTypes";

const initialState = {
  contactshc: [],
  contactshcCreated: {},
  contactshcUpdated: {},
  loading: false,
  error: "",
};

const contactshc = (state = initialState, action) => {
  switch (action.type) {
    case CONTACTSHC_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONTACTSHC_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONTACTSHC_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONTACTSHC_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONTACTSHC_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        contactshcCreated: action.payload,
        error: "",
      };
      break;
      case CONTACTSHC_FULLLIST:
        state = {
          ...state,
          loading: true,
        };
        break;
        case CONTACTSHC_FULLLIST_SUCCESS:
          state = {
            ...state,
            loading: false,
            contactshc: action.payload,
            contactshcCreated: {},
            contactshcDeleted: false,
            error: "",
          };
          break;
    case CONTACTSHC_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        contactshc: action.payload,
        contactshcCreated: {},
        contactshcUpdated: {},
        error: "",
      };
      break;
    case CONTACTSHC_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        contactshcUpdated: action.payload,
        error: "",
      };
      break;
    case CONTACTSHC_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default contactshc;
