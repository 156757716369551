import React, { useState } from "react";
import { Col, Row, Form, Input, Label, Modal, Button } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { coordinacionCreate, coordinacionFilter } from "../../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";

import "flatpickr/dist/themes/material_blue.css";
import TableConfirmacion from "../Tables/TableConfirmacion";
import TableRuteroInfo from "../Tables/TableRuteroInfo";

function CoordTransporte(props) {
  const dispatch = useDispatch();

  const coordinacion = useSelector((state) => state.Coordinacion);
  const confirmacion = useSelector((state) => state.Confirmacion.confirmacion);
  console.log(confirmacion);
  const [formData, setFormData] = useState({
    status: "",
    conductor: "",
    conductor_doc: "",
    placa: "",

  });

  const { status, conductor, conductor_doc , placa} = formData;
  const onChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      coordinacionCreate({
        status,
        conductor,
        conductor_doc,
        rutero: props.id,
        placa

        // Pasa "Colombia" como valor de la propiedad 'pais'
      })
    );
  };

  return (
    <div>
      {coordinacion.coordinacionCreated.id ? (
        <SweetAlert
          title="¡Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            dispatch(coordinacionFilter(`rutero/?id=${props.id}`));

            props.onCloseClick();
          }}
        >
          Se ha agregado la coordinacion correctamente.
        </SweetAlert>
      ) : null}

      <Modal
        isOpen={props.ModalCoordTransporte}
        toggle={props.onCloseClick}
        size="xl"
      >
        <div className="modal-header">
          <h3 className="modal-title mt-2">Coordinador Transporte</h3>
          <button
            type="button"
            onClick={props.onCloseClick}
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            {/* <span aria-hidden="true">&times;</span> */}
          </button>
        </div>

        <div className="d-flex justify-content-center">

<h4 style={{ margin: "10px" }}>Rutero: {props.selectedNumGestion}</h4>
</div>


        <div style={{ margin: "10px" }}>
          <TableRuteroInfo
            itemFechaServicio={props.itemFechaServicio}
            itemHoraServicio={props.itemHoraServicio}
            tipodeServicio={props.tipodeServicio}
            ciudad_origen={props.ciudad_origen}
            ciudad_destino={props.ciudad_destino}
            vereda={props.vereda}
            direccion_origen={props.direccion_origen}
            direccion_destino={props.direccion_destino}
            cantida={props.cantida}
          ></TableRuteroInfo>

          <TableConfirmacion></TableConfirmacion>
        </div>

        <Form className="form-control">
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="status" className="form-label">
                Estado de Servicio
              </Label>
            
              <select
                className="form-select"
                id="tipo_silla"
                name="status"
                onChange={onChange}
                value={status}
                required
              >
                <option defaultValue>Seleccione una opción</option>
                <option value="NO PROGRAMADO">NO PROGRAMADO</option>
                <option value="PROGRAMADO">PROGRAMADO</option>
                <option value="PROGRAMAR">PROGRAMAR</option>
                <option value="NO PRESTADO">NO PRESTADO</option>
                <option value="FALLIDA">FALLIDA</option>
                <option value="CANCELADO">CANCELADO</option>
              </select>

              {coordinacion.error && coordinacion.error.status ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {coordinacion.error.status}
                </p>
              ) : null}
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="conductor" className="form-label">
                Conductor Asociado a prestar el servicio
              </Label>
              <Input
                id="conductor"
                name="conductor"
                type="text"
                onChange={onChange}
                value={conductor}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={6} className="mb-3">
              <Label for="conductor_doc" className="form-label">
                Numero Cedula Conductor
              </Label>
              <Input
                id="conductor_doc"
                name="conductor_doc"
                type="text"
                onChange={onChange}
                value={conductor_doc}
              />
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="placa" className="form-label">
                Placa
              </Label>
              <Input
                id="placa"
                name="placa"
                type="text"
                onChange={onChange}
                value={placa}
              />
            </Col>
          </Row>

          <Row className="col-lg-12 justify-content-center mt-4">
            <Col md={1} className="mx-1">
              <Button color="primary" type="button" onClick={onSubmit}>
                Enviar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default CoordTransporte;
