import React, { useEffect } from "react";
import { Table } from "reactstrap";

import { coordinacionFilter } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";

function TableTransporte(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(coordinacionFilter(`rutero/?id=${props.id}`));
  }, [dispatch, props.id]);

  const coordinacion = useSelector((state) => state.Coordinacion.coordinacion);
  
  return (
    <div className="table-responsive" style={{ overflowX: "auto" }}>
      <Table
       className="table table-bordered table-striped text-center mb-3  "
       hover
       responsive
      >
    
        <thead className="table-dark align-middle">
          <tr key={props.id}>

          <th>Estado Transporte</th>
          <th>Conductor</th>
          <th>Documento</th>
          <th>Placa</th>
          <th>Funcionario</th>
        
          </tr>
        </thead>
        <tbody>
          {Array.isArray(coordinacion)
            ? coordinacion.map((item) => {
                return (
          
                    <tr key={item.id}>
                      <th> {item.status}</th>
                      <th> {item.conductor}</th>
                      <th> {item.conductor_doc}</th>
                      <th> {item.placa}</th>
                      <th> {item.user}</th>
                    </tr>
                
                );
              })
            : []}
        </tbody>
      </Table>
    </div>
  );
}

export default TableTransporte;
