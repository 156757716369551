import {
  COORDINACION_LIST,
  COORDINACION_FILTER,
  COORDINACION_CREATE,
  COORDINACION_UPDATE,
  COORDINACION_UPDATE_SUCCESS,
  COORDINACION_CREATE_SUCCESS,
  COORDINACION_LIST_SUCCESS,
  COORDINACION_REQUEST_FAILED,
} from "./actionTypes";

export const coordinacionList = () => {
  return {
    type: COORDINACION_LIST,
    payload: {},
  };
};

export const coordinacionCreate = (coordinacion) => {
  return {
    type: COORDINACION_CREATE,
    payload: coordinacion,
  };
};

export const coordinacionFilter = (changeUrl) => {
  return {
    type: COORDINACION_FILTER,
    payload: { changeUrl },
  };
};

export const coordinacionUpdate = (id, data) => {
  return {
    type: COORDINACION_UPDATE,
    payload: { id, data },
  };
};

export const coordinacionListSuccess = (coordinacion) => {
  return {
    type: COORDINACION_LIST_SUCCESS,
    payload: coordinacion,
  };
};

export const coordinacionCreateSuccess = (coordinacionCreated) => {
  return {
    type: COORDINACION_CREATE_SUCCESS,
    payload: coordinacionCreated,
  };
};

export const coordinacionUpdateSuccess = (coordinacion) => {
  return {
    type: COORDINACION_UPDATE_SUCCESS,
    payload: coordinacion,
  };
};

export const coordinacionRequestFailed = (error) => {
  return {
    type: COORDINACION_REQUEST_FAILED,
    payload: error,
  };
};
