import {
  ROOMS_LIST,
  ROOMS_FILTER,
  ROOMS_CREATE,
  ROOMS_UPDATE,
  ROOMS_UPDATE_SUCCESS,
  ROOMS_CREATE_SUCCESS,
  ROOMS_LIST_SUCCESS,
  ROOMS_REQUEST_FAILED,
  ROOMS_FULLLIST,
  ROOMS_FULLLIST_SUCCESS,
} from "./actionTypes";

export const roomsList = () => {
  return {
    type: ROOMS_LIST,
    payload: {},
  };
};

export const roomsFullList = () => {
  return {
    type: ROOMS_FULLLIST,
    payload: {},
  };
};

export const roomsCreate = (rooms) => {
  return {
    type: ROOMS_CREATE,
    payload: rooms,
  };
};

export const roomsFilter = (changeUrl) => {
  return {
    type: ROOMS_FILTER,
    payload: { changeUrl },
  };
};

export const roomsUpdate = (id, data) => {
  return {
    type: ROOMS_UPDATE,
    payload: { id, data },
  };
};

export const roomsListSuccess = (rooms) => {
  return {
    type: ROOMS_LIST_SUCCESS,
    payload: rooms,
  };
};

export const roomsFullListSuccess = (actividad_diaria) => {
  return {
    type: ROOMS_FULLLIST_SUCCESS,
    payload: actividad_diaria,
  };
};

export const roomsCreateSuccess = (roomsCreated) => {
  return {
    type: ROOMS_CREATE_SUCCESS,
    payload: roomsCreated,
  };
};

export const roomsUpdateSuccess = (rooms) => {
  return {
    type: ROOMS_UPDATE_SUCCESS,
    payload: rooms,
  };
};

export const roomsRequestFailed = (error) => {
  return {
    type: ROOMS_REQUEST_FAILED,
    payload: error,
  };
};
