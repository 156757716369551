import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Input, Table, Label, Button, Modal } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  confirmacionCreate,
  confirmacionFilter,
  confirmacionUpdate,
} from "../../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import departamentos from "../Departamentos";
import { getConfirmacionFilterAsync } from "../../../helpers/backend";

function CallCenterConfirmacion(props) {
  const dispatch = useDispatch();
  const [confirmData, setConfirmData] = useState(null);
  const [horaCita, setHoraCita] = useState(null);
  const [horaRecogida, setHoraRecogida] = useState(null);
  const [horaRetorno, setHoraRetorno] = useState(null);
  const [silla_rueda, setSillaRueda] = useState(null);
  const [rutero_confirm, setrutero_confirm] = useState(false);
  const [fecha_servicio, setSelectedFechaServicio] = useState(props.itemFechaServicio);
  const [horaServicio, setHoraCitaRutero] = useState();
  const [vereda, setVereda] = useState(props.vereda);
  const [direccionOrigen, setDireccionOrigen] = useState(props.direccion_origen);
  const [direccionDestino, setDireccionDestino] = useState(props.direccion_destino);
  const [cantidad, setCantida] = useState(props.cantida);
  const Confirmacion = useSelector((state) => state.Confirmacion);




  const allCities = departamentos
    .flatMap((department) => department.cities)
    .sort((a, b) => a.localeCompare(b));

  const flatpickrRef = useRef(null);
  const flatpickrRefHoraCita = useRef(null);
  const flatpickrRefHoraRecogida = useRef(null);
  let hora_cita = "";
  let hora_recogida = "";
  let hora_retorno = "";

  const [formData, setFormData] = useState({
    nota: "",
    tipo_silla: "",
    accom_name: "",
    accom_documento: "",
    tipo_servicio: props.tipodeServicio,
    
  });


  const {nota,tipo_silla,accom_name,accom_documento,tipo_servicio} = formData;

  const onChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onChangeBoolean = (event) => {
    const value = event.target.value === "true";
    console.log(value); // Esto será true o false
    setSillaRueda(value);
    // Luego puedes enviar 'value' al backend como un booleano
  };

  const onChangeHoraCita = (e) => {
    if (e && e.target && e.target.value) {
      setHoraCita(e.target.value);
    }
  };

  const onChangeHoraRecogida = (e) => {
    if (e && e.target && e.target.value) {
      setHoraRecogida(e.target.value);
    }
  };

  const onChangeHoraRetorno = (e) => {
    if (e && e.target && e.target.value) {
      setHoraRetorno(e.target.value);
    }
  };

  let horaCitaRec = horaCita?.toString().slice(0, 5);
  hora_cita = horaCitaRec;

  let horaRecogidaRec = horaRecogida?.toString().slice(0, 5);
  hora_recogida = horaRecogidaRec;

  let horaRetornoRec = horaRetorno?.toString().slice(0, 5);
  hora_retorno = horaRetornoRec;

  ////Infordel ruter
  const onChangeFechaServicio = (e) => {
    setSelectedFechaServicio(e.target.value);
  };

  const [hora_servicio, setHoraPrueba] = useState(props.itemHoraServicio);

  const onChangeHoraCitaRutero = (e) => {
    if (e && e.target && e.target.value) {
      setHoraCitaRutero(e.target.value);
      setHoraPrueba(e.target.value.toString().slice(0, 5)); // Aplicando slice aquí
    }
  };

  const [selectedCiudadOrigen, setSelectedCiudadOrigen] = useState(props.ciudad_origen);
  const [selectedCiudadDestino, setSelectedCiudadDestino] = useState(props.ciudad_destino);

  const handleCiudadOrigenChange = (e) => {
    const ciudadSeleccionada = e.target.value;
    setSelectedCiudadOrigen(ciudadSeleccionada); // Resetea la ciudad seleccionada cuando cambias el departamento
  };

  const handleCiudadDestinoChange = (e) => {
    setSelectedCiudadDestino(e.target.value);
    // Resetea la ciudad seleccionada cuando cambias el departamento
  };

  const getConfirmData = async () => {
    const response = await getConfirmacionFilterAsync(`rutero/?id=${props.id}`);
    const data = response.data;

    setConfirmData(data);
  };

  useEffect(() => {
    getConfirmData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (confirmData?.[0]?.hora_cita) {
      setHoraCitaRutero(confirmData[0].hora_cita);
    }
    if (confirmData?.[0]?.hora_recogida) {
      setHoraRecogida(confirmData[0].hora_recogida);
    }
    if (confirmData?.[0]?.hora_retorno) {
      setHoraRetorno(confirmData[0].hora_retorno);
    }
    if (confirmData?.[0]?.silla_rueda !== undefined) {
      // Verifica que el valor no sea `undefined`
      setSillaRueda(confirmData[0].silla_rueda);
    }
    if (confirmData?.[0]) {
      setSelectedFechaServicio(confirmData[0].fecha_servicio);
      setHoraPrueba(confirmData[0].hora_servicio);
      setSelectedCiudadOrigen(confirmData[0].ciudad_origen);
      setSelectedCiudadDestino(confirmData[0].ciudad_destino);
      setCantida(confirmData[0].cantidad);
      setVereda(confirmData[0].vereda);
      setDireccionOrigen(confirmData[0].direccion_origen);
      setDireccionDestino(confirmData[0].direccion_destino);
      setFormData((prevFormData) => ({
        ...prevFormData,
        nota: confirmData[0].nota || "",
        tipo_silla: confirmData[0].tipo_silla || "",
        accom_name: confirmData[0].accom_name || "",
        accom_documento: confirmData[0].accom_documento || "",
        tipo_servicio: confirmData[0].tipo_servicio || "",
        vereda: confirmData[0].vereda || "",
        direccion_origen: confirmData[0].direccion_origen || "",
        direccion_destino: confirmData[0].direccion_destino || "",
      }));
    }
  }, [confirmData]);


  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      confirmacionCreate({
        nota,

        tipo_silla,
        accom_name,
        accom_documento,
        rutero: props.id,
        hora_cita,
        hora_recogida,
        hora_retorno,
        silla_rueda,

        fecha_servicio,
        hora_servicio,
        tipo_servicio,
        ciudad_origen: selectedCiudadOrigen,
        ciudad_destino: selectedCiudadDestino,
        vereda,
        direccion_origen: direccionOrigen,
        direccion_destino: direccionDestino,
        status: "NO CONFIRMADO",
        cantidad

        // Pasa "Colombia" como valor de la propiedad 'pais'
      })
    );
  };

  const onConfirm = (e) => {
    e.preventDefault();
    dispatch(
      confirmacionUpdate(confirmData?.[0]?.id, {
        nota,

        tipo_silla,
        accom_name,
        accom_documento,
        rutero: props.id,
        hora_cita,
        hora_recogida,
        hora_retorno,
        silla_rueda,

        fecha_servicio,
        hora_servicio,
        tipo_servicio,
        ciudad_origen: selectedCiudadOrigen,
        ciudad_destino: selectedCiudadDestino,
        vereda,
        direccion_origen: direccionOrigen,
        direccion_destino: direccionDestino,
        status: "CONFIRMADO",
        cantidad

        // Pasa "Colombia" como valor de la propiedad 'pais'
      })
    );
  };

  const resetFormData = () => {
    setSillaRueda("");
    flatpickrRef.current.flatpickr.clear();
    flatpickrRefHoraCita.current.flatpickr.clear();
    flatpickrRefHoraRecogida.current.flatpickr.clear();
    setFormData({
      nota: "",

      tipo_silla: "",
      accom_name: "",
      accom_documento: "",
    });
  };

  return (
    <div>
      {Confirmacion.confirmacionCreated.id ? (
        <SweetAlert
          title="¡Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            dispatch(confirmacionFilter(`rutero/?id=${props.id}`));
            resetFormData();
            props.onCloseClick();
          }}
        >
          Se ha agregado la Confirmacion correctamente.
        </SweetAlert>
      ) : null}
      {Confirmacion.confirmacionUpdated.id ? (
        <SweetAlert
          title="¡Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            dispatch(confirmacionFilter(`rutero/?id=${props.id}`));
            resetFormData();
            props.onCloseClick();
          }}
        >
          Se ha actualizado la Confirmacion correctamente.
        </SweetAlert>
      ) : null}

      <Modal isOpen={props.ModalConfirm} toggle={props.onCloseClick} size="xl">
        <div className="modal-header">
          <h3 className="modal-title mt-2">Crear Callcenter Confirmacion     </h3>
          <br />
        
          <button
            type="button"
            onClick={props.onCloseClick}
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            {/* <span aria-hidden="true">&times;</span> */}
          </button>


        </div>
        <div className="d-flex justify-content-center">

        <h4 style={{ margin: "10px" }}>Rutero: {props.selectedNumGestion}</h4>
        </div>

        <div style={{ margin: "10px" }}>
          <Table
            className="table table-bordered table-striped text-center mb-3"
            hover
            responsive
          >
            <thead className="table-dark align-middle">
              <tr>
                <th style={{ alignContent: "center" }}>Fecha de Servicio</th>
                <th style={{ alignContent: "center" }}>Hora de Servicio</th>
                <th style={{ alignContent: "center" }}>Tipo de Servicio</th>
                <th style={{ alignContent: "center" }}>Ciudad Origen </th>

                <th style={{ alignContent: "center" }}>Ciudad Destino </th>
                <th style={{ alignContent: "center" }}>Vereda </th>
                <th style={{ alignContent: "center" }}>Direccion Origen </th>

                <th style={{ alignContent: "center" }}>Direccion Destino </th>
                <th style={{ alignContent: "center" }}>Cantidad </th>

                <th style={{ alignContent: "center" }}>Accion </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{props.itemFechaServicio}</td>
                <td>{props.itemHoraServicio}</td>
                <td>{props.tipodeServicio}</td>
                <td>{props.ciudad_origen}</td>
                <td>{props.ciudad_destino}</td>
                <td>{props.vereda}</td>
                <td>{props.direccion_origen}</td>
                <td>{props.direccion_destino}</td>
                <td>{props.cantida}</td>

                <td>
                  <span
                    className="btn bx bx-edit bx-sm align-middle"
                    style={{ color: "#2fa4e7" }}
                    onClick={() => setrutero_confirm(!rutero_confirm)}
                  ></span>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        {rutero_confirm ? (
          <div style={{ margin: "10px" }}>
            <Table
              className="table table-bordered table-striped text-center mb-3"
              hover
              responsive
            >
              <thead className="table-dark align-middle">
                <tr>
                  <th style={{ alignContent: "center" }}>Fecha de Servicio</th>
                  <th style={{ alignContent: "center" }}>Hora de Servicio</th>
                  <th style={{ alignContent: "center" }}>Tipo de Servicio</th>
                  <th style={{ alignContent: "center" }}>Ciudad Origen </th>

                  <th style={{ alignContent: "center" }}>Ciudad Destino </th>
                  <th style={{ alignContent: "center" }}>Vereda </th>
                  <th style={{ alignContent: "center" }}>Direccion Origen </th>

                  <th style={{ alignContent: "center" }}>Direccion Destino </th>
                  <th style={{ alignContent: "center" }}>Cantidad </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      className="form-control"
                      type="date"
                      style={{ width: "130px" }}
                      value={fecha_servicio}
                      onChange={(e) => onChangeFechaServicio(e)}
                    />
                  </td>
                  <td>
                    <Flatpickr
                      className="form-control d-block"
                      placeholder="Hora de Cita"
                      style={{ width: "120px" }}
                      value={hora_servicio}
                      onInput={onChangeHoraCitaRutero}
                      ref={flatpickrRef}
                      options={{
                        enableTime: true,

                        noCalendar: true,

                        dateFormat: "H:i K",
                        minuteIncrement: "15",
                      }}
                    />
                  </td>
                  <td>
                    <select
                      className="form-select"
                      style={{ width: "120px" }}
                      id="tipo_servicio"
                      name="tipo_servicio"
                      onChange={onChange}
                      value={tipo_servicio}
                      required
                    >
                      <option defaultValue>Seleccione una opción</option>
                      <option value="CARRETERO">Carretero</option>
                      <option value="LOCAL">Local</option>
                    </select>
                  </td>
                  <td>
                    <select
                      style={{ width: "120px" }}
                      value={selectedCiudadOrigen}
                      onChange={handleCiudadOrigenChange}
                      className="form-control"
                    >
                      <option value="">Selecciona una ciudad</option>
                      {allCities.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                      style={{ width: "120px" }}
                      value={selectedCiudadDestino}
                      onChange={handleCiudadDestinoChange}
                      className="form-control"
                    >
                      <option value="">Selecciona una ciudad</option>
                      {allCities.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      style={{ width: "120px" }}
                      value={vereda}
                      onChange={(e) => setVereda(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      style={{ width: "120px" }}
                      value={direccionOrigen}
                      onChange={(e) => setDireccionOrigen(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      style={{ width: "120px" }}
                      value={direccionDestino}
                      onChange={(e) => setDireccionDestino(e.target.value)}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control"
                      style={{ width: "120px" }}
                      value={cantidad}
                      onChange={(e) => setCantida(e.target.value)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        ) : null}

        <Form className="form-control">
          {/* Formulario de confirmacion del rutero */}

          {/* Formulario de Confirmacion */}
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="nombre_empresa" className="form-label">
                Hora cita
              </Label>

              <Flatpickr
                className="form-control d-block"
                placeholder="Hora de Cita"
                value={horaServicio}
                onInput={onChangeHoraCita}
                ref={flatpickrRefHoraCita}
                options={{
                  enableTime: true,

                  noCalendar: true,

                  dateFormat: "H:i K",
                  minuteIncrement: 15,
                }}
              />
            </Col>

            <Col lg={6} className="mb-3">
              <Label for="tipo_empleo" className="form-label">
                Hora de Recogida
              </Label>
              <Flatpickr
                className="form-control d-block"
                placeholder="Hora de Recogida"
                value={horaRecogida}
                onInput={onChangeHoraRecogida}
                ref={flatpickrRefHoraRecogida}
                options={{
                  enableTime: true,

                  noCalendar: true,

                  dateFormat: "H:i K",
                  minuteIncrement: "15",
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="nombre_empresa" className="form-label">
                Hora de retorno
              </Label>
              <Flatpickr
                className="form-control d-block"
                placeholder="Hora de Recogida"
                value={horaRetorno}
                onInput={onChangeHoraRetorno}
                ref={flatpickrRef}
                options={{
                  enableTime: true,

                  noCalendar: true,

                  dateFormat: "H:i K",
                  minuteIncrement: "15",
                }}
              />
            </Col>

            <Col lg={6} className="mb-3">
              <Label for="nota" className="form-label">
                Nota de Confirmacion
              </Label>
              <Input
                id="nota"
                name="nota"
                type="text"
                onChange={onChange}
                value={nota}
                required
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="silla_rueda" className="form-label">
                Utiliza silla de ruedas
              </Label>
              <select
                className="form-select"
                id="silla_rueda"
                name="silla_rueda"
                onChange={onChangeBoolean}
                value={silla_rueda !== null ? String(silla_rueda) : ""} // Convertimos a string
                required
              >
                <option defaultValue>Seleccione una opción</option>
                <option value="true">SI</option>
                <option value="false">NO</option>
              </select>
              {Confirmacion.error && Confirmacion.error.silla_rueda ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {Confirmacion.error.silla_rueda}
                </p>
              ) : null}
            </Col>

            {silla_rueda ? (
              <Col lg={6} className="mb-3">
                <Label for="tipo_silla" className="form-label">
                  Tipo de Silla de Ruedas
                </Label>
                <select
                  className="form-select"
                  id="tipo_silla"
                  name="tipo_silla"
                  onChange={onChange}
                  value={tipo_silla}
                  required
                >
                  <option defaultValue>Seleccione una opción</option>
                  <option value="CAMINADOR">CAMINADOR</option>
                  <option value="NEUROLOGIA">NEUROLOGIA</option>
                  <option value="PLEGABLE">PLEGABLE</option>
                </select>

                {Confirmacion.error && Confirmacion.error.tipo_silla ? (
                  <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                    {Confirmacion.error.tipo_silla}
                  </p>
                ) : null}
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="accom_name" className="form-label">
                Nombre Acompañante
              </Label>
              <Input
                id="accom_name"
                name="accom_name"
                type="text"
                onChange={onChange}
                value={accom_name}
              />
            </Col>

            <Col lg={6} className="mb-3">
              <Label for="accom_documento" className="form-label">
                Documento del Acompañante
              </Label>
              <Input
                id="accom_documento"
                name="accom_documento"
                type="text"
                onChange={onChange}
                value={accom_documento}
                required
              />
            </Col>
          </Row>

          <Row className="col-lg-12 justify-content-center mt-4">
            {confirmData?.length === 0 ? (
              <Col md={1} className="mx-1">
                <Button color="primary" type="button" onClick={onSubmit}>
                  Crear
                </Button>
              </Col>
            ) : (
              <Col md={1} className="mx-1">
                <Button color="primary" type="button" onClick={onConfirm}>
                  Confirmar
                </Button>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default CallCenterConfirmacion;
