import { call, put, takeEvery } from "redux-saga/effects";

import {
  GIRO_LIST,
  GIRO_FILTER,
  GIRO_CREATE,
  GIRO_UPDATE,
} from "./actionTypes";

import {
 giroListSuccess,
 giroCreateSuccess,
 giroUpdateSuccess,
 giroRequestFailed,
} from "./actions";

import {
  getGiroList,
  getGiroFilter,
  postGiroCreate,
  patchGiroUpdate,
} from "../../helpers/backend";

function*giroListSaga() {
  console.log("Trayendo lista de giro...");
  try {
    const response = yield call(getGiroList);
    if (response.status === 200) {
      yield put(giroListSuccess(response.data));
    } else {
      yield put(giroRequestFailed(response.data));
    }
  } catch (error) {
    yield put(giroRequestFailed(error.response.data));
  }
}

function*giroCreateSaga({ payload:giro }) {
  console.log("Creando nuevo giro...");
  try {
    const response = yield call(postGiroCreate,giro);
    if (response.status === 201) {
      yield put(giroCreateSuccess(response.data));
    } else {
      yield put(giroRequestFailed(response.data));
    }
  } catch (error) {
    yield put(giroRequestFailed(error.response.data));
  }
}

function*giroFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getGiroFilter, { changeUrl });
    if (response.status === 200) {
      yield put(giroListSuccess(response.data));
    } else {
      yield put(giroRequestFailed(response.data));
    }
  } catch (error) {
    yield put(giroRequestFailed(error.response.data));
  }
}

function*giroUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del giro...");
  try {
    const response = yield call(patchGiroUpdate, { id }, data);
    if (response.status === 200) {
      yield put(giroUpdateSuccess(response.data));
    } else {
      yield put(giroRequestFailed(response.data));
    }
  } catch (error) {
    yield put(giroRequestFailed(error.response.data));
  }
}

export default function*giroSaga() {
  yield takeEvery(GIRO_LIST,giroListSaga);
  yield takeEvery(GIRO_FILTER,giroFilterSaga);
  yield takeEvery(GIRO_CREATE,giroCreateSaga);
  yield takeEvery(GIRO_UPDATE,giroUpdateSaga);
}
