// RUTERO requests
export const RUTERO_LIST = "RUTERO_LIST";
export const RUTERO_CREATE = "RUTERO_CREATE";
export const RUTERO_FILTER = "RUTERO_FILTER";
export const RUTERO_UPDATE = "RUTERO_UPDATE";
export const RUTERO_DELETE = "RUTERO_DELETE";
export const RUTERO_READ = "RUTERO_READ";

// RUTERO success
export const RUTERO_LIST_SUCCESS = "RUTERO_LIST_SUCCESS";
export const RUTERO_CREATE_SUCCESS = "RUTERO_CREATE_SUCCESS";
export const RUTERO_UPDATE_SUCCESS = "RUTERO_UPDATE_SUCCESS";
export const RUTERO_DELETE_SUCCESS = "RUTERO_DELETE_SUCCESS";
export const RUTERO_READ_SUCCESS = "RUTERO_READ_SUCCESS";

// RUTERO failed
export const RUTERO_REQUEST_FAILED = "RUTERO_REQUEST_FAILED";
