import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Form,
  Button,
  CardTitle,
  Input,
  FormFeedback,
} from "reactstrap";
import Select from 'react-select';
import SweetAlert from "react-bootstrap-sweetalert";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../components/Common/Loader";

import { getAgent, getCasosRead, getContactsFullList } from "../../helpers/backend";

import { casosList, casosUpdate } from "../../store/actions";

const AutorizacionesUpdate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const id = useParams().id;

  const getCasosData = async () => {
    const response = await getCasosRead(id);
    const data = response.data;
    console.log(data);
    setFormData({
      name: data.name,
      tipo_servicio: data.tipo_servicio,
      observaciones: data.observaciones,
      descripcion: data.descripcion,
      contacto: data.contacto,
      cliente: data.cliente,
      direccion_1: data.direccion_1,
      direccion_2: data.direccion_2,
      images: data.conimagestacto,
    });
  };
  const [contactList, setContactList] = useState([]);
  const getContactListData = async () => {
    const response = await getContactsFullList();
    const data = response.data;
    setContactList(data);
  };
  const  [agent, setAgent] = useState ({});
  const getAgentData = async () => {
    const response = await getAgent();
    const data = response.data;
    setAgent(data)
  }
  useEffect(() => {
    getCasosData();
    getContactListData();
    getAgentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    tipo_servicio: "",
    observaciones: "",
    descripcion: "",
    contacto: [],
    cliente: [],
    direccion_1: "", 
    direccion_2: "",
    images: [],
  });

  const {
    name,
    tipo_servicio,
    observaciones,
    descripcion,
    direccion_1, 
    direccion_2,
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const selectedContactValues = Array.isArray(formData.contacto) ? formData.contacto.map((option) => option[1]):[];
    const selectedClientValues = Array.isArray(formData.cliente) ? formData.cliente.map((option) => option[1]):[];
    // Crea un nuevo objeto formDataToSend solo con los valores necesarios
    const formDataToSend = {
      ...formData,
      contacto: selectedContactValues,
      cliente: selectedClientValues,
    };
    dispatch(casosUpdate(id, formDataToSend));
  };

  const redirect = () => {
    dispatch(casosList())
    history.push("/casos");
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Editar Casos</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem="Editar Casos" />
          {props.loading ? (
            <Loader />
          ) : (
            <div className="d-flex justify-content-center">
              {props.casosUpdated.id ? (
                <SweetAlert
                  title="¡Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha actualizado <b>{props.casosUpdated.nombre}</b> correctamente.
                </SweetAlert>
              ) : null}

              <Card
                style={{
                  width: "60rem",
                }}
              >
                <CardTitle className="ps-3 pt-3">
                  <h2 className="mb-3 font-size-20">Casos</h2>
                </CardTitle>
                <CardBody>
                  <Form onSubmit={onSubmit}>
                    <Row className="justify-content-center">
                      <Col lg={10}>
                        <Row className="mt-3">
                          <Col>
                            <Label for="name">Nombre</Label>
                            <Input
                              id="name"
                              name="name"
                              type="name"
                              onChange={onChange}
                              value={name}
                              autoFocus
                              disabled
                              required
                            />
                            {props.error && props.error.name ? (
                              <FormFeedback>{props.error.name}</FormFeedback>
                            ) : null}
                          </Col>
                          <Col>
                            <Label for="tipo_servicio">Tipo de Servicio</Label>
                            <select
                              className="form-select"
                              id="tipo_servicio"
                              name="tipo_servicio"
                              type="text"
                              value={tipo_servicio}
                              onChange={onChange}
                              required
                            >
                              <option defaultValue>Seleccione una opción</option>
                              <option value="DESPLAZAMIENTO">Desplazamiento</option>
                              <option value="PRESENCIAL">Presencial</option>
                              <option value="REMOTO">Remoto</option>
                              <option value="MIXTO">Mixto</option>
                              <option value="OTRO">Otro</option>
                            </select>
                            {props.error && props.error.tipo_servicio ? (
                            <FormFeedback>{props.error.tipo_servicio}</FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                        {tipo_servicio === "DESPLAZAMIENTO" && (
                        <Row className="mt-3">
                          <Col>
                            <Label for="direccion_1">Ubicación</Label>
                            <Input
                              id="direccion_1"
                              name="direccion_1"
                              type="text"
                              onChange={onChange}
                              value={direccion_1}
                              required
                            />
                            {props.error && props.error.direccion_1 ? (
                              <FormFeedback>{props.error.direccion_1}</FormFeedback>
                            ) : null}
                          </Col>
                          <Col>
                            <Label for="direccion_2">Destino</Label>
                            <Input
                              id="direccion_2"
                              name="direccion_2"
                              type="text"
                              onChange={onChange}
                              value={direccion_2}
                              required
                            />
                            {props.error && props.error.direccion_2 ? (
                              <FormFeedback>{props.error.direccion_2}</FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                  )}
                        <Row className="mt-3">
                          <Col>
                            <Label for="observaciones">Observaciones</Label>
                            <Input
                              id="observaciones"
                              name="observaciones"
                              type="text"
                              onChange={onChange}
                              value={observaciones}
                              required
                            />
                            {props.error && props.error.observaciones ? (
                              <FormFeedback>{props.error.observaciones}</FormFeedback>
                            ) : null}
                          </Col>
                          <Col>
                            <Label for="descripcion">Descripción</Label>
                            <Input
                              id="descripcion"
                              name="descripcion"
                              type="text"
                              onChange={onChange}
                              value={descripcion}
                              required
                            />
                            {props.error && props.error.descripcion ? (
                              <FormFeedback>{props.error.descripcion}</FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mt-3">
                        <Col>
                            <Label for="cliente">Cliente</Label>
                            <Select
                              closeMenuOnSelect={false}
                              id="cliente"
                              name="cliente"
                              options={Array.isArray(contactList)? contactList.map(item=> ({label: item.get_full_name, value: item.id})):[]}
                              value={formData.cliente ? formData.cliente.map(([label, value]) => ({ label, value })): null}
                              onChange={(selectedOptions) => {
                                // Obtener los datos seleccionados de los contactos
                                const selectedContactData = selectedOptions.map((option) => [option.label, option.value]);
                                // Actualizar formData.contacto con los datos seleccionados
                                setFormData({ ...formData, cliente: selectedContactData });
                              }}                        
                              placeholder="Seleccione"
                              isMulti={true}                    
                              menuPosition="fixed"                   
                              />  
                            {props.error && props.error.contacto ? (
                          <FormFeedback>{props.error.contacto}</FormFeedback>
                            ) : null}
                          </Col>
                          <Col>
                            <Label for="contacto">Agente</Label>
                            <Select
                              closeMenuOnSelect={false}
                              id="contacto"
                              name="contacto"
                              options={Array.isArray(agent)? agent.map(item=> ({label: item.get_full_name, value: item.id})):[]}
                              value={formData.contacto ? formData.contacto.map(([label, value]) => ({ label, value })): null}
                              onChange={(selectedOptions) => {
                                // Obtener los datos seleccionados de los contactos
                                const selectedContactData = selectedOptions.map((option) => [option.label, option.value]);
                                // Actualizar formData.contacto con los datos seleccionados
                                setFormData({ ...formData, contacto: selectedContactData });
                              }}                        
                              placeholder="Seleccione"
                              isMulti={true}                    
                              menuPosition="fixed"                   
                              />  
                            {props.error && props.error.contacto ? (
                          <FormFeedback>{props.error.contacto}</FormFeedback>
                            ) : null}
                          </Col>
                        </Row>
                      </Col>
                      <Row className="justify-content-md-center mt-5 ms-5">
                        <Col lg={4}>
                          <Link className="btn btn-secondary text-dark me-1" to="/casos">
                            Cancelar
                          </Link>

                          <Button color="primary" type="submit">
                            Actualizar
                          </Button>
                        </Col>
                      </Row>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, casosUpdated } = state.Casos;
  return { error, loading, casosUpdated };
};

export default withRouter(connect(mapStateToProps)(AutorizacionesUpdate));
