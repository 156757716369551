import {
  ROOMS_LIST,
  ROOMS_FILTER,
  ROOMS_CREATE,
  ROOMS_CREATE_SUCCESS,
  ROOMS_LIST_SUCCESS,
  ROOMS_REQUEST_FAILED,
  ROOMS_UPDATE,
  ROOMS_UPDATE_SUCCESS,
  ROOMS_FULLLIST,
  ROOMS_FULLLIST_SUCCESS, 
} from "../../store/rooms/actionTypes";

const initialState = {
  rooms: [],
  roomsCreated: {},
  roomsUpdated: {},
  loading: false,
  error: "",
};

const rooms = (state = initialState, action) => {
  switch (action.type) {
    case ROOMS_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ROOMS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

      case ROOMS_FULLLIST:
        state = {
          ...state,
          loading: true,
        };
        break;
    case ROOMS_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ROOMS_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ROOMS_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        roomsCreated: action.payload,
        error: "",
      };
      break;
    case ROOMS_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        rooms: action.payload,
        roomsCreated: {},
        roomsUpdated: {},
        error: "",
      };
      break;

      
      case ROOMS_FULLLIST_SUCCESS:
        state = {
          ...state,
          loading: false,
          rooms: action.payload,
          roomsCreated: {},
          roomsUpdated: {},
          error: "",
        };
        break;
    case ROOMS_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        roomsUpdated: action.payload,
        error: "",
      };
      break;
    case ROOMS_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default rooms;
