// ROOMS requests
export const ROOMS_LIST = "ROOMS_LIST";
export const ROOMS_CREATE = "ROOMS_CREATE";
export const ROOMS_FILTER = "ROOMS_FILTER";
export const ROOMS_UPDATE = "ROOMS_UPDATE";
export const ROOMS_DELETE = "ROOMS_DELETE";
export const ROOMS_READ = "ROOMS_READ";
export const ROOMS_FULLLIST = "ACTIVIDAD_DIARIA_FULLLIST";


// ROOMS success
export const ROOMS_LIST_SUCCESS = "ROOMS_LIST_SUCCESS";
export const ROOMS_FULLLIST_SUCCESS = "ACTIVIDAD_DIARIA_FULLLIST_SUCCESS";

export const ROOMS_CREATE_SUCCESS = "ROOMS_CREATE_SUCCESS";
export const ROOMS_UPDATE_SUCCESS = "ROOMS_UPDATE_SUCCESS";
export const ROOMS_DELETE_SUCCESS = "ROOMS_DELETE_SUCCESS";
export const ROOMS_READ_SUCCESS = "ROOMS_READ_SUCCESS";

// ROOMS failed
export const ROOMS_REQUEST_FAILED = "ROOMS_REQUEST_FAILED";
