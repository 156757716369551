import { call, put, takeEvery } from "redux-saga/effects";

import {
  CONFIRMACION_LIST,
  CONFIRMACION_FILTER,
  CONFIRMACION_CREATE,
  CONFIRMACION_UPDATE,
} from "./actionTypes";

import {
 confirmacionListSuccess,
 confirmacionCreateSuccess,
 confirmacionUpdateSuccess,
 confirmacionRequestFailed,
} from "./actions";

import {
  getConfirmacionList,
  getConfirmacionFilter,
  postConfirmacionCreate,
  patchConfirmacionUpdate,
} from "../../helpers/backend";

function*confirmacionListSaga() {
  console.log("Trayendo lista de confirmacion...");
  try {
    const response = yield call(getConfirmacionList);
    if (response.status === 200) {
      yield put(confirmacionListSuccess(response.data));
    } else {
      yield put(confirmacionRequestFailed(response.data));
    }
  } catch (error) {
    yield put(confirmacionRequestFailed(error.response.data));
  }
}

function*confirmacionCreateSaga({ payload:confirmacion }) {
  console.log("Creando nuevo confirmacion...");
  try {
    const response = yield call(postConfirmacionCreate,confirmacion);
    if (response.status === 201) {
      yield put(confirmacionCreateSuccess(response.data));
    } else {
      yield put(confirmacionRequestFailed(response.data));
    }
  } catch (error) {
    yield put(confirmacionRequestFailed(error.response.data));
  }
}

function*confirmacionFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getConfirmacionFilter, { changeUrl });
    if (response.status === 200) {
      yield put(confirmacionListSuccess(response.data));
    } else {
      yield put(confirmacionRequestFailed(response.data));
    }
  } catch (error) {
    yield put(confirmacionRequestFailed(error.response.data));
  }
}

function*confirmacionUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del confirmacion...");
  try {
    const response = yield call(patchConfirmacionUpdate, { id }, data);
    if (response.status === 200) {
      yield put(confirmacionUpdateSuccess(response.data));
    } else {
      yield put(confirmacionRequestFailed(response.data));
    }
  } catch (error) {
    yield put(confirmacionRequestFailed(error.response.data));
  }
}

export default function*confirmacionSaga() {
  yield takeEvery(CONFIRMACION_LIST,confirmacionListSaga);
  yield takeEvery(CONFIRMACION_FILTER,confirmacionFilterSaga);
  yield takeEvery(CONFIRMACION_CREATE,confirmacionCreateSaga);
  yield takeEvery(CONFIRMACION_UPDATE,confirmacionUpdateSaga);
}
