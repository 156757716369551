import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Form,
  Button,
  CardTitle,
  Input,
  
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../components/Common/Loader";

import { autorizacionCreate, autorizacionList } from "../../store/actions";
import {
  
  contactshcFulllist,
} from "../../store/contactshc/actions";

import departamentos from "./Departamentos";

import ContactoAddModal from "../Contacts/components/modals/ContactAddModal";
const AutorizacionesCreate = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [f_correo, setFechaCorreo] = useState(null);
  const [f_vigencia, setFechaVigencia] = useState(null);
  const [f_emision, setFechaEmision] = useState(null);

  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [selectedCiudad, setSelectedCiudad] = useState("");
  const [selectedContacto, setSelelectedContacto] = useState(null);
  const [crearModal, setCrearModal] = useState(false);
  const [error, setError] = useState([]);	
  const [errorPrescripcion, setErrorPrescripcion] = useState([]);	

 
 
  useEffect(() => {
    dispatch(contactshcFulllist())
   
  }, [dispatch]);

  const contacts = useSelector((state) => state.Contactshc.contactshc);


console.log(contacts)
  

  const [formData, setFormData] = useState({
    tipo_traslado: "",
    observaciones: "",
    descripcion: "",

   
    direccion_1: "",
    direccion_2: "",
    images: "",
    numero_entrega: "",
    code_service: "T34001",
    f_correo: "",
    pais: "",
    area_envio: "",
    prescripcion: "",
    autorizacion:"",
    id_ciclo: "",
    cantidad: "",
    limite: "",

  });

  const { tipo_traslado,  numero_entrega, code_service, pais, area_envio , prescripcion, autorizacion, id_ciclo, images, cantidad, limite} =
    formData;

    const onChange = (e) => {
      const { name, value } = e.target;
  
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };

  const handleFotosChange = (fotos) => {
    const image = [];
  
    Array.from(fotos).forEach((foto) => {
      var reader = new FileReader();
      reader.readAsDataURL(foto);
      reader.onload = function () {
        var fotoBase64 = [];
        var base64 = reader.result;
        fotoBase64 = base64.split(",");
        image.push({ image: fotoBase64[1] });
  
        if (image.length === fotos.length) {
          setFormData({
            ...formData,
            images: image
          });
        }
      };
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      autorizacionCreate({
        numero_entrega,
        code_service,
        tipo_traslado,
        f_correo,
        f_vigencia,
        pais: "Colombia", 
        departamento: selectedDepartamento,
        ciudad: selectedCiudad, 
        area_envio,
        prescripcion,
        autorizacion,
        id_ciclo,
        contacto: selectedContacto,
        vauche_img:images[0]?.image,
        f_emision,
        cantidad,
        limite,
       
        // Pasa "Colombia" como valor de la propiedad 'pais'
      })
    );
  };

  const redirect = () => {
    dispatch(autorizacionList());
    history.push("/autorizaciones");
  };

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: 300,
    }),
  };

  const onChangeFecha = (e) => {
    setFechaCorreo(e.target.value);
  };
  const onChangeFechaVigencia = (e) => {
    setFechaVigencia(e.target.value);
  };
  const onChangeFechaEmision = (e) => {
    setFechaEmision(e.target.value);
  };

 
  const handleDepartamentoChange = (e) => {
    setSelectedDepartamento(e.target.value);
    setSelectedCiudad(""); // Resetea la ciudad seleccionada cuando cambias el departamento
  };

  const handleCiudadChange = (e) => {
    setSelectedCiudad(e.target.value);
  };

  function handleSelectContacto(selectedContacto) {
    setSelelectedContacto(selectedContacto.value);
  }

  // Filtra las ciudades según el departamento seleccionado
  const ciudadesDisponibles =
    departamentos.find((d) => d.name === selectedDepartamento)?.cities || [];

   

    const handleCasoClick = (selected) => {
      setCrearModal(true);
    };
    const handleCloseModal = () => {
      setCrearModal(false);
    };

    
  return (
    <React.Fragment>
       {crearModal ? (
        <ContactoAddModal
          style={{ overflow: "auto", zIndex: "100" }}
          crearModal={crearModal}
          onCloseClick={handleCloseModal}
        ></ContactoAddModal>
      ) : null}
      <div className="page-content">
        <MetaTags>
          <title>TrueContact | Autorizaciones</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem="Autorizaciones" />
          {props.loading ? (
            <Loader />
          ) : (
            <div className="d-flex justify-content-center">
              {props.autorizacionCreated.id ? (
                <SweetAlert
                  title="¡Hecho!"
                  success
                  timeout={2000}
                  showConfirm={false}
                  onConfirm={() => {
                    redirect();
                  }}
                >
                  Se ha agregado <b>{props.autorizacionCreated.nombre}</b>{" "}
                  correctamente.
                </SweetAlert>
              ) : null}

              <Card
                style={{
                  width: "60rem",
                }}
              >
                <CardTitle className="ps-3 pt-3">
                  <h2 className="font-size-20">Autorizaciones</h2>
                </CardTitle>
                <CardBody>
                  <Form onSubmit={onSubmit} className="form-control">
                    <Row className="justify-content-center">
                      <Col lg={10}>
                        <Row className="mt-3">
                          <Col>
                            <Label for="autorizacion">Autorizacion</Label>
                            <Input
                              id="autorizacion"
                              name="autorizacion"
                              type="number"
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value.length <= 15) {
                                  onChange(e); // Tu función onChange para manejar el estado principal
                                }
                                if (value.length <=15) {
                                  setError("La autorización debe tener exactamente 15 caracteres.");
                                } else {
                                  setError("");
                                }
                              }}
                              maxLength={15}
                              value={autorizacion}
                              required

                             
                            />
                          {(props.error && props.error.autorizacion) || error ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger`}>
                  {props.error.autorizacion || error}
                </p>
              ) : null}
                          </Col>

                          <Col>
                          <Label for="fotos">Fotos</Label>
                              <Input
                              type="file"
                              className="form-control"
                              name="fotos"
                              id="fotos"
                           
                              onChange={(e)=> handleFotosChange(e.target.files)}
                              />
  {props.error && props.error.vauche_img ? (
                            <p className={`mb-4 mx-5 font-size-13 text-danger `} > {props.error.vauche_img}</p>
                            ) : null}
                           
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col>
                            <Label for="contacto">Usuario </Label>
                            <div className="d-flex">
                              <Select
                                id="contacto"
                                name="contacto"
                                styles={customStyles}
                                options={
                                  Array.isArray(contacts)
                                    ? contacts.map((item) => ({
                                        label: `${item.full_name}  ${item.document}`,
                                        value: item.id,
                                      }))
                                    : []
                                }
                                onChange={handleSelectContacto}
                                 
                                placeholder={"Seleccione"}
                                
                                menuPosition="fixed"
                              />
                              
                              <Button
                                color="primary"
                                type="button"
                                style={{ marginLeft: "15px" }}
                                onClick={() => handleCasoClick()}
                                // onClick={toggleNewContactFields}
                              >
                                +
                              </Button>
                            </div>
                            {!formData.contacto && !formData.contacto ? (
                               <p className={`mb-4 mx-5 font-size-13 text-danger `} > {props.error.contacto}</p>
                              ) : null}
                          </Col>

                          <Col>
                            <Label for="prescripcion">Prescripcion</Label>
                            <Input
                          id="prescripcion"
                          name="prescripcion"
                          type="text"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.length <= 20) {
                              onChange(e); // Tu función onChange para manejar el estado principal
                            }
                            if (value.length <=20) {
                              setErrorPrescripcion("La autorización debe tener exactamente 15 caracteres.");
                            } else {
                              setErrorPrescripcion("");
                            }
                          }}
                          value={prescripcion}
                          required
                        />
              
                            {(props.error && props.error.prescripcion) || errorPrescripcion ? (
                            <p className={`mb-4 mx-5 font-size-13 text-danger `} > {props.error.prescripcion || errorPrescripcion}</p>
                            ) : null}
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col>
                            <Label for="code_service">Codigo de Servicio</Label>
                            <Input
                              id="code_service"
                              name="code_service"
                              type="text"
                              onChange={onChange}
                              value={code_service}
                              required
                              disabled
                            />
                            {props.error && props.error.code_service ? (
                               <p className={`mb-4 mx-5 font-size-13 text-danger `} > {props.error.code_service}</p>
                            ) : null}
                          </Col>

                          <Col>
                            <Label for="pais">Pais</Label>
                            <Input
                              id="pais"
                              name="pais"
                              type="text"
                              onChange={onChange}
                              value={pais}
                              placeholder="Colombia"
                              disabled
                              required
                            />
                            {props.error && props.error.pais ? (
                        <p className={`mb-4 mx-5 font-size-13 text-danger `} > {props.error.pais}</p>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Label for="descripcion">Departamento</Label>
                            <select
                              value={selectedDepartamento}
                              onChange={handleDepartamentoChange}
                              className="form-select"
                            >
                              <option value="">
                                Selecciona un departamento
                              </option>
                              {departamentos.map((departamento) => (
                                <option
                                  key={departamento.name}
                                  value={departamento.name}
                                >
                                  {departamento.name}
                                </option>
                              ))}
                               {props.error && props.error.departamento ? (
                              <p className={`mb-4 mx-5 font-size-13 text-danger `} > {props.error.departamento}</p>
                            ) : null}
                            </select>
                          </Col>

                          <Col>
                            <Label for="descripcion">Ciudad</Label>
                            <select
                              value={selectedCiudad}
                              onChange={handleCiudadChange}
                              disabled={!selectedDepartamento}
                              className="form-select"
                            >
                              <option value="">Selecciona una ciudad</option>
                              {ciudadesDisponibles.map((ciudad) => (
                                <option key={ciudad} value={ciudad}>
                                  {ciudad}
                                </option>
                              ))}
                            </select>
                            {props.error && props.error.ciudad ? (
                              <p className={`mb-4 mx-5 font-size-13 text-danger `} > {props.error.ciudad}</p>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <Label for="area_envio">Area de Envio</Label>
                            <Input
                              id="area_envio"
                              name="area_envio"
                              type="text"
                              onChange={onChange}
                              value={area_envio}
                              required
                            />
                            {props.error && props.error.area_envio ? (
                             <p className={`mb-4 mx-5 font-size-13 text-danger `} > {props.error.area_envio}</p>
                            ) : null}
                          </Col>

                          <Col>
                            <Label
                              for="f_correo"
                              className="col-form-label col-sm-8 d-flex"
                            >
                              Fecha del Correo
                            </Label>

                            <input
                              className="form-control d-block"
                              placeholder="Fecha"
                              onChange={(e) => onChangeFecha(e)}
                              type="date"
                            />
                              {props.error && props.error.id_ciclo ? (
                              <p className={`mb-4 mx-5 font-size-13 text-danger `} > {props.error.f_correo}</p>
                            ) : null}


                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col>
                            <Label
                              for="tecnico_asignado"
                              className="col-form-label col-sm-8 d-flex"
                            >
                              Fecha de Emision Aut
                            </Label>

                            <input
                              className="form-control d-block"
                              placeholder="Fecha"
                              onChange={(e) => onChangeFechaEmision(e)}
                              type="date"
                            />
                             
                          </Col>

                          <Col>
                            <Label
                              for="tecnico_asignado"
                              className="col-form-label col-sm-8 d-flex"
                            >
                              Fecha de Vigencia Aut
                            </Label>

                            <input
                              className="form-control d-block"
                              placeholder="Fecha"
                              onChange={(e) => onChangeFechaVigencia(e)}
                              type="date"
                            />
                              {props.error && props.error.id_ciclo ? (
                              <p className={`mb-4 mx-5 font-size-13 text-danger `} > {props.error.f_vigencia}</p>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg={6}>
                            <Label for="id_ciclo">Id de Ciclo</Label>
                            <Input
                              id="id_ciclo"
                              name="id_ciclo"
                              type="number"
                        
                              onChange={(e) => {
                                if (e.target.value.length <= 8) {
                                  onChange(e);
                                }
                              }}
                              value={id_ciclo}
                              required
                            />
                            {props.error && props.error.id_ciclo ? (
                              <p className={`mb-4 mx-5 font-size-13 text-danger `} > {props.error.id_ciclo}</p>
                            ) : null}
                          </Col>
                          <Col lg={6}>
                            <Label for="limite">
                            Cantidad
                            </Label>
                            <Input
                              id="limite"
                              name="limite"
                              type="number"
                              onChange={(e) => {
                                if (e.target.value.length <= 2) {
                                  onChange(e);
                                }
                              }}
                              value={limite}
                              required
                            />
                                {props.error && props.error.limite ? (
                              <p className={`mb-4 mx-5 font-size-13 text-danger `} > {props.error.limite}</p>
                            ) : null}
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col lg={6}>
                            <Label for="id_ciclo">  Nro. de Entrega </Label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
    <Input
      id="cantidad"
      name="cantidad"
      type="number"
      onChange={(e) => {
        if (e.target.value.length <= 2) {
          onChange(e);
        }
      }}
      value={cantidad}
      required
      style={{ width: '45%', marginRight: '5px' }}
    />
    <span>/</span>
    <Input
      id="numero_entrega"
      name="numero_entrega"
      type="number"
      onChange={(e) => {
        if (e.target.value.length <= 2) {
          onChange(e);
        }
      }}
      value={numero_entrega}
      required
      style={{ width: '45%', marginLeft: '5px' }}
    />
  </div>
           </Col>
                      </Row>                            

                        <Row className="mt-3"></Row>
                      </Col>
                    </Row>
                    <Row className="col-lg-12 justify-content-center mt-4">
                      <Col md={1} className="mx-1">
                        <Link
                          className="btn btn-secondary text-dark"
                          to="/autorizaciones"
                        >
                          Volver
                        </Link>
                      </Col>
                      <Col md={1} className="mx-1">
                        <Button color="primary" type="submit">
                          Enviar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, autorizacionCreated } = state.Autorizacion;
  return { error, loading, autorizacionCreated };
};

export default withRouter(connect(mapStateToProps)(AutorizacionesCreate));
