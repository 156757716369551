import {
  COORDINACION_LIST,
  COORDINACION_FILTER,
  COORDINACION_CREATE,
  COORDINACION_CREATE_SUCCESS,
  COORDINACION_LIST_SUCCESS,
  COORDINACION_REQUEST_FAILED,
  COORDINACION_UPDATE,
  COORDINACION_UPDATE_SUCCESS,
} from "../../store/coordinacion/actionTypes";

const initialState = {
  coordinacion: [],
  coordinacionCreated: {},
  coordinacionUpdated: {},
  loading: false,
  error: "",
};

const coordinacion = (state = initialState, action) => {
  switch (action.type) {
    case COORDINACION_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case COORDINACION_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case COORDINACION_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case COORDINACION_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case COORDINACION_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        coordinacionCreated: action.payload,
        error: "",
      };
      break;
    case COORDINACION_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        coordinacion: action.payload,
        coordinacionCreated: {},
        coordinacionUpdated: {},
        error: "",
      };
      break;
    case COORDINACION_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        coordinacionUpdated: action.payload,
        error: "",
      };
      break;
    case COORDINACION_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default coordinacion;
