import { call, put, takeEvery } from "redux-saga/effects";

import {
  ROOMS_LIST,
  ROOMS_FILTER,
  ROOMS_CREATE,
  ROOMS_UPDATE,
  ROOMS_FULLLIST,
} from "./actionTypes";

import {
 roomsListSuccess,
 roomsCreateSuccess,
 roomsUpdateSuccess,
 roomsRequestFailed,
 roomsFullListSuccess,
} from "./actions";

import {
  getRoomList,
  getRoomFilter,
  postRoomCreate,
  patchRoomUpdate,
  getRoomFullList,  
} from "../../helpers/backend";

function*roomsListSaga() {
  console.log("Trayendo lista de rooms...");
  try {
    const response = yield call(getRoomList);
    if (response.status === 200) {
      yield put(roomsListSuccess(response.data));
    } else {
      yield put(roomsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(roomsRequestFailed(error.response.data));
  }
}

function*roomsFullListSaga() {
  console.log("Trayendo lista de rooms de FullList...");
  try {
    const response = yield call(getRoomFullList);
    if (response.status === 200) {
      yield put(roomsFullListSuccess(response.data));
  
    } else {
      yield put(roomsRequestFailed(response.data));
   
    }
  } catch (error) {
    yield put(roomsRequestFailed(error.response.data));
  }
}


function*roomsCreateSaga({ payload:rooms }) {
  console.log("Creando nuevo rooms...");
  try {
    const response = yield call(postRoomCreate,rooms);
    if (response.status === 201) {
      yield put(roomsCreateSuccess(response.data));
    } else {
      yield put(roomsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(roomsRequestFailed(error.response.data));
  }
}

function*roomsFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getRoomFilter, { changeUrl });
    if (response.status === 200) {
      yield put(roomsListSuccess(response.data));
    } else {
      yield put(roomsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(roomsRequestFailed(error.response.data));
  }
}

function*roomsUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del rooms...");
  try {
    const response = yield call(patchRoomUpdate, { id }, data);
    if (response.status === 206) {
      yield put(roomsUpdateSuccess(response.data));
    } else {
      yield put(roomsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(roomsRequestFailed(error.response.data));
  }
}

export default function*roomsSaga() {
  yield takeEvery(ROOMS_LIST,roomsListSaga);
  yield takeEvery(ROOMS_FILTER,roomsFilterSaga);
  yield takeEvery(ROOMS_CREATE,roomsCreateSaga);
  yield takeEvery(ROOMS_UPDATE,roomsUpdateSaga);
  yield takeEvery(ROOMS_FULLLIST,roomsFullListSaga);
}
