import { call, put, takeEvery } from "redux-saga/effects";

import {
  RUTERO_LIST,
  RUTERO_FILTER,
  RUTERO_CREATE,
  RUTERO_UPDATE,
} from "./actionTypes";

import {
 ruteroListSuccess,
 ruteroCreateSuccess,
 ruteroUpdateSuccess,
 ruteroRequestFailed,
} from "./actions";

import {
  getRuteroList,
  getRuteroFilter,
  postRuteroCreate,
  patchRuteroUpdate,
} from "../../helpers/backend";

function*ruteroListSaga() {
  console.log("Trayendo lista de rutero...");
  try {
    const response = yield call(getRuteroList);
    if (response.status === 200) {
      yield put(ruteroListSuccess(response.data));
    } else {
      yield put(ruteroRequestFailed(response.data));
    }
  } catch (error) {
    yield put(ruteroRequestFailed(error.response.data));
  }
}

function*ruteroCreateSaga({ payload:rutero }) {
  console.log("Creando nuevo rutero...");
  try {
    const response = yield call(postRuteroCreate,rutero);
    if (response.status === 201) {
      yield put(ruteroCreateSuccess(response.data));
    } else {
      yield put(ruteroRequestFailed(response.data));
    }
  } catch (error) {
    yield put(ruteroRequestFailed(error.response.data));
  }
}

function*ruteroFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getRuteroFilter, { changeUrl });
    if (response.status === 200) {
      yield put(ruteroListSuccess(response.data));
    } else {
      yield put(ruteroRequestFailed(response.data));
    }
  } catch (error) {
    yield put(ruteroRequestFailed(error.response.data));
  }
}

function*ruteroUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del rutero...");
  try {
    const response = yield call(patchRuteroUpdate, { id }, data);
    if (response.status === 200) {
      yield put(ruteroUpdateSuccess(response.data));
    } else {
      yield put(ruteroRequestFailed(response.data));
    }
  } catch (error) {
    yield put(ruteroRequestFailed(error.response.data));
  }
}

export default function*ruteroSaga() {
  yield takeEvery(RUTERO_LIST,ruteroListSaga);
  yield takeEvery(RUTERO_FILTER,ruteroFilterSaga);
  yield takeEvery(RUTERO_CREATE,ruteroCreateSaga);
  yield takeEvery(RUTERO_UPDATE,ruteroUpdateSaga);
}
