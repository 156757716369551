import { call, put, takeEvery } from "redux-saga/effects";

import {
  FACTURA_LIST,
  FACTURA_FILTER,
  FACTURA_CREATE,
  FACTURA_UPDATE,
} from "./actionTypes";

import {
 facturaListSuccess,
 facturaCreateSuccess,
 facturaUpdateSuccess,
 facturaRequestFailed,
} from "./actions";

import {
  getFacturaList,
  getFacturaFilter,
  postFacturaCreate,
  patchFacturaUpdate,
} from "../../helpers/backend";

function*facturaListSaga() {
  console.log("Trayendo lista de factura...");
  try {
    const response = yield call(getFacturaList);
    if (response.status === 200) {
      yield put(facturaListSuccess(response.data));
    } else {
      yield put(facturaRequestFailed(response.data));
    }
  } catch (error) {
    yield put(facturaRequestFailed(error.response.data));
  }
}

function*facturaCreateSaga({ payload:factura }) {
  console.log("Creando nuevo factura...");
  try {
    const response = yield call(postFacturaCreate,factura);
    if (response.status === 201) {
      yield put(facturaCreateSuccess(response.data));
    } else {
      yield put(facturaRequestFailed(response.data));
    }
  } catch (error) {
    yield put(facturaRequestFailed(error.response.data));
  }
}

function*facturaFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getFacturaFilter, { changeUrl });
    if (response.status === 200) {
      yield put(facturaListSuccess(response.data));
    } else {
      yield put(facturaRequestFailed(response.data));
    }
  } catch (error) {
    yield put(facturaRequestFailed(error.response.data));
  }
}

function*facturaUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del factura...");
  try {
    const response = yield call(patchFacturaUpdate, { id }, data);
    if (response.status === 200) {
      yield put(facturaUpdateSuccess(response.data));
    } else {
      yield put(facturaRequestFailed(response.data));
    }
  } catch (error) {
    yield put(facturaRequestFailed(error.response.data));
  }
}

export default function*facturaSaga() {
  yield takeEvery(FACTURA_LIST,facturaListSaga);
  yield takeEvery(FACTURA_FILTER,facturaFilterSaga);
  yield takeEvery(FACTURA_CREATE,facturaCreateSaga);
  yield takeEvery(FACTURA_UPDATE,facturaUpdateSaga);
}
