import { call, put, takeEvery } from "redux-saga/effects";

import {
  PISOS_LIST,
  PISOS_FILTER,
  PISOS_CREATE,
  PISOS_UPDATE,
  PISOS_FULLLIST,
} from "./actionTypes";

import {
 pisosListSuccess,
 pisosCreateSuccess,
 pisosUpdateSuccess,
 pisosRequestFailed,
 pisosFullListSuccess,
} from "./actions";

import {
  getPisoList,
  getPisoFilter,
  postPisoCreate,
  patchPisoUpdate,
  getPisoFullList,  
} from "../../helpers/backend";

function*pisosListSaga() {
  console.log("Trayendo lista de pisos...");
  try {
    const response = yield call(getPisoList);
    if (response.status === 200) {
      yield put(pisosListSuccess(response.data));
    } else {
      yield put(pisosRequestFailed(response.data));
    }
  } catch (error) {
    yield put(pisosRequestFailed(error.response.data));
  }
}

function*pisosFullListSaga() {
  console.log("Trayendo lista de pisos de FullList...");
  try {
    const response = yield call(getPisoFullList);
    if (response.status === 200) {
      yield put(pisosFullListSuccess(response.data));
  
    } else {
      yield put(pisosRequestFailed(response.data));
   
    }
  } catch (error) {
    yield put(pisosRequestFailed(error.response.data));
  }
}


function*pisosCreateSaga({ payload:pisos }) {
  console.log("Creando nuevo pisos...");
  try {
    const response = yield call(postPisoCreate,pisos);
    if (response.status === 201) {
      yield put(pisosCreateSuccess(response.data));
    } else {
      yield put(pisosRequestFailed(response.data));
    }
  } catch (error) {
    yield put(pisosRequestFailed(error.response.data));
  }
}

function*pisosFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getPisoFilter, { changeUrl });
    if (response.status === 200) {
      yield put(pisosListSuccess(response.data));
    } else {
      yield put(pisosRequestFailed(response.data));
    }
  } catch (error) {
    yield put(pisosRequestFailed(error.response.data));
  }
}

function*pisosUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del pisos...");
  try {
    const response = yield call(patchPisoUpdate, { id }, data);
    if (response.status === 200) {
      yield put(pisosUpdateSuccess(response.data));
    } else {
      yield put(pisosRequestFailed(response.data));
    }
  } catch (error) {
    yield put(pisosRequestFailed(error.response.data));
  }
}

export default function*pisosSaga() {
  yield takeEvery(PISOS_LIST,pisosListSaga);
  yield takeEvery(PISOS_FILTER,pisosFilterSaga);
  yield takeEvery(PISOS_CREATE,pisosCreateSaga);
  yield takeEvery(PISOS_UPDATE,pisosUpdateSaga);
  yield takeEvery(PISOS_FULLLIST,pisosFullListSaga);
}
