import { call, put, takeEvery } from "redux-saga/effects";

import {
  CONTACTSHC_LIST,
  CONTACTSHC_FILTER,
  CONTACTSHC_CREATE,
  CONTACTSHC_UPDATE,
  CONTACTSHC_FULLLIST,
} from "./actionTypes";

import {
 contactshcListSuccess,
 contactshcCreateSuccess,
 contactshcUpdateSuccess,
 contactshcRequestFailed,
 contactshcFuLLListSuccess,
} from "./actions";

import {
  getContactshcList,
  getContactshcFilter,
  postContactshcCreate,
  patchContactshcUpdate,
  getContactshcFullList,
} from "../../helpers/backend";

function*contactshcListSaga() {
  console.log("Trayendo lista de contactshc...");
  try {
    const response = yield call(getContactshcList);
    if (response.status === 200) {
      yield put(contactshcListSuccess(response.data));
    } else {
      yield put(contactshcRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactshcRequestFailed(error.response.data));
  }
}

function*contactsFuLllistSaga() {
  console.log("Trayendo lista de FullList...");
  try {
    const response = yield call(getContactshcFullList);
    if (response.status === 200) {
      yield put(contactshcFuLLListSuccess(response.data));
     
    } else {
      yield put(contactshcRequestFailed(response.data));
      console.log(response.data)
    }
  } catch (error) {
    yield put(contactshcRequestFailed(error.response.data));
  }
}
function*contactshcCreateSaga({ payload:contactshc }) {
  console.log("Creando nuevo contactshc...");
  try {
    const response = yield call(postContactshcCreate,contactshc);
    if (response.status === 201) {
      yield put(contactshcCreateSuccess(response.data));
    } else {
      yield put(contactshcRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactshcRequestFailed(error.response.data));
  }
}

function*contactshcFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getContactshcFilter, { changeUrl });
    if (response.status === 200) {
      yield put(contactshcListSuccess(response.data));
    } else {
      yield put(contactshcRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactshcRequestFailed(error.response.data));
  }
}

function*contactshcUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del contactshc...");
  try {
    const response = yield call(patchContactshcUpdate, { id }, data);
    if (response.status === 200) {
      yield put(contactshcUpdateSuccess(response.data));
    } else {
      yield put(contactshcRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactshcRequestFailed(error.response.data));
  }
}

export default function*contactshcSaga() {
  yield takeEvery(CONTACTSHC_LIST,contactshcListSaga);
  yield takeEvery(CONTACTSHC_FILTER,contactshcFilterSaga);
  yield takeEvery(CONTACTSHC_CREATE,contactshcCreateSaga);
  yield takeEvery(CONTACTSHC_UPDATE,contactshcUpdateSaga);
  yield takeEvery(CONTACTSHC_FULLLIST,contactsFuLllistSaga);
}
