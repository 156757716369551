import {
  PISOS_LIST,
  PISOS_FILTER,
  PISOS_CREATE,
  PISOS_CREATE_SUCCESS,
  PISOS_LIST_SUCCESS,
  PISOS_REQUEST_FAILED,
  PISOS_UPDATE,
  PISOS_UPDATE_SUCCESS,
  PISOS_FULLLIST,
  PISOS_FULLLIST_SUCCESS, 
} from "../../store/pisos/actionTypes";

const initialState = {
  pisos: [],
  pisosCreated: {},
  pisosUpdated: {},
  loading: false,
  error: "",
};

const pisos = (state = initialState, action) => {
  switch (action.type) {
    case PISOS_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PISOS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

      case PISOS_FULLLIST:
        state = {
          ...state,
          loading: true,
        };
        break;
    case PISOS_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PISOS_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case PISOS_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        pisosCreated: action.payload,
        error: "",
      };
      break;
    case PISOS_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        pisos: action.payload,
        pisosCreated: {},
        pisosUpdated: {},
        error: "",
      };
      break;

      
      case PISOS_FULLLIST_SUCCESS:
        state = {
          ...state,
          loading: false,
          pisos: action.payload,
          pisosCreated: {},
          pisosUpdated: {},
          error: "",
        };
        break;
    case PISOS_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        pisosUpdated: action.payload,
        error: "",
      };
      break;
    case PISOS_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default pisos;
