import {
  SEGUIMIENTO_LIST,
  SEGUIMIENTO_FILTER,
  SEGUIMIENTO_CREATE,
  SEGUIMIENTO_UPDATE,
  SEGUIMIENTO_UPDATE_SUCCESS,
  SEGUIMIENTO_CREATE_SUCCESS,
  SEGUIMIENTO_LIST_SUCCESS,
  SEGUIMIENTO_REQUEST_FAILED,
} from "./actionTypes";

export const seguimientoList = () => {
  return {
    type: SEGUIMIENTO_LIST,
    payload: {},
  };
};

export const seguimientoCreate = (seguimiento) => {
  return {
    type: SEGUIMIENTO_CREATE,
    payload: seguimiento,
  };
};

export const seguimientoFilter = (changeUrl) => {
  return {
    type: SEGUIMIENTO_FILTER,
    payload: { changeUrl },
  };
};

export const seguimientoUpdate = (id, data) => {
  return {
    type: SEGUIMIENTO_UPDATE,
    payload: { id, data },
  };
};

export const seguimientoListSuccess = (seguimiento) => {
  return {
    type: SEGUIMIENTO_LIST_SUCCESS,
    payload: seguimiento,
  };
};

export const seguimientoCreateSuccess = (seguimientoCreated) => {
  return {
    type: SEGUIMIENTO_CREATE_SUCCESS,
    payload: seguimientoCreated,
  };
};

export const seguimientoUpdateSuccess = (seguimiento) => {
  return {
    type: SEGUIMIENTO_UPDATE_SUCCESS,
    payload: seguimiento,
  };
};

export const seguimientoRequestFailed = (error) => {
  return {
    type: SEGUIMIENTO_REQUEST_FAILED,
    payload: error,
  };
};
