// CONFIRMACION requests
export const CONFIRMACION_LIST = "CONFIRMACION_LIST";
export const CONFIRMACION_CREATE = "CONFIRMACION_CREATE";
export const CONFIRMACION_FILTER = "CONFIRMACION_FILTER";
export const CONFIRMACION_UPDATE = "CONFIRMACION_UPDATE";
export const CONFIRMACION_DELETE = "CONFIRMACION_DELETE";
export const CONFIRMACION_READ = "CONFIRMACION_READ";

// CONFIRMACION success
export const CONFIRMACION_LIST_SUCCESS = "CONFIRMACION_LIST_SUCCESS";
export const CONFIRMACION_CREATE_SUCCESS = "CONFIRMACION_CREATE_SUCCESS";
export const CONFIRMACION_UPDATE_SUCCESS = "CONFIRMACION_UPDATE_SUCCESS";
export const CONFIRMACION_DELETE_SUCCESS = "CONFIRMACION_DELETE_SUCCESS";
export const CONFIRMACION_READ_SUCCESS = "CONFIRMACION_READ_SUCCESS";

// CONFIRMACION failed
export const CONFIRMACION_REQUEST_FAILED = "CONFIRMACION_REQUEST_FAILED";
