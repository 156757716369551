import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { 
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Form,
  Input,
  Button,
  InputGroup,
  Dropdown,
  DropdownToggle,
 

 } from "reactstrap"

 import { IoEllipsisVertical } from 'react-icons/io5';


import { connect, useDispatch } from "react-redux";
import { withRouter, Link, useHistory} from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Loader from "../../components/Common/Loader"
import Pagination from "../../components/Common/Pagination_ansync"
import styles from "../Contacts/Contacts.module.scss"
import { getAgent, getAutorizacionEstados, getAutorizacionEstadosFilter, getAutorizacionFilter, getAutorizacionList, postCasoUpdateContacto, postAutorizacionStatusChange } from "../../helpers/backend";
import {  casosUpdate } from "../../store/actions";

const Autorizaciones = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const URL = process.env.REACT_APP_API_URL;

  const exportar = () => {
    window.open(
      `${URL}/casos/reportes/excel/`
    );
  };
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [botonActivo, setBotonActivo] = useState("Todas");
  const [params, setParams] = useState({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const [fechaInicio, setFechaInicio] = useState('');

  // Función para manejar el cambio en el input de fecha
  const handleFechaChange = async (e) => {
    e.preventDefault(); // Previene comportamiento predeterminado
  
    const nuevaFecha = e.target.value; // Obtén el valor directamente del input
    setFechaInicio(nuevaFecha); // Actualiza el estado de la fecha
  
    setLoading(true); // Activa el indicador de carga
  
    try {
      // Construye la URL usando la fecha recién obtenida
      const url = `?fecha=${nuevaFecha}`;
  
      // Realiza la llamada al backend
      const response = await getAutorizacionFilter(url);
  
      // Actualiza los parámetros con la respuesta del backend
      setParams({
        count: response.data.count,
        next: response.data.next,
        previous: response.data.previous,
        results: response.data.results,
      });
    } catch (error) {
      console.error('Error al filtrar por fecha:', error);
    } finally {
      setLoading(false); // Desactiva el indicador de carga
    }
  };

  const toggleDropdown = (idx) => {
    setDropdownOpen(!dropdownOpen);
    setActiveIndex(idx);
  };
  const  [agent, setAgent] = useState ({});
  const getAgentData = async () => {
    const response = await getAgent();
    const data = response.data;
    setAgent(data)
  } 
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [upPhoto, setUpPhoto] = useState(false);
  const [agentAssign, setAgentAssign] = useState(false);
  const [contacto, setContacto] = useState([]);
  const [images, setImages] = useState([]);
  const [actionAlert, setActionAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = useState(false);
  const [action, setAction] = useState("");
  const [ticket, setTicket] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [coordenadas, setCoordenadas] = useState("");
  const [ticketId, setTicketId] = useState(null);


  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
      let url;
      
          url = `?cedula=${search}`;
      
        const response = await getAutorizacionFilter (url);
        setParams({
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          results: response.data.results,
        });
    setLoading(false);
  };

  const resetSearch = async () => {
    setLoading(true); // Activa el indicador de carga
  
    try {
      // Construye la URL base para el reinicio
  
      const response = await getAutorizacionList();
  
      // Resetea los parámetros con la respuesta del backend
      setParams({
        count: response.data.count,
        next: response.data.next,
        previous: response.data.previous,
        results: response.data.results,
      });
  
      // Resetea los estados asociados a los filtros
      setSearch(""); // Limpia la búsqueda de texto
      setFechaInicio(""); // Limpia el valor del campo de fecha
    } catch (error) {
      console.error("Error al resetear la búsqueda:", error);
    } finally {
      setLoading(false); // Desactiva el indicador de carga
    }
  };
  const getAutorizacionData = async () => {
    setLoading(true);
    const response = await getAutorizacionList();
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };
 
  const getFilterData = async (url) => {
    setLoading(true);
    const response = await getAutorizacionFilter(url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };
  const getFilterAutorizacionData = async (url) => {
    setLoading(true);
    const response = await getAutorizacionEstadosFilter(botonActivo, url);
    setParams({
      count: response.data.count,
      next: response.data.next,
      previous: response.data.previous,
      results: response.data.results,
    });
    setLoading(false);
  };







  useEffect(() => {
    getAutorizacionData()
    getAgentData() 

  }, [])


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Autorizaciones </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="TrueContact" breadcrumbItem=" Autorizaciones" />

          
         
         
          
          {loading ? (
            <Loader />
          ) : (
            <div>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={7}>
                      <Link
                        className="btn btn-primary waves-effect waves-light mb-1"
                        to="/autorizaciones/create"
                      >
                        Agregar
                      </Link>

                      <button
                  type="button"
                  className="btn waves-effect waves-light mb-1"
                  style={{ background: "#78c2ad" , marginLeft:'10px', color:'white' }}
                  onClick={exportar}
                >
                  Exportar Reporte
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-excel-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM5.884 6.68 8 9.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 10l2.233 2.68a.5.5 0 0 1-.768.64L8 10.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 10 5.116 7.32a.5.5 0 1 1 .768-.64z" />
                  </svg>
                </button>{" "}
                    </Col>
                    <Col md={5}>
                      <Form className="search-box" onSubmit={onSubmit}>
                        <InputGroup>
                        {/* <i className="bx bx-search-alt search-icon" /> */}
                          <Input
        type="date"
        className="form-control me-1"
        name="fechaInicio"
        placeholder="Fecha Inicio"
        value={fechaInicio} // Vinculamos el estado al valor del input
        onChange={handleFechaChange} // Actualizamos el estado cuando cambia la fecha
      />
                          
                          <Input
                            type="text"
                            className="form-control"
                            name="search"
                            placeholder="Cedula..."
                            value={search}
                            onChange={onChange}
                          />
                          {/* <i className="bx bx-search-alt search-icon" /> */}
                          <Button color="primary" onClick={resetSearch}>
                            <span className=" bx bx-x fa-lg align-middle"></span>
                          </Button>
                        </InputGroup>
                      </Form>
                    </Col>
                  </Row>
                </CardBody>
              </Card> 
                     
              <div className={`text-center ${styles['card-container']}`}>                
          {params.results &&
            params.results.map((autorizacion, idx) => (
            <>
              <Card className={styles.ticket} style={{border: '0px'}}> 
                <div className={styles["dropdown-container"]} >
                <Dropdown isOpen={dropdownOpen && activeIndex === idx} toggle={() => toggleDropdown(idx)} style={{ zIndex: 1000 }}>
                    <DropdownToggle
                      tag="span"
                      onClick={() => toggleDropdown(idx)}
                      className={`${styles.editMenu}`}
                    >
                      <IoEllipsisVertical size={20} />
                    </DropdownToggle>
                  
                  </Dropdown>
                </div>
                <div  onClick={() => history.push(`/autorizacion/view/${autorizacion.id}`)}>
                  <div className={styles["ticket-header"]}>  
                  
                      <b>Autorizacion {autorizacion.autorizacion}</b>      
                                                 
                  </div>   
                
                  <div className={styles["ticket-body"]} >
                  <b>Usuario: {autorizacion.contacto}</b> 
                 
                  <b>Fecha de Correo: {autorizacion.f_correo}</b> 
               
                  </div>
                
                </div>
              
               
              </Card >
            </>
            ))}
        </div>
            </div>

          )}
          <Pagination
            count={params.count}
            next={params.next}
            previous={params.previous}
            limit={12}
            filter={botonActivo !== 'Todas'? true : null}
            baseUrl={`${botonActivo === 'Todas' ? '/casos/casos/' : '/casos/casos/casos_por_estado/?estado='+ botonActivo }`}
            filterFunction={botonActivo === 'Todas'? getFilterData : getFilterAutorizacionData }
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = (state) => {
  const { error, loading, casos } = state.Autorizacion;
  return { error, loading, casos };
};

export default withRouter(connect(mapStateToProps)(Autorizaciones));
