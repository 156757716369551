import { call, put, takeEvery } from "redux-saga/effects";

import {
  SEGUIMIENTO_LIST,
  SEGUIMIENTO_FILTER,
  SEGUIMIENTO_CREATE,
  SEGUIMIENTO_UPDATE,
} from "./actionTypes";

import {
 seguimientoListSuccess,
 seguimientoCreateSuccess,
 seguimientoUpdateSuccess,
 seguimientoRequestFailed,
} from "./actions";

import {
  getSeguimientoList,
  getSeguimientoFilter,
  postSeguimientoCreate,
  patchSeguimientoUpdate,
} from "../../helpers/backend";

function*seguimientoListSaga() {
  console.log("Trayendo lista de seguimiento...");
  try {
    const response = yield call(getSeguimientoList);
    if (response.status === 200) {
      yield put(seguimientoListSuccess(response.data));
    } else {
      yield put(seguimientoRequestFailed(response.data));
    }
  } catch (error) {
    yield put(seguimientoRequestFailed(error.response.data));
  }
}

function*seguimientoCreateSaga({ payload:seguimiento }) {
  console.log("Creando nuevo seguimiento...");
  try {
    const response = yield call(postSeguimientoCreate,seguimiento);
    if (response.status === 201) {
      yield put(seguimientoCreateSuccess(response.data));
    } else {
      yield put(seguimientoRequestFailed(response.data));
    }
  } catch (error) {
    yield put(seguimientoRequestFailed(error.response.data));
  }
}

function*seguimientoFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getSeguimientoFilter, { changeUrl });
    if (response.status === 200) {
      yield put(seguimientoListSuccess(response.data));
    } else {
      yield put(seguimientoRequestFailed(response.data));
    }
  } catch (error) {
    yield put(seguimientoRequestFailed(error.response.data));
  }
}

function*seguimientoUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del seguimiento...");
  try {
    const response = yield call(patchSeguimientoUpdate, { id }, data);
    if (response.status === 200) {
      yield put(seguimientoUpdateSuccess(response.data));
    } else {
      yield put(seguimientoRequestFailed(response.data));
    }
  } catch (error) {
    yield put(seguimientoRequestFailed(error.response.data));
  }
}

export default function*seguimientoSaga() {
  yield takeEvery(SEGUIMIENTO_LIST,seguimientoListSaga);
  yield takeEvery(SEGUIMIENTO_FILTER,seguimientoFilterSaga);
  yield takeEvery(SEGUIMIENTO_CREATE,seguimientoCreateSaga);
  yield takeEvery(SEGUIMIENTO_UPDATE,seguimientoUpdateSaga);
}
