import React, { useState } from "react";
import {
  Col,
  Modal,
  Row,
  Label,
 
  FormFeedback,
  Form,
  Input,
} from "reactstrap";

import PropTypes from "prop-types";
import Select from "react-select";

import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  contactshcCreate,
  contactshcFulllist,
} from "../../../../store/contactshc/actions";

const ContactoAddModal = (props) => {
  const dispatch = useDispatch();

  const optionGroupGenero = [
    {
      options: [
        { label: "Masculino", value: "Masculino" },
        { label: "Femenino", value: "Femenino" },
        { label: "Otro", value: "Otro" },
      ],
    },
  ];

  const optionGroupTipoIdentificacion = [
    {
      options: [
        { label: "CEDULA", value: "CEDULA" },
        { label: "PASAPORTE", value: "PASAPORTE" },
        { label: "CEDULA DE EXTRANJERIA", value: "CEDULA_EXTRANJERIA" },
        { label: "NIT", value: "NIT" },
        { label: "CARNÉ DIPLOMATICO", value: "CARNÉ DIPLOMATICO" },
        { label: "REGISTRO CIVIL", value: "REGISTRO CIVIL" },
        { label: "NUIP", value: "NUIP" },
        { label: "CERTIFICADO DE NACIMIENTO VIVO", value: "CERTIFICADO DE NACIMIENTO VIVO" },
        { label: "SALVOCONDUCTO", value: "SALVOCONDUCTO" },
        { label: "PERMANENCIA ESPECIAL", value: "PERMANENCIA ESPECIAL" },
        { label: "ADULTO SIN IDENTIFICAR", value: "ADULTO SIN IDENTIFICAR" },
        { label: "MENOR SIN IDENTIFICAR", value: "MENOR SIN IDENTIFICAR" },
        { label: "PERMISO DE PROTECCIÓN TEMPORAL", value: "PERMISO DE PROTECCIÓN TEMPORAL" },
        { label: "OTRO", value: "OTRO" },
   
      ],
    },
  ];

  const [formData, setFormData] = useState({
    documentNumber: "",
    birthdate: "2000-01-01",
    city: "",

    phone: "",
    address: "",
    barrio_localidad: "",
    cargo: "",
    persona_contacto: "",
    first_name: "",
    last_name: "",
    contactType: "",
    tipo_cliente: "",
    edad: "",
    gender: "",
    diagnostico: "",
    regimen: "",

  });

  const {
    documentNumber,
    phone,
    persona_contacto,
    first_name,
    last_name,
    documentType,
    edad,
    gender,
    diagnostico,
    regimen
  } = formData;

  const onChange = (e) => {
    const uppercaseValue = e.target.value.toUpperCase();
    setFormData({ ...formData, [e.target.name]: uppercaseValue });
  };

  /**
   * onSubmit function to handle form submit event
   * @param {React.FormEvent<HTMLFormElement>} e - Form event
   * @return {void}
   */
  const onSubmit = (e) => {
    e.preventDefault();

    props.contactshcCreate({
      first_name,
      last_name,
      documentType,
      documentNumber,
      gender,
      phone,
      edad,
      diagnostico,
      regimen
    });
  };

  const handleButtonClick = () => {
    props.onCloseClick();
  };

  return (
    <React.Fragment>
      {props.contactshcCreated.id ? (
        <SweetAlert
          title="Hecho!"
          success
          timeout={1000}
          showConfirm={false}
          onConfirm={() => {
            handleButtonClick();
            dispatch(contactshcFulllist());
          }}
        >
          Se ha agregado el Contacto
        </SweetAlert>
      ) : null}

      <Modal
        isOpen={props.crearModal}
        toggle={props.onCloseClick}
        centered={true}
      >
        <div className="modal-header">
          <h3 className="modal-title mt-2">Crear Nuevo Contacto</h3>
          <button
            type="button"
            onClick={props.onCloseClick}
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            {/* <span aria-hidden="true">&times;</span> */}
          </button>
        </div>

        <Form onSubmit={onSubmit} style={{ padding: "20px" }}>
          {/* Nombres y Apellidos */}
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="first_name" className="form-label">
                Nombres
              </Label>
              <Input
                id="first_name"
                name="first_name"
                type="text"
                onChange={onChange}
                value={first_name}
              />
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="apellido" className="form-label">
                Apellidos
              </Label>
              <Input
                id="last_name"
                name="last_name"
                type="text"
                onChange={onChange}
                value={last_name}
              />
              <FormFeedback>{props.error.last_name}</FormFeedback>
            </Col>
          </Row>

          {/* Persona contacto y telefono */}
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="persona_contacto" className="form-label">
                Persona Contacto
              </Label>
              <Input
                id="persona_contacto"
                name="persona_contacto"
                type="text"
                onChange={onChange}
                value={persona_contacto}
                valid={
                  props.error.persona_contacto
                    ? true
                    : props.contactshcCreated && false
                }
                invalid={
                  props.error
                    ? props.error.persona_contacto
                      ? true
                      : false
                    : false
                }
              />
              <FormFeedback>{props.error.persona_contacto}</FormFeedback>
            </Col>

            <Col lg={6} className="mb-3">
              <Label for="phone" className="form-label">
                Telefono y/o celular
              </Label>
              <Input
                id="phone"
                name="phone"
                type="text"
                onChange={onChange}
                valid={
                  props.error.phone ? true : props.contactshcCreated && false
                }
                invalid={
                  props.error ? (props.error.phone ? true : false) : false
                }
              />

              <FormFeedback>{props.error.phone}</FormFeedback>
            </Col>
          </Row>

          {/*  tipo de identificacion  numero de documento*/}
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="contactType" className="form-label">
                Tipo de identificación
              </Label>
              <select
                className="form-select"
                id="documentType"
                name="documentType"
                value={documentType}
                onChange={onChange}
              >
                <option value="">Seleccione</option>

                {Array.isArray(optionGroupTipoIdentificacion)
                  ? optionGroupTipoIdentificacion[0].options.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))
                  : []}
              </select>
              {props.error && props.error.documentType ? (
                  <p className={`mb-4 mx-5 font-size-13 text-danger `} > {props.error.documentType}</p>
                  ) : null}
            </Col>

            <Col lg={6} className="mb-3">
              <Label for="documento" className="form-label">
                Documento
              </Label>
              <Input
                id="documentNumber"
                name="documentNumber"
                type="number"
                onChange={onChange}
                value={documentNumber}
                />
                </Col>
                {props.error && props.error.documentNumber ? (
                  <p className={`mb-4 mx-5 font-size-13 text-danger `} > {props.error.documentNumber}</p>
                  ) : null}
          </Row>
          {/*  genero y edad*/}
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="contactType" className="form-label">
                Genero
              </Label>
              <Select
                id="gender"
                name="gender"
                onChange={(item) =>
                  setFormData({ ...formData, gender: item.value })
                }
                options={optionGroupGenero}
                placeholder={"Seleccione"}
              />
            </Col>

            <Col lg={6} className="mb-3">
              <Label for="documento" className="form-label">
                Edad
              </Label>
              <Input
                id="edad"
                name="edad"
                type="number"
                onChange={onChange}
                value={edad}
                valid={
                  props.error.edad
                    ? true
                    : props.contactshcCreated && false
                }
                invalid={
                  props.error
                    ? props.error.edad
                      ? true
                      : false
                    : false
                }
              />
              <FormFeedback>{props.error.edad}</FormFeedback>
            </Col>
          </Row>
          {/*  diagnositco y regimen*/}
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="contactType" className="form-label">
                Diagnostico
              </Label>
              <Input
                id="diagnostico"
                name="diagnostico"
                type="text"
                onChange={onChange}
                value={diagnostico}
                valid={
                  props.error.diagnostico
                    ? true
                    : props.contactshcCreated && false
                }
                invalid={
                  props.error
                    ? props.error.diagnostico
                      ? true
                      : false
                    : false
                }
              />
              <FormFeedback>{props.error.diagnostico}</FormFeedback>
            </Col>

            <Col lg={6} className="mb-3">
              <Label for="documento" className="form-label">
                Regimen
              </Label>
              <select
                  className="form-select"
                  id="regimen"
                  name="regimen"
                  onChange={onChange}
                  value={regimen}
                  required
                >
                  <option defaultValue>Seleccione una opción</option>
                  <option value="CONTRIBUTIVO">CONTRIBUTIVO</option>
                  <option value="SUBSIDIADO">SUBSIDIADO</option>
               
                </select>
              <FormFeedback>{props.error.regimen}</FormFeedback>
            </Col>
          </Row>

          <Row className="col-lg-12 justify-content-center">
        
        <Col className="d-flex justify-content-center ">
        
              <button 
              className="btn btn-primary"
               type="submit">
                Enviar
              </button>
          
              <button
                className="btn btn-secondary btn-volver text-dark"
                type="button"
                onClick={handleButtonClick}
                style={{marginLeft:'10px'}}
                
              >
                Volver
              </button>
          
        </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error, loading, contactshcCreated } = state.Contactshc;
  return { error, loading, contactshcCreated };
};

ContactoAddModal.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.bool,
  contactshcCreated: PropTypes.object,
};
export default withRouter(
  connect(mapStateToProps, { contactshcCreate, contactshcFulllist })(
    ContactoAddModal
  )
);
