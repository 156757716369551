import React , { useEffect } from 'react'
import {
    
    Table
  } from "reactstrap";
import { giroFilter } from '../../../store/actions';
import {  useDispatch, useSelector } from "react-redux";

function TableGiro(props) {
    
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(giroFilter(`rutero/?id=${props.id}`))
   
  }, [dispatch,props.id]);

  
  const giro = useSelector((state) => state.Giro.giro);

  
  return (
    <div>
       <Table
         className="table table-bordered table-striped text-center mb-3"
       responsive
      > <thead className="table-dark align-middle" >
   <tr key={props.id}>
        <th >Total Giro</th>
        <th style={{width:"125px"}} >Fecha Contable</th>
        <th  >Empresa</th>
        <th   >Valor Flete</th>
        <th   >Observacion</th>
        <th   >Pin Gelsa</th>
        <th   >Conductor</th>
        <th   >Placa </th>
        <th   >Bauche</th>

        </tr>
        </thead>
        <tbody>

       
        {Array.isArray(giro) 
            ? giro.map((item) => {
              return (
                <React.Fragment  key={item.id}>
                  <tr key={item.id}>
                    <th> {item.total_giro }</th>
                    <th> {item.f_contable }</th>
                    <th> {item.empresa }</th>
                    <th> {item.valor_flete }</th>
                    <th> {item.observacion }</th>
                    <th> {item.pin_gelsa }</th>
                    <th> {item.conductor }</th>
                    <th> {item.placa }</th>
                    <th> {item.bauche }</th>
                  
       
                  </tr>
                </React.Fragment>
              ) 
            })
            : null
        }

        </tbody>
        </Table>
    </div>
  )
}

export default TableGiro
