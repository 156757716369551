import React from 'react'
import { Table } from 'reactstrap'

function TableRuteroInfo(props) {
  return (
    <Table
    className="table table-bordered table-striped text-center mb-3"
    hover
    responsive
     
    >
    <thead className="table-dark align-middle">
    <tr >
      <th  style={{alignContent:"center"}}>Fecha de Servicio</th>
      <th  style={{alignContent:"center"}}>Hora de Servicio</th>
      <th  style={{alignContent:"center"}}>Tipo de Servicio</th>
      <th  style={{alignContent:"center"}}>Ciudad Origen </th>
    
      <th  style={{alignContent:"center"}}>Ciudad Destino </th>
      <th  style={{alignContent:"center"}}>Vereda </th>
      <th  style={{alignContent:"center"}}>Direccion Origen </th>
    
      <th  style={{alignContent:"center"}}>Direccion Destino </th>
      <th  style={{alignContent:"center"}}>Cantidad </th>
 
    
    </tr>
    </thead>
    <tbody>
    
    <tr>
      <td>{props.itemFechaServicio}</td>
      <td>{props.itemHoraServicio}</td>
      <td>{props.tipodeServicio}</td>
      <td>{props.ciudad_origen}</td>
      <td>{props.ciudad_destino}</td>  
      <td>{props.vereda}</td>
      <td>{props.direccion_origen}</td>
      <td>{props.direccion_destino}</td>
      <td>{props.cantida}</td>
     
      </tr>
    </tbody>
    </Table>
  )
}

export default TableRuteroInfo
