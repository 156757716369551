import React , { useEffect } from 'react'
import {
    
    Table
  } from "reactstrap";


import { facturaFilter } from '../../../store/actions';
import {  useDispatch, useSelector } from "react-redux";
 
function TableFactura(props) {






  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(facturaFilter(`rutero/?id=${props.id}`))
   
  }, [dispatch,props.id]);
  



  const factura = useSelector((state) => state.Factura.factura);

  

 
  return (
    <div className="table-responsive" style={{ overflowX: "auto" }}>


    <Table
         className="table table-bordered table-striped text-center mb-3"
       responsive
      > <thead className="table-dark align-middle">
   <tr key={props.id}>
        <th style={{minWidth: "150px"}}>Codigos DANE Cod. 1 ORIGEN</th>
        <th style={{minWidth: "115px"}} >Codigos DANE cod 2 DESTINO</th>
        <th style={{minWidth: "115px"}} >LLave ORIGEN-DESTINO (PLANTILLA KM)</th>
        <th style={{minWidth: "115px"}}  >Vehiculo Sale</th>
        <th style={{minWidth: "115px"}}  >KM Sale Desde</th>
        <th style={{minWidth: "115px"}}  >KM Punto a Punto</th>
        <th style={{minWidth: "115px"}}  >Km Adicional</th>
        <th style={{minWidth: "115px"}}  >Valor Total Km</th>
        <th style={{minWidth: "115px"}}  >Local</th>
        <th style={{minWidth: "115px"}}  >Traslado especial Rampa</th>
        <th style={{minWidth: "115px"}}  >Aeropuerto</th>
        <th style={{minWidth: "115px"}}  >Total Servicio</th>
        <th   style={{minWidth: "115px"}}>Nro de Factura</th>

        <th  style={{minWidth: "115px"}}>Fecha Factura</th>
        </tr>
        </thead>
        <tbody>

       
        {Array.isArray(factura) 
            ? factura.map((item) => {
              return (
                <React.Fragment  key={item.id}>
                  <tr key={item.id}>
                    <th> {item.cod_1}</th>
                    <th> {item.cod_2}</th>
                    <th> {item.origen_dest_km}</th>
                    <th> {item.vehiculo_sale}</th>
                    <th> {item.km_sale}</th>
                    <th> {item.km_punto}</th>
                    <th> {item.km_adicional}</th>
                    <th> {item.valor_km_total}</th>
                    <th> {item.local}</th>
                    <th> {item.trasl_esp_rampa}</th>
                    <th> {item.aeropuerto }</th>
                    <th> {item.total_serv}</th>
                
                    <th> {item.n_factura}</th>
                    <th> {item.f_factura}</th>
                  </tr>
                </React.Fragment>
              ) 
            })
            : null
        }

        </tbody>
        </Table>
    </div>
  )
}

export default TableFactura
