import React, { useState } from "react";
import { Col, Row, Form, Input, Label, Button, Modal, Table } from "reactstrap";
import departamentos from "../Departamentos";
import CurrencyInput from "react-currency-input-field";

import SweetAlert from "react-bootstrap-sweetalert";

import { facturaCreate, facturaFilter } from "../../../store/actions";

import { useDispatch, useSelector } from "react-redux";
import TableConfirmacion from "../Tables/TableConfirmacion";
import TableTransporte from "../Tables/TableTransporte";
import TableSeguimiento from "../Tables/TableSeguimiento";
import TableGiro from "../Tables/TableGiro";
import TableRuteroInfo from "../Tables/TableRuteroInfo";

function Factura(props) {
  const dispatch = useDispatch();

  const allCities = departamentos
    .flatMap((department) => department.cities)
    .sort((a, b) => a.localeCompare(b));
  console.log(props);
  const [f_factura, setSelectedFechaFactura] = useState("");
  const [valor_km_total, setValorKmTotal] = useState("0");
  const [local, setLocal] = useState("");
  const [trasl_esp_rampa, setTraslEspRampa] = useState("");
  const [total_serv, setTotalServ] = useState("");

  const [vehiculo_sale, setSelectedvehiculoSale] = useState("");

  const Factura = useSelector((state) => state.Factura);

  const handleVehiculoSaleChange = (e) => {
    setSelectedvehiculoSale(e.target.value);
    // Resetea la ciudad seleccionada cuando cambias el departamento
  };
  const onChangeFechaFactura = (e) => {
    setSelectedFechaFactura(e.target.value);
  };

  const [formData, setFormData] = useState({
    n_factura: "",

    cod_1: "",
    cod_2: "",
    origen_dest_km: "",

    km_sale: "",
    km_punto: "",
    km_adicional: "",

    local: "",
    trasl_esp_rampa: "",
    aeropuerto: "",
  });

  const {
    n_factura,

    cod_1,
    cod_2,
    origen_dest_km,

    km_sale,
    km_punto,
    km_adicional,

    aeropuerto,
  } = formData;

  const onChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    dispatch(
      facturaCreate({
        n_factura,
        f_factura,
        cod_1,
        cod_2,
        origen_dest_km,
        vehiculo_sale,
        km_sale,
        km_punto,
        km_adicional,
        valor_km_total,
        local,
        trasl_esp_rampa,
        aeropuerto,
        total_serv,
        rutero: props.id,
      })
    );
  };

  console.log(props);

  const resetFormData = () => {
    setValorKmTotal("");
    setLocal("");
    setTraslEspRampa("");
    setTotalServ("");
    setSelectedFechaFactura("");

    setFormData({
      n_factura: "",
      cod_1: "",
      cod_2: "",
      origen_dest_km: "",
      km_sale: "",
      km_punto: "",
      km_adicional: "",
      local: "",
      trasl_esp_rampa: "",
      aeropuerto: "",
    });
  };
  return (
    <div>
      {Factura.facturaCreated.id ? (
        <SweetAlert
          title="¡Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            dispatch(facturaFilter(`rutero/?id=${props.id}`));
            resetFormData();

            props.onCloseClick();
          }}
        >
          Se ha agregado la factura correctamente.
        </SweetAlert>
      ) : null}

      <Modal isOpen={props.ModalFactura} toggle={props.onCloseClick} size="xl">
        <div className="modal-header">
          <h3 className="modal-title mt-2">Crear Factura</h3>
          <button
            type="button"
            onClick={props.onCloseClick}
            className="btn-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            {/* <span aria-hidden="true">&times;</span> */}
          </button>
        </div>

        <div className="d-flex justify-content-center">

<h4 style={{ margin: "10px" }}>Rutero: {props.selectedNumGestion}</h4>
</div>

        <div style={{ margin: "10px" }}>
          <TableRuteroInfo
            itemFechaServicio={props.itemFechaServicio}
            itemHoraServicio={props.itemHoraServicio}
            tipodeServicio={props.tipodeServicio}
            ciudad_origen={props.ciudad_origen}
            ciudad_destino={props.ciudad_destino}
            vereda={props.vereda}
            direccion_origen={props.direccion_origen}
            direccion_destino={props.direccion_destino}
            cantida={props.cantida}
          ></TableRuteroInfo>

          <TableConfirmacion></TableConfirmacion>

          <TableTransporte></TableTransporte>
          <TableSeguimiento></TableSeguimiento>

          <TableGiro></TableGiro>
        </div>

        <Form className="form-control">
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="cod_1" className="form-label">
                Codigos DANE Cod. 1 ORIGEN
              </Label>
              <Input
                id="cod_1"
                name="cod_1"
                type="text"
                onChange={onChange}
                value={cod_1}
                required
              />
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="cod_2" className="form-label">
                Código DANE cod 2 DESTINO
              </Label>
              <Input
                id="cod_2"
                name="cod_2"
                type="text"
                onChange={onChange}
                value={cod_2}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="origen_dest_km" className="form-label">
                "LLAVE ORIGEN-DESTINO (PLANTILLA KM)"
              </Label>
              <Input
                id="origen_dest_km"
                name="origen_dest_km"
                type="text"
                onChange={onChange}
                value={origen_dest_km}
                required
              />
              {Factura.error && Factura.error.origen_dest_km ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {Factura.error.origen_dest_km}
                </p>
              ) : null}
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="nombre_empresa" className="form-label">
                VEHICULO SALE
              </Label>
              <select
                onChange={handleVehiculoSaleChange}
                value={vehiculo_sale}
                className="form-control"
              >
                <option value="">Selecciona una ciudad</option>
                {allCities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="km_sale" className="form-label">
                KM SALE DESDE
              </Label>
              <Input
                id="km_sale"
                name="km_sale"
                type="text"
                onChange={onChange}
                value={km_sale}
                required
              />

              {Factura.error && Factura.error.km_sale ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {Factura.error.km_sale}
                </p>
              ) : null}
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="km_punto" className="form-label">
                KM PUNTO A PUNTO
              </Label>
              <Input
                id="km_punto"
                name="km_punto"
                type="text"
                onChange={onChange}
                value={km_punto}
              />

              {Factura.error && Factura.error.km_punto ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {Factura.error.km_punto}
                </p>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="km_adicional" className="form-label">
                KM ADICIONAL
              </Label>
              <Input
                id="km_adicional"
                name="km_adicional"
                type="number"
                onChange={onChange}
                value={km_adicional}
                required
              />
              {Factura.error && Factura.error.km_adicional ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {Factura.error.km_adicional}
                </p>
              ) : null}
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="valor_km_total" className="form-label">
                VLOR. TOTAL KM
              </Label>
              <CurrencyInput
                id="valor_km_total"
                name="valor_km_total"
                className="form-control"
                placeholder="$0"
                value={valor_km_total}
                decimalsLimit={3}
                prefix="$"
                onValueChange={(value, name, values) => setValorKmTotal(value)}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="local" className="form-label">
                LOCAL
              </Label>
              <CurrencyInput
                id="local"
                name="local"
                className="form-control"
                placeholder="$0"
                value={local}
                decimalsLimit={3}
                prefix="$"
                onValueChange={(value, name, values) => setLocal(value)}
              />
              {Factura.error && Factura.error.local ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {Factura.error.local}
                </p>
              ) : null}
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="trasl_esp_rampa" className="form-label">
                TRASLADO ESPECIAL RAMPA
              </Label>
              <CurrencyInput
                id="trasl_esp_rampa"
                name="trasl_esp_rampa"
                className="form-control"
                placeholder="$0"
                value={trasl_esp_rampa}
                decimalsLimit={3}
                prefix="$"
                onValueChange={(value, name, values) => setTraslEspRampa(value)}
              />
              {Factura.error && Factura.error.trasl_esp_rampa ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {Factura.error.trasl_esp_rampa}
                </p>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="aeropuerto" className="form-label">
                AEROPUERTO
              </Label>
              <Input
                id="aeropuerto"
                name="aeropuerto"
                type="text"
                onChange={onChange}
                value={aeropuerto}
                required
              />
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="total_serv" className="form-label">
                TOTAL SERVICIO
              </Label>
              <CurrencyInput
                id="total_serv"
                name="total_serv"
                className="form-control"
                placeholder="$0"
                value={total_serv}
                decimalsLimit={3}
                prefix="$"
                onChange={onChange}
                onValueChange={(value, name, values) => setTotalServ(value)}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6} className="mb-3">
              <Label for="n_factura" className="form-label">
                CONTABILIZADA
              </Label>
              <Input
                id="n_factura"
                name="n_factura"
                type="text"
                onChange={onChange}
                value={n_factura}
                required
              />
            </Col>
            <Col lg={6} className="mb-3">
              <Label for="nombre_empresa" className="form-label">
                FECHA CONTABILIZADA
              </Label>
              <input
                className="form-control d-block"
                placeholder="Fecha"
                onChange={(e) => onChangeFechaFactura(e)}
                type="date"
                value={f_factura}
              />
              {Factura.error && Factura.error.f_factura ? (
                <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                  {Factura.error.f_factura}
                </p>
              ) : null}
            </Col>

            <Row className="col-lg-12 justify-content-center mt-4">
              <Col md={1} className="mx-1">
                <Button color="primary" type="button" onClick={onSubmit}>
                  Enviar
                </Button>
              </Col>
            </Row>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

export default Factura;
