import {
  AUTORIZACION_LIST,
  AUTORIZACION_FILTER,
  AUTORIZACION_CREATE,
  AUTORIZACION_CREATE_SUCCESS,
  AUTORIZACION_LIST_SUCCESS,
  AUTORIZACION_REQUEST_FAILED,
  AUTORIZACION_UPDATE,
  AUTORIZACION_UPDATE_SUCCESS,
} from "../../store/autorizacion/actionTypes";

const initialState = {
  autorizacion: [],
  autorizacionCreated: {},
  autorizacionUpdated: {},
  loading: false,
  error: "",
};

const autorizacion = (state = initialState, action) => {
  switch (action.type) {
    case AUTORIZACION_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AUTORIZACION_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AUTORIZACION_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AUTORIZACION_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case AUTORIZACION_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        autorizacionCreated: action.payload,
        error: "",
      };
      break;
    case AUTORIZACION_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        autorizacion: action.payload,
        autorizacionCreated: {},
        autorizacionUpdated: {},
        error: "",
      };
      break;
    case AUTORIZACION_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        autorizacionUpdated: action.payload,
        error: "",
      };
      break;
    case AUTORIZACION_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default autorizacion;
