import {
  CONFIRMACION_LIST,
  CONFIRMACION_FILTER,
  CONFIRMACION_CREATE,
  CONFIRMACION_CREATE_SUCCESS,
  CONFIRMACION_LIST_SUCCESS,
  CONFIRMACION_REQUEST_FAILED,
  CONFIRMACION_UPDATE,
  CONFIRMACION_UPDATE_SUCCESS,
} from "../../store/confirmacion/actionTypes";

const initialState = {
  confirmacion: [],
  confirmacionCreated: {},
  confirmacionUpdated: {},
  loading: false,
  error: "",
};

const confirmacion = (state = initialState, action) => {
  switch (action.type) {
    case CONFIRMACION_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONFIRMACION_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONFIRMACION_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONFIRMACION_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONFIRMACION_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        confirmacionCreated: action.payload,
        error: "",
      };
      break;
    case CONFIRMACION_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        confirmacion: action.payload,
        confirmacionCreated: {},
        confirmacionUpdated: {},
        error: "",
      };
      break;
    case CONFIRMACION_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        confirmacionUpdated: action.payload,
        error: "",
      };
      break;
    case CONFIRMACION_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default confirmacion;
