import { call, put, takeEvery } from "redux-saga/effects";

import {
  COORDINACION_LIST,
  COORDINACION_FILTER,
  COORDINACION_CREATE,
  COORDINACION_UPDATE,
} from "./actionTypes";

import {
 coordinacionListSuccess,
 coordinacionCreateSuccess,
 coordinacionUpdateSuccess,
 coordinacionRequestFailed,
} from "./actions";

import {
  getCoordinacionList,
  getCoordinacionFilter,
  postCoordinacionCreate,
  patchCoordinacionUpdate,
} from "../../helpers/backend";

function*coordinacionListSaga() {
  console.log("Trayendo lista de coordinacion...");
  try {
    const response = yield call(getCoordinacionList);
    if (response.status === 200) {
      yield put(coordinacionListSuccess(response.data));
    } else {
      yield put(coordinacionRequestFailed(response.data));
    }
  } catch (error) {
    yield put(coordinacionRequestFailed(error.response.data));
  }
}

function*coordinacionCreateSaga({ payload:coordinacion }) {
  console.log("Creando nuevo coordinacion...");
  try {
    const response = yield call(postCoordinacionCreate,coordinacion);
    if (response.status === 201) {
      yield put(coordinacionCreateSuccess(response.data));
    } else {
      yield put(coordinacionRequestFailed(response.data));
    }
  } catch (error) {
    yield put(coordinacionRequestFailed(error.response.data));
  }
}

function*coordinacionFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getCoordinacionFilter, { changeUrl });
    if (response.status === 200) {
      yield put(coordinacionListSuccess(response.data));
    } else {
      yield put(coordinacionRequestFailed(response.data));
    }
  } catch (error) {
    yield put(coordinacionRequestFailed(error.response.data));
  }
}

function*coordinacionUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del coordinacion...");
  try {
    const response = yield call(patchCoordinacionUpdate, { id }, data);
    if (response.status === 200) {
      yield put(coordinacionUpdateSuccess(response.data));
    } else {
      yield put(coordinacionRequestFailed(response.data));
    }
  } catch (error) {
    yield put(coordinacionRequestFailed(error.response.data));
  }
}

export default function*coordinacionSaga() {
  yield takeEvery(COORDINACION_LIST,coordinacionListSaga);
  yield takeEvery(COORDINACION_FILTER,coordinacionFilterSaga);
  yield takeEvery(COORDINACION_CREATE,coordinacionCreateSaga);
  yield takeEvery(COORDINACION_UPDATE,coordinacionUpdateSaga);
}
