import {
  AREAS_LIST,
  AREAS_FILTER,
  AREAS_CREATE,
  AREAS_UPDATE,
  AREAS_UPDATE_SUCCESS,
  AREAS_CREATE_SUCCESS,
  AREAS_LIST_SUCCESS,
  AREAS_REQUEST_FAILED,
  AREAS_FULLLIST,
  AREAS_FULLLIST_SUCCESS,
} from "./actionTypes";

export const areasList = () => {
  return {
    type: AREAS_LIST,
    payload: {},
  };
};

export const areasFullList = () => {
  return {
    type: AREAS_FULLLIST,
    payload: {},
  };
}
export const areasCreate = (areas) => {
  return {
    type: AREAS_CREATE,
    payload: areas,
  };
};

export const areasFilter = (changeUrl) => {
  return {
    type: AREAS_FILTER,
    payload: { changeUrl },
  };
};

export const areasUpdate = (id, data) => {
  return {
    type: AREAS_UPDATE,
    payload: { id, data },
  };
};

export const areasListSuccess = (areas) => {
  return {
    type: AREAS_LIST_SUCCESS,
    payload: areas,
  };
};
export const areasFullListSuccess = (actividad_diaria) => {
  return {
    type: AREAS_FULLLIST_SUCCESS,
    payload: actividad_diaria,
  };
};


export const areasCreateSuccess = (areasCreated) => {
  return {
    type: AREAS_CREATE_SUCCESS,
    payload: areasCreated,
  };
};

export const areasUpdateSuccess = (areas) => {
  return {
    type: AREAS_UPDATE_SUCCESS,
    payload: areas,
  };
};

export const areasRequestFailed = (error) => {
  return {
    type: AREAS_REQUEST_FAILED,
    payload: error,
  };
};
