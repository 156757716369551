// CONTACTS requests
export const CONTACTS_LIST = "CONTACTS_LIST";
export const CONTACTS_CREATE = "CONTACTS_CREATE";
export const CONTACTS_FILTER = "CONTACTS_FILTER";
export const CONTACTS_UPDATE = "CONTACTS_UPDATE";
export const CONTACTS_DELETE = "CONTACTS_DELETE";
export const CONTACTS_READ = "CONTACTS_READ";
export const CONTACTS_FULLLIST = "CONTACTS_FULLLIST";
// CONTACTS success
export const CONTACTS_LIST_SUCCESS = "CONTACTS_LIST_SUCCESS";
export const CONTACTS_CREATE_SUCCESS = "CONTACTS_CREATE_SUCCESS";
export const CONTACTS_UPDATE_SUCCESS = "CONTACTS_UPDATE_SUCCESS";
export const CONTACTS_DELETE_SUCCESS = "CONTACTS_DELETE_SUCCESS";
export const CONTACTS_READ_SUCCESS = "CONTACTS_READ_SUCCESS";
export const CONTACTS_FULLLIST_SUCCESS = "CONTACTS_FULLLIST_SUCCESS";

// CONTACTS failed
export const CONTACTS_REQUEST_FAILED = "CONTACTS_REQUEST_FAILED";
