import {
  FACTURA_LIST,
  FACTURA_FILTER,
  FACTURA_CREATE,
  FACTURA_CREATE_SUCCESS,
  FACTURA_LIST_SUCCESS,
  FACTURA_REQUEST_FAILED,
  FACTURA_UPDATE,
  FACTURA_UPDATE_SUCCESS,
} from "../../store/factura/actionTypes";

const initialState = {
  factura: [],
  facturaCreated: {},
  facturaUpdated: {},
  loading: false,
  error: "",
};

const factura = (state = initialState, action) => {
  switch (action.type) {
    case FACTURA_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FACTURA_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FACTURA_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FACTURA_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case FACTURA_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        facturaCreated: action.payload,
        error: "",
      };
      break;
    case FACTURA_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        factura: action.payload,
        facturaCreated: {},
        facturaUpdated: {},
        error: "",
      };
      break;
    case FACTURA_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        facturaUpdated: action.payload,
        error: "",
      };
      break;
    case FACTURA_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default factura;
