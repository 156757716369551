import {
  RUTERO_LIST,
  RUTERO_FILTER,
  RUTERO_CREATE,
  RUTERO_CREATE_SUCCESS,
  RUTERO_LIST_SUCCESS,
  RUTERO_REQUEST_FAILED,
  RUTERO_UPDATE,
  RUTERO_UPDATE_SUCCESS,
} from "../../store/rutero/actionTypes";

const initialState = {
  rutero: [],
  ruteroCreated: {},
  ruteroUpdated: {},
  loading: false,
  error: "",
};

const rutero = (state = initialState, action) => {
  switch (action.type) {
    case RUTERO_CREATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case RUTERO_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case RUTERO_FILTER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case RUTERO_UPDATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case RUTERO_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        ruteroCreated: action.payload,
        error: "",
      };
      break;
    case RUTERO_LIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        rutero: action.payload,
        ruteroCreated: {},
        ruteroUpdated: {},
        error: "",
      };
      break;
    case RUTERO_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        ruteroUpdated: action.payload,
        error: "",
      };
      break;
    case RUTERO_REQUEST_FAILED:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default rutero;
