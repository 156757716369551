// SEGUIMIENTO requests
export const SEGUIMIENTO_LIST = "SEGUIMIENTO_LIST";
export const SEGUIMIENTO_CREATE = "SEGUIMIENTO_CREATE";
export const SEGUIMIENTO_FILTER = "SEGUIMIENTO_FILTER";
export const SEGUIMIENTO_UPDATE = "SEGUIMIENTO_UPDATE";
export const SEGUIMIENTO_DELETE = "SEGUIMIENTO_DELETE";
export const SEGUIMIENTO_READ = "SEGUIMIENTO_READ";

// SEGUIMIENTO success
export const SEGUIMIENTO_LIST_SUCCESS = "SEGUIMIENTO_LIST_SUCCESS";
export const SEGUIMIENTO_CREATE_SUCCESS = "SEGUIMIENTO_CREATE_SUCCESS";
export const SEGUIMIENTO_UPDATE_SUCCESS = "SEGUIMIENTO_UPDATE_SUCCESS";
export const SEGUIMIENTO_DELETE_SUCCESS = "SEGUIMIENTO_DELETE_SUCCESS";
export const SEGUIMIENTO_READ_SUCCESS = "SEGUIMIENTO_READ_SUCCESS";

// SEGUIMIENTO failed
export const SEGUIMIENTO_REQUEST_FAILED = "SEGUIMIENTO_REQUEST_FAILED";
