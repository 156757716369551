import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { Card, Nav, NavItem, NavLink, TabContent, TabPane, CardBody, Table, Button, Modal } from 'reactstrap';
import classnames from 'classnames';


// Redux
import { connect} from "react-redux";
import { withRouter,useParams, Link} from "react-router-dom";

import { contactsDataCreate, contactsDataDelete, contactsDataUpdate } from "../../../store/contactsData/actions"
import { getContactsDataAddress, getContactsDataFilter, getContactsDataMail, getContactsDataPhone } from '../../../helpers/backend';
import Pagination from '../../../components/Common/Pagination';

const DatoTab = (props) => {

    const param = useParams();
    const str = param.id; 
    const contactoId = Number(str);
    const user = JSON.parse(localStorage.getItem("user"))
    const groups =String(user.groups);

  //Nav  
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
      if (activeTab !== tab) setActiveTab(tab);

      }

  const optionGroupLabel1 = [
      {
      options: [
          { label: "Whatsapp", value: "Whatsapp" },
          { label: "Principal", value: "Principal" },
          { label: "Casa ", value: "Casa" },
          { label: "Trabajo ", value: "Trabajo" },
          { label: "Movil ", value: "Movil" },
        ],
      },
  ];
  
  const optionGroupLabel2 = [
      {
      options: [
          { label: "Principal", value: "Principal" },
          { label: "Trabajo ", value: "Trabajo" },
          { label: "Particular ", value: "Particular" },
          { label: "Otro ", value: "Otro" },
        ],
      },
  ];
  
  const optionGroupLabel3 = [
      {
      options: [
          { label: "Principal", value: "Principal" },
          { label: "Trabajo ", value: "Trabajo" },
          { label: "Casa ", value: "Casa" },
          { label: "Otro ", value: "Otro" },
        ],
      },
  ];
  
    //Telefono
    const [telefono1, settelefono1] = useState(false)
    const [telefono1_txt, settelefono1_txt] = useState("")
    const [telefono2, settelefono2] = useState(false)
    const [telefono2_txt, settelefono2_txt] = useState("")


    //email
    const [email1, setemail1] = useState(false)
    const [email1_txt, setemail1_txt] = useState("")
    const [email2, setemail2] = useState(false)
    const [email2_txt, setemail2_txt] = useState("")

    //direciion
    const [direccion1, setdireccion1] = useState(false)
    const [direccion1_txt, setdireccion1_txt] = useState("")
    const [direccion2, setdireccion2] = useState(false)
    const [direccion2_txt, setdireccion2_txt] = useState("")   
    
 //   const [datoId, setDatoId] = useState("")
    const [tipo, setTipo]= useState("")
    const [error, setError] = useState(false)
    const [showAlert, setShowAlert] = useState(false)

    function handleTelefono1Change(telefono1_txt) {
        settelefono1_txt(telefono1_txt.value)
    }

    function handleTelefono2Change(e) {
        settelefono2_txt(e.target.value)
    }

    function handleEmail1Change(telefono1_txt) {
        setemail1_txt(telefono1_txt.value)
    }

    function handleEmail2Change(e) {
        setemail2_txt(e.target.value)
    }

    function handleDireccion1Change(direccion1_txt) {
        setdireccion1_txt(direccion1_txt.value)
    }

    function handleDireccion2Change(e) {
        setdireccion2_txt(e.target.value)
    }
    
    useEffect(() => {
      getPhoneData();
      getAddressData();
      getMailData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    
    const handleAdd = (dato) =>{ 
      if (dato ==='Telefono'){
        settelefono1(!telefono1);
        setTipo("Telefono")
    }
    else if (dato === 'Email'){
        setemail1(!email1);
        setTipo("Email")
    }

    else if (dato ==='Direccion'){
        setdireccion1(!direccion1);
        setTipo("Direccion")
    }
    }
   
    //props

    const  [phoneData, setPhoneData] = useState ({});
    const getPhoneData = async () => {
      const response = await getContactsDataPhone(contactoId);
      const data = response.data;
      setPhoneData(data)
    }
    const  [addressData, setAddressData] = useState ({});
    const getAddressData = async () => {
      const response = await getContactsDataAddress(contactoId);
      const data = response.data;
      setAddressData(data)
    }
    const  [mailData, setMailData] = useState ({});
    const getMailData = async () => {
      const response = await getContactsDataMail(contactoId);
      const data = response.data;
      setMailData(data)
    }

 
  const labelAdd = telefono1_txt || email1_txt || direccion1_txt; 
  const value_1Add = telefono2_txt || email2_txt || direccion2_txt; 
  
  
  const onSubmit = () => {
      props.contactsDataCreate({
          contact : contactoId, 
          label : labelAdd, 
          tipo, 
          value_1: value_1Add, 
  
      });
      setShowAlert(true);
      settelefono1_txt("")
      settelefono2_txt("")
      setemail1_txt("")
      setemail2_txt("")
      setdireccion1_txt("")
      setdireccion2_txt("")
     };
    

  return (
    <React.Fragment>
          
    {telefono1 ? (
        <Modal
            isOpen={telefono1}
            centered={true}
            toggle={()=> {settelefono1_txt(""); settelefono1(false)}}

        >          
        <div className="modal-header">
        <h3 className="modal-title mt-2">
            Tipo de Contacto
        </h3>
        <button
            type="button"
            onClick={() => {
              settelefono1_txt("")
              settelefono1(false);
                
            }}
            className="btn"
            data-dismiss="modal"
            aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
         <div className="modal-body">
            <Select
                id= 'tipo_Contacto'
                name='tipo_Conatcto'
                options={optionGroupLabel1}
                placeholder="Seleccione una opción"    
                onChange={handleTelefono1Change} 
                autoFocus
                required   
                
            />
            <div className="modal-footer">
              <button
                  type="button"
                  className="btn btn-secondary text-dark"
                  onClick={() => {
                    settelefono1_txt(""); 
                    settelefono1(false)
                  }}
              >
                  Cerrar
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={()=> {
                    if(telefono1_txt !== ""){
                      settelefono2(true); 
                      settelefono1(false)
                    }
                    else{
                      setError(true)
                    }
                  }}
              >
                  Siguiente
              </button>
            </div>
         </div>
        </Modal>
    ) : null}
  
        <Modal
            isOpen={telefono2}
            centered={true}
            toggle={()=> {
              settelefono1_txt(""); 
              settelefono2(false)
            }}

        > 
        <div className="modal-header">
        <h3 className="modal-title mt-2">
        Número de Teléfono
        </h3>
        <button
            type="button"
            onClick={() => {
              settelefono1_txt("");
              settelefono1(false);
            }}
            className="btn"
            data-dismiss="modal"
            aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
            <input
                type="text"
                className="form-control"
                placeholder="Ingrese número de Teléfono "
                onChange={e => {
                    handleTelefono2Change(e)
                }}

            />
            <div className="modal-footer">
              <button
                  type="button"
                  className="btn btn-secondary text-dark"
                  onClick={() => {
                    settelefono1_txt("");
                    settelefono2(false)
                  }}
              >
                  Atras
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    if(telefono2_txt !== ""){
                      onSubmit(); 
                      settelefono2(false)
                    }
                    else{
                      setError(true)
                    }
                  }}
              >
                  Siguiente
              </button>
            </div>    
        </div>
        </Modal>


    {email1 ? (
        <Modal
            isOpen={email1}
            centered={true}
            toggle ={()=> {
              setemail1_txt(""); 
              setemail1(false)
            }}

        >
        <div className="modal-header">
        <h3 className="modal-title mt-2">
            Tipo de Contacto
        </h3>
        <button
            type="button"
            onClick={() => {
              setemail1_txt("");
              setemail1(false);                
            }}
            className="btn"
            data-dismiss="modal"
            aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
         <div className="modal-body">
            <Select
                id= 'tipo_Contacto'
                name='tipo_Conatcto'
                options={optionGroupLabel2}
                placeholder="Seleccione una opción"    
                onChange={handleEmail1Change}           
            />
        <div className="modal-footer">
              <button
                  type="button"
                  className="btn btn-secondary text-dark"
                  onClick={() => {
                    setemail1_txt("");
                    setemail1(false)
                  }}
              >
                  Cerrar
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={()=> {
                    if(email1_txt !== ""){
                      setemail2(true); 
                      setemail1(false)
                    }
                    else{
                      setError(true)
                    }
                    }}
              >
                  Siguiente
              </button>
            </div>
        </div>   
        </Modal>
    ) : null}

    {email2 ? (
        <Modal
        isOpen={email2}
        centered={true}
        toggle={()=> {
          setemail1_txt("");
          setemail2(false)
        }}
        >
        <div className="modal-header">
        <h3 className="modal-title mt-2">
        E-mail
        </h3>
        <button
            type="button"
            onClick={() => {
              setemail1_txt("");
              setemail2(false);
            }}
            className="btn"
            data-dismiss="modal"
            aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
            <input
                type="text"
                className="form-control"
                placeholder="example@xxxxx.com"
                onChange={e => {
                    handleEmail2Change(e)
                }}
            />
            <div className="modal-footer">
              <button
                  type="button"
                  className="btn btn-secondary text-dark"
                  onClick={() => {
                    setemail1_txt("");
                    setemail2(false)
                  }}
              >
                  Atras
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    if (email2_txt!== ""){
                      onSubmit(); 
                      setemail2(false)
                    }
                    else{
                      setError(true)
                    }
                  }}
              >
                  Siguiente
              </button>
            </div>    
        </div>
        </Modal>
    ) : null}

    {direccion1 ? (
        <Modal
        isOpen={direccion1}
        centered={true}
        toggle={()=> {setdireccion1_txt(""); setdireccion1(false)}}
        >
        <div className="modal-header">
            <h3 className="modal-title mt-2">
            Tipo de Contacto
             </h3>
        <button
            type="button"
            onClick={() => {
              setdireccion1_txt("");
              setdireccion1(false);               
            }}
            className="btn"
            data-dismiss="modal"
            aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
         <div className="modal-body">
            <Select
                id= 'tipo_Contacto'
                name='tipo_Conatcto'
                options={optionGroupLabel3}
                placeholder="Tipo de Contacto"    
                onChange={handleDireccion1Change}           
            />
            <div className="modal-footer">
              <button
                  type="button"
                  className="btn btn-secondary text-dark"
                  onClick={() => {
                    setdireccion1_txt("");
                    setdireccion1(false)
                  }}
              >
                  Cerrar
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={()=> {
                    if(direccion1_txt !== ""){
                      setdireccion2(true); 
                      setdireccion1(false);
                    }
                    else{
                      setError(true);
                    }
                  }}
              >
                  Siguiente
              </button>
            </div>
        </div>
        </Modal>
    ) : null}

        <Modal
            isOpen={direccion2}
            centered={true}
            toggle ={()=> {
              setdireccion1_txt("");
              setdireccion2(false)
            }}
        >
        <div className="modal-header">
        <h3 className="modal-title mt-2">
        Dirección
        </h3>
        <button
            type="button"
            onClick={() => {
              setdireccion1_txt("");
              setdireccion2(false);
            }}
            className="btn"
            data-dismiss="modal"
            aria-label="Close"
        >
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">

            <input
                type="text"
                className="form-control"
                onChange={e => {
                    handleDireccion2Change(e)
                }}
            />
            <div className="modal-footer">
              <button
                  type="button"
                  className="btn btn-secondary text-dark"
                  onClick={() => {
                    setdireccion1_txt("");
                    setdireccion2(false)
                  }}
              >
                  Atras
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    if(direccion2_txt !== ""){
                      onSubmit(); 
                      setdireccion2(false)
                    }
                    else{
                      setError(true)
                    }
                  }}
              >
                  Siguiente
              </button>
            </div>    
        </div>
        </Modal>
            {props.contactsDataCreated.id && showAlert ? (
                <SweetAlert
                    title="¡Hecho!"
                    success
                    showConfirm={false}
                    timeout={2000}
                    onConfirm={() => {
                      getPhoneData();
                      getAddressData();
                      getMailData();
                      setShowAlert(false)           
                    }}
                >
                  Se ha Agregado el contacto con Éxito
                </SweetAlert>
            ) : null}
            {props.contactsDataUpdated.id && showAlert ? (
                <SweetAlert
                    title="¡Hecho!"
                    success
                    showConfirm= {false}
                    timeout={2000}
                    onConfirm={() => {
                      getPhoneData();   
                      setShowAlert(false) 
                    }}
                >
                  Se ha Actualizado el contacto con Éxito
                </SweetAlert>
            ) : null}
            {error  ? (
              <SweetAlert
                title="Error"
                warning
                timeout={2000}
                showConfirm={false}
                onConfirm={() => {
                  setError(false);
                }}
              >
                Debe Agregar los Campos Requeridos
              </SweetAlert>
            ) : null}


            {props.contactsDataDeleted === true ? (
              <SweetAlert
                title= "Hecho!"
                success
                showConfirm={false}
                timeout={2000}
                >
                  Se ha eliminado el Usuario con éxito!
                </SweetAlert>
            ) : null}
            <Link
              className ="btn btn-info btn-sm mb-2 "
              to="/contacts">
                Volver
            </Link>{"  "}
            <Card className="mb-0">
                <Nav tabs justified className="nav-tabs-custom" role="tablist">
                    <NavItem>
                        <NavLink
                            to="#"
                            className={classnames({ active: activeTab === '1' })}
                            onClick={() => { toggle('1'); }}
                            style={{ cursor: 'pointer'}}
                        >
                            <i className="bx  bx-mobile bx-sm"></i>
                            <span className="d-none d-sm-block">Teléfonos </span>
                        </NavLink>
                    </NavItem>                   
                    <NavItem>
                        <NavLink
                            to="#"
                            className={classnames({ active: activeTab === '2' })}
                            onClick={() => { toggle('2'); }}
                            style={{ cursor: 'pointer'}}>
                            <i className=" fas fa-envelope fa-md "></i>
                            <span className="d-none d-sm-block">E- mails</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            to="#"
                            className={classnames({ active: activeTab === '3' })}
                            onClick={() => { toggle('3'); }}
                        >
                            <i className="bx bx-detail bx-sm"></i>
                            <span className="d-none d-sm-block">Direcciones</span>
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent className="p-4" activeTab={activeTab}>
                   
                  <TabPane id="1" role="tabpanel" tabId="1">              
                {groups.includes("SuperAdmin") || groups.includes("Supervisor")
                ||  groups.includes( "Operador") 
                || groups.includes("Crear y modificar Cliente")
                || groups.includes("Cliente") ? (
                        <Button className="btn btn-primary waves-effect waves-light btn-sm mb-1"
                        color="success" 
                        type="submit"
                        onClick={() => handleAdd("Telefono")}
                          >
                          Agregar Nuevo Teléfono
                        </Button> 
                    ):null}             
                          <Card>
                            <CardBody className="pb-0">
                              <h4 className="card-title">Teléfonos</h4>

                        {phoneData.count !== 0 ? (
                              <div>
                                <Table
                                  className="table text-center "
                                  hover
                                  responsive
                                >
                                  <thead className="table-dark">
                                    <tr>
                                      <th>#</th>
                                      <th>Tipo</th>
                                      <th>Numero</th>
                                  {groups.includes("SuperAdmin") || groups.includes("Supervisor") ||  groups.includes( "Operador") || groups.includes("Crear y modificar Cliente") || groups.includes("Cliente") ? (
                                      <th>Acción</th> ):null}
                                    </tr>
                                  </thead>
                                  <tbody>
                                      {Array.isArray(phoneData.results) ?
                                        phoneData.results.map((dato, idx) => (
                                          <tr key={idx}>
                                            <th scope="row">{idx+1}</th>
                                            <td>{dato.label}</td>
                                            <td>{dato.value_1}</td>
                                            <td>
                                            {groups.includes("SuperAdmin") || groups.includes("Supervisor") ||  groups.includes( "Operador") || groups.includes("Crear y modificar Cliente") || groups.includes("Cliente") ? (
                                              <Link
                                                to="#"                                                                 
                                              >
                                                <span className="bx bx-edit bx-sm align-middle"
                                                  onClick={()=> '#'}></span>
                                              </Link>
                                            ):null}                 
                                            </td>
                                          </tr>
                                        )):[]}
                                    </tbody>
                                  
                                </Table>
                                <Pagination
                                count={phoneData.count}
                                next={phoneData.next}
                                previous={phoneData.previous}
                                limit={10}
                                baseUrl="contact/"
                                filterFunction={getContactsDataFilter}
                              />
                              </div>

                      ) : ( 
                        <CardBody>
                          <h4 className="card-title">No se encontraron resultados</h4>
                        </CardBody>
                  )}
                            </CardBody>

                          </Card>                                  
                  </TabPane>
                    
                  <TabPane id="2" role="tabpanel" tabId="2">
                
                  {groups.includes("SuperAdmin") 
                    || groups.includes("Supervisor") 
                    ||  groups.includes( "Operador") 
                    || groups.includes("Crear y modificar Cliente") 
                    || groups.includes("Cliente")? (
                    <Button className="btn btn-primary waves-effect waves-light btn-sm mb-1"
                      color="success" 
                      type="submit"
                      onClick={() => handleAdd("Email")}
                    >
                    Agregar Nuevo E - Mail
                    </Button>  

                  ):null}
                  <Card>

                    <CardBody className="pb-0">
                      <h4 className="card-title">E-mails</h4>
                      {mailData.count !== 0 ? (
                            <div>
                              <Table
                                className="table table-hover text-center "
                                hover
                                responsive
                              >
                                <thead className="table-dark">
                                  <tr>
                                    <th>#</th>
                                    <th>Tipo</th>
                                    <th>Numero</th>
                              {groups.includes("SuperAdmin") || groups.includes("Supervisor") ||  groups.includes( "Operador") || groups.includes("Crear y modificar Cliente") || groups.includes("Cliente") ? (
                                    <th>Acción</th>
                              ):null}
                                  </tr> 
                                </thead>
                                <tbody>
                                  {Array.isArray(mailData.results) ?
                                    mailData.results.map((dato, idx) => (
                                      <tr key={idx}>
                                        <th scope="row">{idx+1}</th>
                                        <td>{dato.label}</td>
                                        <td>{dato.value_1}</td>
                                        <td>
                                        {groups.includes("SuperAdmin") || groups.includes("Supervisor") ||  groups.includes( "Operador") || groups.includes("Crear y modificar Cliente") || groups.includes("Cliente") ? (
                                          <Link to='#'                                                                                                                          
                                          >
                                            <span className="bx bx-edit bx-sm align-middle"></span>
                                          </Link>
                                        ):null}
                                        </td>
                                      </tr>
                                    )):[]}
                                  </tbody>
                                
                              </Table>
                              <Pagination
                              count={mailData.count}
                              next={mailData.next}
                              previous={mailData.previous}
                              limit={10}
                              baseUrl="contact/"
                              filterFunction={getContactsDataFilter}
                            />
                            </div>
                    ) : ( 
                      <CardBody>
                        <h4 className="card-title">No se encontraron resultados</h4>
                      </CardBody>
                  )}

                    </CardBody>

                  </Card>  

                  </TabPane>
                    
                  <TabPane id="3" role="tabpanel" tabId="3">
                  {groups.includes("SuperAdmin") 
                  || groups.includes("Supervisor") 
                  || groups.includes( "Operador") 
                  || groups.includes("Crear y modificar Cliente")
                  || groups.includes("Cliente") ? (
                    <Button className="btn btn-primary waves-effect waves-light btn-sm mb-1"
                      color="success" 
                      type="submit"
                      onClick={() => handleAdd("Direccion")}
                    >
                    Agregar Nueva Direccion
                     </Button>  
                   ):null}
                <Card>
                    <CardBody className="pb-0">
                      <h4 className="card-title">Direcciones</h4>
                  {addressData.count !== 0 ? (
                    <div>
                        <Table
                        className="table table-hover text-center "
                        hover
                        responsive
                        >
                          <thead className="table-dark">
                            <tr>
                              <th>#</th>
                              <th>Tipo</th>
                              <th>Numero</th>
                          {groups.includes("SuperAdmin") || groups.includes("Supervisor") ||  groups.includes( "Operador") || groups.includes("Crear y modificar Cliente") || groups.includes("Cliente") ? (
                              <th>Acción</th>
                            ):null}
                            </tr>
                          </thead>
                          <tbody>
                              {Array.isArray(addressData.results) ?
                                addressData.results.map((dato, idx) => (
                                  <tr key={idx}>
                                    <th scope="row">{idx +1}</th>
                                    <td>{dato.label}</td>
                                    <td>{dato.value_1}</td>
                                    <td>
                                {groups.includes("SuperAdmin") || groups.includes("Supervisor") ||  groups.includes( "Operador") || groups.includes("Crear y modificar Cliente") || groups.includes("Cliente") ? (
                                      <Link to='#'
                                  
                                      >
                                        <span className="bx bx-edit bx-sm align-middle"></span>
                                      </Link>
                                    ):null }
                                    </td>
                                  </tr>
                                )):[]}
                            </tbody>
                          
                        </Table>
                        <Pagination
                        count={addressData.count}
                        next={addressData.next}
                        previous={addressData.previous}
                        limit={10}
                        baseUrl="contact/"
                        filterFunction={getContactsDataFilter}
                      />
                      </div>
              ) : ( 
                <CardBody>
                  <h4 className="card-title">No se encontraron resultados</h4>
                </CardBody>
            )}

                    </CardBody>

                </Card>  
                                
                  </TabPane>

                </TabContent>
            </Card>
    </React.Fragment>
    );
}
const mapStateToProps = (state) => {
    const { error, loading, contactsData, contactsDataCreated, contactsDataUpdated, contactsDataDeleted } = state.ContactsData;
    return { error, loading, contactsData, contactsDataCreated, contactsDataUpdated, contactsDataDeleted };
  };
  contactsDataCreate.propTypes = {
    error: PropTypes.any,
    loading: PropTypes.bool,
    contactsDataCreated: PropTypes.object,
    contactsDataUpdated: PropTypes.object,
    contactsDataDeleted:  PropTypes.bool,
  };
  
  export default withRouter(connect(mapStateToProps, { contactsDataCreate , contactsDataDelete, contactsDataUpdate })(DatoTab));