import React, { useEffect } from "react";
import { Table } from "reactstrap";

import { confirmacionFilter } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";

function TableConfirmacion(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(confirmacionFilter(`rutero/?id=${props.id}`));
  }, [dispatch, props.id]);

  const confirmacion = useSelector((state) => state.Confirmacion.confirmacion);

  return (
    <div className="table-responsive" style={{ overflowX: "auto" }}>
      <Table
        className="table table-bordered table-striped text-center mb-3  "
        hover
        responsive
      >
        <thead className="table-dark align-middle" key={props.id}>
          <tr >
            <th style={{ minWidth: "150px" }}>Estado Confirmacion</th>
            <th style={{ minWidth: "150px" }}>Funcionario que Confirma</th>
            <th style={{ minWidth: "150px" }}>Hora Cita</th>
            <th style={{ minWidth: "150px" }}>Hora de Recogida</th>
            <th style={{ minWidth: "150px" }}>Hora de Retorno</th>
            <th style={{ minWidth: "150px" }}>Nota de Confirmacion</th>
            <th style={{ minWidth: "150px" }}>Utiliza Silla de Ruedas</th>
            <th style={{ minWidth: "150px" }}>Tipo de Silla de Ruedas</th>
            <th style={{ minWidth: "150px" }}>Nombre Acompañante</th>
            <th style={{ minWidth: "150px" }}>Documento del Acompañante</th>
            <th style={{ minWidth: "150px" }}>Fecha Servicio Confirmada</th>
            <th style={{ minWidth: "150px" }}>Hora Servicio Confirmada</th>
            <th style={{ minWidth: "150px" }}>Tipo Servicio Confirmada</th>
            <th style={{ minWidth: "150px" }}>Ciudad Origen Confirmada</th>
            <th style={{ minWidth: "150px" }}>Ciudad Destino Confirmada</th>
            <th style={{ minWidth: "150px" }}>Vereda Confirmada</th>
            <th style={{ minWidth: "150px" }}>Direccion Origen Confirmada</th>
            <th style={{ minWidth: "150px" }}>Direccion Destino Confirmada</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(confirmacion)
            ? confirmacion.map((item) => {
                return (
                
                    <tr key={item.id}>
                      <th>
                        {item.status === "CONFIRMADO" ? (
                          <span className="badge bg-success">CONFIRMADO</span>
                        ) : item.status === "NO CONFIRMADO" ? (
                          <span
                            style={{ background: "#ff8000" }}
                            className="badge "
                          >
                            NO CONFIRMADO
                          </span>
                        ) : null}{" "}
                      </th>
                      <th> {item.user}</th>
                      <th> {item.hora_cita}</th>
                      <th> {item.hora_recogida}</th>
                      <th> {item.hora_retorno}</th>
                      <th> {item.nota}</th>
                      <th> {item.silla_rueda ? "Si" : "No"}</th>
                      <th> {item.tipo_silla}</th>
                      <th> {item.accom_name}</th>
                      <th> {item.accom_documento}</th>
                      <th> {item.fecha_servicio}</th>
                      <th> {item.hora_servicio}</th>
                      <th> {item.tipo_servicio}</th>
                      <th> {item.ciudad_origen}</th>
                      <th> {item.ciudad_destino}</th>
                      <th> {item.vereda}</th>
                      <th> {item.direccion_origen}</th>
                      <th> {item.direccion_destino}</th>
                    </tr>
           
                );
              })
            : []}
        </tbody>
      </Table>
    </div>
  );
}

export default TableConfirmacion;
