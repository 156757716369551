import { call, put, takeEvery } from "redux-saga/effects";

import {
  CONTACTS_LIST,
  CONTACTS_FILTER,
  CONTACTS_CREATE,
  CONTACTS_UPDATE,
  CONTACTS_DELETE,
  CONTACTS_FULLLIST,
} from "./actionTypes";

import {
 contactsListSuccess,
 contactsCreateSuccess,
 contactsUpdateSuccess,
 contactsDeleteSuccess,
 contactsRequestFailed,
 contactsFuLLListSuccess,
} from "./actions";

import {
  getContactsList,
  getContactsFilter,
  postContactsCreate,
  patchContactsUpdate,
  deleteContacts,
  getContactsFullList,
} from "../../helpers/backend";

function*contactsListSaga() {
  console.log("Trayendo lista de contacts...");
  try {
    const response = yield call(getContactsList);
    if (response.status === 200) {
      yield put(contactsListSuccess(response.data));
    } else {
      yield put(contactsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactsRequestFailed(error.response.data));
  }
}

function*contactsFuLllistSaga() {
  console.log("Trayendo lista de FullList...");
  try {
    const response = yield call(getContactsFullList);
    if (response.status === 200) {
      yield put(contactsFuLLListSuccess(response.data));
     
    } else {
      yield put(contactsRequestFailed(response.data));
      console.log(response.data)
    }
  } catch (error) {
    yield put(contactsRequestFailed(error.response.data));
  }
}
function*contactsCreateSaga({ payload:contacts }) {
  console.log("Creando nuevo contacts...");
  try {
    const response = yield call(postContactsCreate,contacts);
    if (response.status === 201) {
      yield put(contactsCreateSuccess(response.data));
    } else {
      yield put(contactsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactsRequestFailed(error.response.data));
  }
}

function*contactsFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getContactsFilter, { changeUrl });
    if (response.status === 200) {
      yield put(contactsListSuccess(response.data));
    } else {
      yield put(contactsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactsRequestFailed(error.response.data));
  }
}

function*contactsUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del CONTACTS...");
  try {
    const response = yield call(patchContactsUpdate, { id }, data);
    if (response.status === 200) {
      yield put(contactsUpdateSuccess(response.data));
    } else {
      yield put(contactsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactsRequestFailed(error.response.data));
  }
}

function*contactsDeleteSaga({ payload: { id } }) {
  console.log("Actualizando datos del Contacto...");
  try {
    const response = yield call(deleteContacts, id );
    if (response.status === 204) {
      yield put(contactsDeleteSuccess(response.data));
    } else {
      yield put(contactsRequestFailed(response.data));
    }
  } catch (error) {
    yield put(contactsRequestFailed(error.response.data));
  }
}

export default function*contactsSaga() {
  yield takeEvery(CONTACTS_LIST,contactsListSaga);
  yield takeEvery(CONTACTS_FILTER,contactsFilterSaga);
  yield takeEvery(CONTACTS_CREATE,contactsCreateSaga);
  yield takeEvery(CONTACTS_UPDATE,contactsUpdateSaga);
  yield takeEvery(CONTACTS_DELETE,contactsDeleteSaga);
  yield takeEvery(CONTACTS_FULLLIST,contactsFuLllistSaga);

}
