// PISOS requests
export const PISOS_LIST = "PISOS_LIST";
export const PISOS_CREATE = "PISOS_CREATE";
export const PISOS_FILTER = "PISOS_FILTER";
export const PISOS_UPDATE = "PISOS_UPDATE";
export const PISOS_DELETE = "PISOS_DELETE";
export const PISOS_READ = "PISOS_READ";
export const PISOS_FULLLIST = "PISOS_FULLLIST";


// PISOS success
export const PISOS_LIST_SUCCESS = "PISOS_LIST_SUCCESS";
export const PISOS_FULLLIST_SUCCESS = "PISOS_FULLLIST_SUCCESS";

export const PISOS_CREATE_SUCCESS = "PISOS_CREATE_SUCCESS";
export const PISOS_UPDATE_SUCCESS = "PISOS_UPDATE_SUCCESS";
export const PISOS_DELETE_SUCCESS = "PISOS_DELETE_SUCCESS";
export const PISOS_READ_SUCCESS = "PISOS_READ_SUCCESS";

// PISOS failed
export const PISOS_REQUEST_FAILED = "PISOS_REQUEST_FAILED";
