import React, { useState, useRef } from "react";
import { Col, Row, Form, Input, Label, Button,
  } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import departamentos from "../Departamentos";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import SweetAlert from "react-bootstrap-sweetalert";

import { ruteroCreate, ruteroFilter } from "../../../store/actions";
 function RuteroForm(props) {
  const dispatch = useDispatch();

  const [selectedCiudadOrigen, setSelectedCiudadOrigen] = useState("");
  const [fecha_servicio, setSelectedFechaServicio] = useState("");
  const [selectedCiudadDestino, setSelectedCiudadDestino] = useState("");
  const [selectedDepartamento, setSelectedDepartamento] = useState("");
  const [horaServicio, setHoraCita] = useState(null);
  const flatpickrRef = useRef(null);

  console.log(selectedDepartamento)

  let hora_servicio = "";



  const [formData, setFormData] = useState({
    tipo_servicio: "",
    
    vereda: "",
    direccion_origen: "",
    direccion_destino: "",
    cantida: props.autorizacion.limite,
    sev_gestionar: props.autorizacion.limite,
    observaciones:""
  });



  const allCities = departamentos
    .flatMap((department) => department.cities)
    .sort((a, b) => a.localeCompare(b));
  // const state = useSelector((state) => state);
  const Rutero = useSelector((state) => state.Rutero);

  console.log(Rutero)
  
  const {
    tipo_servicio,

    vereda,
    direccion_origen,
    direccion_destino,
    cantida,
    sev_gestionar,
    observaciones
  } = formData;


  const onChange = (e) => {
    const { name, value } = e.target;
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: name === "sev_gestionar" ? Number(value) : value,
    }));
  };
  const onChangeFechaServicio = (e) => {
    setSelectedFechaServicio(e.target.value);
  };

  const onChangeHoraCita = (e) => {
    if (e && e.target && e.target.value) {
      
      setHoraCita(e.target.value);
    }
  };

  let horaSalidaRec = horaServicio?.toString().slice(0, 5);
  hora_servicio = horaSalidaRec;


  const handleCiudadOrigenChange = (e) => {
    const ciudadSeleccionada = e.target.value;
  setSelectedCiudadOrigen(ciudadSeleccionada);

    const departamento = departamentos.find((dep) =>
      dep.cities.includes(ciudadSeleccionada)
    );
  
    if (departamento) {
      setSelectedDepartamento(departamento.name);  // Actualizar el select de departamento
    } else {
      setSelectedDepartamento('');  // Si no se encuentra, limpiar el valor
    }
    // Resetea la ciudad seleccionada cuando cambias el departamento
  };
console.log(props)
  const handleCiudadDestinoChange = (e) => {
    setSelectedCiudadDestino(e.target.value);
    // Resetea la ciudad seleccionada cuando cambias el departamento
  };
  const handleDepartamentoChange = (e) => {
    setSelectedDepartamento(e.target.value);
    // Resetea la ciudad seleccionada cuando cambias el departamento
  };
  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(
      ruteroCreate({
        tipo_servicio,

        fecha_servicio,
        ciudad_origen: selectedCiudadOrigen,
        ciudad_destino: selectedCiudadDestino,
        vereda,
        direccion_origen,
        direccion_destino,
        cantida,
        sev_gestionar,
        autorizacion: props.autorizacion.id,
        hora_servicio,
        departamento: selectedDepartamento,
        observaciones,

        // Pasa "Colombia" como valor de la propiedad 'pais'
      })
    );
  };

  const resetFormData = () => {
    setSelectedFechaServicio("");
    setSelectedCiudadOrigen("");
    setSelectedCiudadDestino("");
    setSelectedDepartamento("");
    flatpickrRef.current.flatpickr.clear();
    setFormData({
      tipo_servicio: "",
      ciudad_origen: "",
      ciudad_destino: "",
      vereda: "",
      direccion_origen: "",
      direccion_destino: "",
      cantida: "",
      sev_gestionar: "",
    });
  };

  return (
    <div>
      {Rutero.ruteroCreated.id ? (
        <SweetAlert
          title="¡Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            dispatch(ruteroFilter(`auto/?id=${props.autorizacion.id}`));
            resetFormData();
            props.setisCollapseRuteroOpen(
              (prevState) => !prevState
            )
          }}
        >
          Se ha agregado el Rutero correctamente.
        </SweetAlert>
      ) : null}
      {Rutero.error.error ? (
        <SweetAlert
          title="¡Hecho!"
          error
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            dispatch(ruteroFilter(`auto/?id=${props.autorizacion.id}`));
          }}
        >
        {Rutero.error.error } 
        </SweetAlert>
      ) : null}





      <Form className="form-control"  onSubmit={onSubmit}>
        <Row>
          <Col lg={6} className="mb-3">
            <Label for="tipo_empleo" className="form-label">
              Fecha del servicio
            </Label>
            <input
              className="form-control d-block"
              placeholder="Fecha"
              onChange={(e) => onChangeFechaServicio(e)}
              type="date"
              value={fecha_servicio}
              required
            />
               {Rutero.error && Rutero.error.fecha_servicio ? (
              <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                {" "}
                {Rutero.error.fecha_servicio}
              </p>
            ) : null}
          </Col>
          <Col lg={6} className="mb-3">
            <Label for="nombre_empresa" className="form-label">
              Tipo de Servicio
            </Label>
            <select
              className="form-select"
              id="tipo_servicio"
              name="tipo_servicio"
              onChange={onChange}
              value={tipo_servicio}
              required
            >
              <option defaultValue>Seleccione una opción</option>
              <option value="CARRETERO">Carretero</option>
              <option value="LOCAL">Local</option>
            </select>
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-3">
            <Label for="tipo_empleo" className="form-label">
              Cantidad Rutero
            </Label>
            <Input
              id="cantida"
              name="cantida"
              type="text"
              onChange={onChange}
              value={cantida}
              required
            />

            {Rutero.error && Rutero.error.cantida ? (
              <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                {" "}
                {Rutero.error.cantida}
              </p>
            ) : null}
          </Col>
          <Col lg={6} className="mb-3">
            <Label for="nombre_empresa" className="form-label">
              Servicios a Gestionar
            </Label>
            <Input
              id="sev_gestionar"
              name="sev_gestionar"
              type="number"
              placeholder={`limite ${props.autorizacion.limite}`}
              onChange={onChange}
              value={sev_gestionar}
              required
            />
            {Rutero.error && Rutero.error.sev_gestionar ? (
              <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                {" "}
                {Rutero.error.sev_gestionar}
              </p>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-3">
            <Label for="tipo_empleo" className="form-label">
              Ciudad Origen
            </Label>
            <select
              value={selectedCiudadOrigen}
              onChange={handleCiudadOrigenChange}
              className="form-control"
              required
            >
              <option value="">Selecciona una ciudad</option>
              {allCities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </select>
          </Col>
          <Col lg={6} className="mb-3">
            <Label for="nombre_empresa" className="form-label">
              Ciudad destino
            </Label>
            <select
              value={selectedCiudadDestino}
              onChange={handleCiudadDestinoChange}
              className="form-control"
              required
            >
              <option value="">Selecciona una ciudad</option>
              {allCities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </select>
          </Col>
        </Row>

        <Row>
          <Col lg={6} className="mb-3">
            <Label for="tipo_empleo" className="form-label">
              Direccion Origen
            </Label>
            <Input
              id="direccion_origen"
              name="direccion_origen"
              type="text"
              onChange={onChange}
              value={direccion_origen}
              required
            />
          </Col>
          <Col lg={6} className="mb-3">
            <Label for="direccion_destino" className="form-label">
              Direccion Destino
            </Label>
            <Input
              id="direccion_destino"
              name="direccion_destino"
              type="text"
              onChange={onChange}
              value={direccion_destino}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-3">
            <Label for="tipo_empleo" className="form-label">
              Hora Cita
            </Label>

            <Flatpickr
              className="form-control d-block"
              placeholder="Hora de Cita"
              onInput={onChangeHoraCita}
              ref={flatpickrRef}
              required
              options={{
                enableTime: true,

                noCalendar: true,

                dateFormat: "H:i K",
                minuteIncrement: "15",
              }}
            />
             {Rutero.error && Rutero.error.hora_servicio ? (
              <p className={`mb-4 mx-5 font-size-13 text-danger `}>
                {" "}
                {Rutero.error.hora_servicio}
              </p>
            ) : null}
          </Col>
          <Col lg={6} className="mb-3">
            <Label for="vereda" className="form-label">
              Vereda
            </Label>
            <Input
              id="vereda"
              name="vereda"
              type="text"
              onChange={onChange}
              value={vereda}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} className="mb-3">
            <Label for="tipo_empleo" className="form-label">
              Departamento del Servicio
            </Label>
            <select
              value={selectedDepartamento}
              onChange={handleDepartamentoChange}
              className="form-select"
              disabled={true}
              required
            >
              <option value="">Selecciona un departamento</option>
              {departamentos.map((departamento) => (
                <option key={departamento.name} value={departamento.name}>
                  {departamento.name}
                </option>
              ))}
            </select>
          </Col>


          <Col lg={6} className="mb-3">
            <Label for="observaciones" className="form-label">
              Observaciones
            </Label>
            <Input
              id="observaciones"
              name="observaciones"
              type="text"
              onChange={onChange}
              value={observaciones}
              
            />
          </Col>

          <Row className="col-lg-12 justify-content-center mt-4">
            <Col md={1} className="mx-1">
              <Button color="primary" type="submit" >
                Enviar
              </Button>
            </Col>
       
          </Row>
        </Row>
      </Form>
    </div>
  );
}

export default RuteroForm;
