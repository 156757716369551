import {
  CONFIRMACION_LIST,
  CONFIRMACION_FILTER,
  CONFIRMACION_CREATE,
  CONFIRMACION_UPDATE,
  CONFIRMACION_UPDATE_SUCCESS,
  CONFIRMACION_CREATE_SUCCESS,
  CONFIRMACION_LIST_SUCCESS,
  CONFIRMACION_REQUEST_FAILED,
} from "./actionTypes";

export const confirmacionList = () => {
  return {
    type: CONFIRMACION_LIST,
    payload: {},
  };
};

export const confirmacionCreate = (confirmacion) => {
  return {
    type: CONFIRMACION_CREATE,
    payload: confirmacion,
  };
};

export const confirmacionFilter = (changeUrl) => {
  return {
    type: CONFIRMACION_FILTER,
    payload: { changeUrl },
  };
};

export const confirmacionUpdate = (id, data) => {
  return {
    type: CONFIRMACION_UPDATE,
    payload: { id, data },
  };
};

export const confirmacionListSuccess = (confirmacion) => {
  return {
    type: CONFIRMACION_LIST_SUCCESS,
    payload: confirmacion,
  };
};

export const confirmacionCreateSuccess = (confirmacionCreated) => {
  return {
    type: CONFIRMACION_CREATE_SUCCESS,
    payload: confirmacionCreated,
  };
};

export const confirmacionUpdateSuccess = (confirmacion) => {
  return {
    type: CONFIRMACION_UPDATE_SUCCESS,
    payload: confirmacion,
  };
};

export const confirmacionRequestFailed = (error) => {
  return {
    type: CONFIRMACION_REQUEST_FAILED,
    payload: error,
  };
};
