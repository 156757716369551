import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
//Import Breadcrumb
import { Carousel } from "react-responsive-carousel";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Loader from "../../components/Common/Loader";
import { getAutorizacionRead } from "../../helpers/backend";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./CasosDetails.module.scss";
import Timeline from "./TimeLine";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import RuteroForm from "./Formularios.jsx/Rutero";

import Chat from "../Whatsapp/Chat";

import { ruteroFilter } from "../../store/actions";
import TableRutero from "./Tables/TableRutero";

const AutorizacionesDetails = (props) => {
  const id = useParams().id;
  const URL = process.env.REACT_APP_API_URL;
  const url = URL.split("/api");

  const [activeTab, setActiveTab] = useState("1");

  const [isCollapseFotoOpen, setisCollapseFotoOpen] = useState(false);
  const [isCollapseRuteroOpen, setisCollapseRuteroOpen] = useState(false);
  const [isChatOpen, setisChatOpen] = useState(false);

  /////////////////////////////////////////////////////////

  const [managementOpen, setManagementOpen] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [chatType, setChatType] = useState(null);
  const [managementUpdate, setManagementUpdate] = useState(null);
  const [contactsRefresh, setContactsRefresh] = useState(false);
  const [isLead, setIsLead] = useState(false);
  const [campaignRefresh, setCampaignRefresh] = useState(false);
  const [idConversation, setIdConversation] = useState(null);
  const [leadId, setLeadId] = useState(null);

  const [autorizacion, setAutorizacion] = useState({});

  console.log(autorizacion)
  const dispatch = useDispatch();
  const getAutorizacionData = async () => {
    const response = await getAutorizacionRead(id);
    const data = response.data;

    setAutorizacion(data);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    getAutorizacionData();

    setSelectedPhoneNumber("+584125686862");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (autorizacion) {
      // Asegúrate de que 'autorizacion' ya tiene datos antes de ejecutar el dispatch
      dispatch(ruteroFilter(`auto/?id=${autorizacion.id}`));
    }
  }, [autorizacion, dispatch]);

  const Rutero = useSelector((state) => state.Rutero);

  function Status({ estado }) {
    const estados = {
      EN_PAUSA: { className: "bg-info", text: "En Pausa" },
      PENDIENTE: { className: "bg-danger", text: "Pendiente" },
      SIN_ASIGNAR: { className: "bg-warning", text: "Sin Asignar" },
      EN_PROCESO: { className: "bg-pink", text: "En Proceso" },
      FINALIZADO: { className: "bg-success", text: "Finalizado" },
      ASIGNADO: { className: "bg-primary  ", text: "Asignado" },
    };

    const estadoActual = estados[estado];

    return (
      <div className={`badge  ${estadoActual.className} font-size-18`}>
        <strong>{estadoActual.text}</strong>
      </div>
    );
  }

  const abrirImagenEnNuevaPestana = (imageUrl) => {
    window.open(imageUrl, "_blank");
  };

  const updateManagementOpen = (isOpen) => {
    setManagementOpen(isOpen);
    setHistoryOpen(false);
    setContactOpen(false);
  };
  const updateHistoryOpen = (isOpen) => {
    setHistoryOpen(isOpen);
    setContactOpen(false);
    setManagementOpen(false);
  };
  const updateContactOpen = (isOpen) => {
    setContactOpen(isOpen);
    setHistoryOpen(false);
    setManagementOpen(false);
  };
  const handlePhoneNumberSelect = (phoneNumber) => {
    setSelectedPhoneNumber(phoneNumber);
  };
  const handleContactId = (id) => {
    setSelectedContact(id);
  };
  const handleCampaignId = (id) => {
    setSelectedCampaign(id);
  };
  const handleChatType = (type) => {
    setChatType(type);
  };
  const handleManagementCreateSubmitStatus = (isSuccess) => {
    setManagementUpdate(isSuccess);
  };
  const handleIsLeadUpdate = (isLead) => {
    setIsLead(isLead);
  };
  const handleCampaignRefresh = (campaign) => {
    setCampaignRefresh(campaign);
  };
  const handleIdConversation = (id) => {
    setIdConversation(id);
  };
  const handleLeadIdUpdate = (leadId) => {
    setLeadId(leadId);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> TrueContact | Detalles de la Autorizacion </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="TrueContact"
            breadcrumbItem=" Detalles de la autorización"
          />

          {props.loading ? (
            <Loader />
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Card className={styles.ticket} style={{ border: "0px" }}>
                  {autorizacion.estado ? (
                    <div style={{ zIndex: "999" }}>
                      <b className="position-absolute top-0 end-0 m-2">
                        <Status estado={autorizacion.estado} />
                      </b>
                    </div>
                  ) : null}
                  <div
                    className="position-absolute top-0 start-0 m-2"
                    style={{ zIndex: "999", padding: "10px" }}
                  >
                    <Link
                      className="btn btn-secondary btn-sm text-dark"
                      to="/autorizaciones"
                    >
                      Volver
                    </Link>
                  </div>

                  <div className={styles["ticket-header"]}>
                    <h1>
                      <b style={{ color: "black" }}>Numero de Autorizacion :</b>{" "}
                      <b>{autorizacion.autorizacion}</b>
                    </h1>
                    {autorizacion.total_duracion ? (
                      <p className="d-flex justify-content-center">
                        <b>Tiempo de Finalizacion: </b>{" "}
                        {autorizacion.total_duracion}
                      </p>
                    ) : null}
                  </div>
                  <div className={styles["ticket-body"]}>
                    <Card
                      style={{ width: "100%", height: "100%", border: "0" }}
                    >
                      <Nav
                        tabs
                        justified
                        className="nav-tabs-custom"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            to="#"
                            className={`p-2 ${classnames({
                              active: activeTab === "1",
                            })}`}
                            onClick={() => {
                              toggle("1");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fas fa-ticket-alt fa-lg"></i>
                            <span className="d-none d-sm-block">
                              Detalles de la autorización
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            to="#"
                            className={`p-2 ${classnames({
                              active: activeTab === "2",
                            })}`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              toggle("2");
                            }}
                          >
                            <i className="fas fa-tasks fa-lg "></i>
                            <span className="d-none d-sm-block">
                              Gestiones de la autorización
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent className="p-4" activeTab={activeTab}>
                        <TabPane id="1" role="tabpanel" tabId="1">
                          <Row style={{ lineHeight: "0.5rem" }}>

                          <p><b>Prescripcion: </b>{autorizacion.prescripcion} </p>
                          <p><b>Area de envio: </b>{autorizacion.area_envio} </p>
                          <p><b>Ciudad: </b>{autorizacion.ciudad} </p>
                          <p><b>Departamento: </b>{autorizacion.departamento} </p>
                          <p><b>Usuario: </b>{autorizacion.contacto} </p>
                          <p><b>Fecha de Correo: </b>{autorizacion.f_correo} </p>
                          <p><b>Fecha de Emision: </b>{autorizacion.f_emision} </p>
                          <p><b>Fecha de Vigencia: </b>{autorizacion.f_vigencia} </p>
                          <p><b>Cantidad Maxima de Ruteros: </b>{autorizacion.limite} </p>
                          <p><b>Id de Ciclo: </b>{autorizacion.id_ciclo} </p>

                  
                             

                            <Card className={styles.card}>
                              <Col>
                                <Link
                                  className={styles["button-plus"]}
                                  onClick={() =>
                                    setisCollapseFotoOpen(
                                      (prevState) => !prevState
                                    )
                                  }
                                >
                                  {!isCollapseFotoOpen ? (
                                    <span className="fas fa-plus fa-lg"></span>
                                  ) : (
                                    <span className="fas fa-minus fa-lg"></span>
                                  )}
                                </Link>
                                <h4 className="text-dark p-3">
                                  <b>Fotos:</b>
                                </h4>

                                {isCollapseFotoOpen ? (
                                  autorizacion.vauche_img !== "" ? (
                                    <Card>
                                      <CardBody>
                                        <div>
                                          <Carousel infiniteLoop showArrows>
                                            <div
                                              onClick={() =>
                                                abrirImagenEnNuevaPestana(
                                                  url[0] +
                                                    autorizacion.vauche_img
                                                )
                                              }
                                            >
                                              <img
                                                src={
                                                  url[0] +
                                                  autorizacion.vauche_img
                                                }
                                                alt={autorizacion.vauche_img}
                                              />
                                            </div>
                                          </Carousel>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  ) : (
                                    <div className="mb-2">
                                      <b>No tiene imagen</b>
                                    </div>
                                  )
                                ) : null}
                              </Col>
                            </Card>

                            {/* RUtero */}

                            {Rutero?.rutero?.length === 0 && (
                              <Card className={styles.card}>
                                <h4 className="text-dark p-3">
                                  <b>Rutero</b>
                                </h4>
                                <Link
                                  className={styles["button-plus"]}
                                  onClick={() =>
                                    setisCollapseRuteroOpen(
                                      (prevState) => !prevState
                                    )
                                  }
                                >
                                  {!isCollapseRuteroOpen ? (
                                    <span className="fas fa-plus fa-lg"></span>
                                  ) : (
                                    <span className="fas fa-minus fa-lg"></span>
                                  )}
                                </Link>
                                {isCollapseRuteroOpen ? (
                                  <RuteroForm
                                    autorizacion={autorizacion}
                                    setisCollapseRuteroOpen={
                                      setisCollapseRuteroOpen
                                    }
                                  />
                                ) : null}
                                <Row></Row>
                              </Card>
                            )}
                          </Row>

                          <TableRutero Rutero={Rutero}></TableRutero>
                        </TabPane>

                        <TabPane id="2" role="tabpanel" tabId="2">
                          {activeTab === "2" ? (
                            <Timeline gestiones={autorizacion.autorizacion} />
                          ) : null}
                        </TabPane>
                      </TabContent>

                      {/* aqui va la tabla rutero */}
                    </Card>
                  </div>
                </Card>
              
              </div>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AutorizacionesDetails;
